
<div class="body body-medium" (click)="close($event)">

  <p [innerHTML]="msg.intro"></p>

  @if (me.isIOS) {

    @if (me.isSafari) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/ios-share.svg">
        {{msg.ios.safari.s1}}
      </li>
      <li>
        <img src="/assets/icons/add-to-home-screen.svg">
        {{msg.ios.safari.s2}}
      </li>
    </ul>
    }
  
    @else if (me.isChrome) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/ios-share.svg">
        {{msg.ios.chrome.s1}}
      </li>
      <li>
        <img src="/assets/icons/add-to-home-screen.svg">
       {{msg.ios.safari.s2}}
      </li>
    </ul>
    }
  
    @else if (me.isEdge) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/menu.svg">
        {{msg.ios.edge.s1}}
      </li>
      <li>
        <img src="/assets/icons/ios-share.svg">
        {{msg.ios.edge.s2}}
      </li>
      <li>
        <img src="/assets/icons/add-to-home-screen.svg">
        {{msg.ios.safari.s2}}
      </li>
    </ul>
    }
  
    @else if (me.isFirefox) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/menu.svg">
         {{msg.ios.edge.s1}}
      </li>
      <li>
        <img src="/assets/icons/ios-share.svg">
        {{msg.ios.edge.s2}}
      </li>
      <li>
        <img src="/assets/icons/add-to-home-screen.svg">
        {{msg.ios.safari.s2}}
      </li>
    </ul>
    }

  }

  @else if (me.isAndroid) {

    @if (me.isEdge) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/menu.svg">
        {{msg.ios.edge.s1}}
      </li>
      <li>
        <img src="/assets/icons/add-to-phone.svg">
        {{msg.android.edge.s2}}
      </li>
    </ul>
    }
  
    @else if (me.isFirefox) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/menu-vert.svg">
         {{msg.android.firefox.s1}}
      </li>
      <li>
        <img src="/assets/icons/install-phone.svg">
         {{msg.android.firefox.s2}}
      </li>
    </ul>
    }
  
  }

  @else if (me.isMacOS) {

    @if (me.isSafari) { 
    <p>{{msg.heading}}</p>
    <ul>
      <li>
        <img src="/assets/icons/ios-share.svg">
        {{msg.macos.safari.s1}}
      </li>
      <li>
        <img src="/assets/icons/add-to-dock.svg">
        {{msg.macos.safari.s2}}
      </li>
    </ul>
    }

    @if (me.isFirefox) { 
    <p>
      {{msg.macos.firefox.s1}}
    </p>
    }

  }

  @else if (me.isWindows) {

    @if (me.isFirefox) { 
    <p>
      {{msg.windows.firefox.s1}}
    </p>
    }

  }
  

</div>
