import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { IOrderListItem } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'ui-user-orders',
    templateUrl: './ui-user-orders.component.html',
    styleUrls: ['./ui-user-orders.component.css'],
    standalone: true,
    imports: [NgFor, MatRipple]
})
export class UiUserOrdersComponent implements OnInit {

  public get text(): any { return this.me.texts.components['ui-user-orders']; }
  public get msg(): any { return this.me.texts.components['common']; }

  public orders: Array<IOrderListItem> = [];
  public get any(): boolean { return this.orders && this.orders.length > 0; }

  constructor(private me: MeService, private nav: NavService) { }

  ngOnInit(): void {

    this.me.call<any>('browse.user.orders.list').subscribe((result) => {

      this.orders = result.orders;

    });

  }

  onClick(order: IOrderListItem) {
    this.nav.to(`/me/orders/${order.transactionId}`);
  }

}
