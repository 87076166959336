import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MeService } from '../../services/me.service';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

export interface IDlgConfirmSettings {
  id: string,
  subject: string,
  type: 'confirm' | 'yesno' | 'yesnocancel',
}

@Component({
    selector: 'dlg-confirm',
    templateUrl: './dlg-confirm.component.html',
    styleUrls: ['./dlg-confirm.component.css'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, NgIf, MatButton, MatDialogClose]
})
export class DlgConfirmComponent {

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.session.texts.components[this.settings.id]; }

  constructor(@Inject(MAT_DIALOG_DATA) public settings: IDlgConfirmSettings,
              private me: MeService) {

  }

}
