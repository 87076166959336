import { Directive, ElementRef, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { IUiElement } from '../../models/ui.interface';
import { MeService } from '../../services/me.service';

@Directive()
export class UiElementComponent {

  public get msg(): any { return this.me.session.texts.components['common']; }

  private _width: any = null;
  private _height: any = null;
  protected get width(): any { return this.host.nativeElement.offsetWidth; }
  protected get height(): any { return this.host.nativeElement.offsetHeight; }

  public get rippleColor(): string {
    return getComputedStyle(this.host.nativeElement)
      .getPropertyValue('--ripple-color').trim();
  }

  constructor(public me: MeService,
              protected renderer: Renderer2,
              protected host: ElementRef) {

  }

  protected update(element: IUiElement) {

    // set host attribute
    if (element.css)
      this.renderer.setAttribute(this.host.nativeElement, 'css', element.css);

    // set style, if any
    if (element.style)
      _.forOwn(element.style, (value, key) => {
        // this.renderer.setStyle(this.host.nativeElement, key, value); // variables --xx-x not working
        this.host.nativeElement.style.setProperty(key, value);
      });

    // set background image
    this.setBackground(element);

  }

  protected afterViewChecked(element: IUiElement) {

    // set image after tile is stabilized - problems with virtual scroll
    // measuring offsetWidth and offsetHeight breaks scrolling to top

    if (this.width == 0 || this.height == 0)
      return;

    if (this._width == this.width &&
      this._height == this.height)
      return;

    this.setBackground(element);
    this._width = this.width;
    this._height = this.height;

  }

  private setBackground(element: IUiElement) {

    // set background image
    var bkgImage = this.createImageUrl(element);
    if (bkgImage)
      this.renderer.setStyle(this.host.nativeElement, "backgroundImage", bkgImage);
    else
      this.renderer.setStyle(this.host.nativeElement, "backgroundImage", null);

  }

  private createImageUrl(element: IUiElement): any {

    if (!element || !element.backgroundImage)
      return '';

    return this.me.createImageUrl(element.backgroundImage, this.width, this.height);
  }

}
