import { Platform } from "@angular/cdk/platform";
import { Directive, Input, Output, EventEmitter, HostListener } from "@angular/core";

// https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard/52949299#52949299

@Directive({
    selector: '[copy-clipboard]',
    standalone: true
})
export class CopyClipboardDirective {

  @Input("copy-clipboard")
  public payload: string;

  @Output("copied")
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener("click", ["$event"])
  public onClick(event: MouseEvent): void {

    event.preventDefault();

    if (this.payload && this.copyTextToClipboard(this.payload.toString()))
      this.copied.emit(this.payload);

  }

  constructor(private platform: Platform) {
  }
  

  // https://gist.github.com/Jeffen/bceb94b81d4d2b4688ccafb06d14d718
  copyTextToClipboard(strValue: string): boolean {

    // create textarea
    const textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = strValue;

    document.body.appendChild(textArea);
    // select text
    var range, selection;

    if (this.platform.IOS) {

      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);

    }
    else {

      textArea.select();
    }

    // copy to clipboard
    let result = true;

    try {

      document.execCommand('copy');

    } catch (err) {

      result = false;

    }

    document.body.removeChild(textArea);
    return result;

  }
}
