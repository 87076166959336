import { Component } from '@angular/core';

@Component({
    selector: 'view-user',
    templateUrl: './view-user.component.html',
    styleUrls: ['./view-user.component.css'],
    standalone: true,
    imports: []
})
export class ViewUserComponent {

  constructor() {

  }

}
