import { NgFor } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import * as _ from 'lodash';
import { CartDeliveryOption, CartDeliveryOptionList, ICartDeliveryOptionList } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiPriceComponent } from '../ui-price/ui-price.component';

@Component({
    selector: 'ui-cart-deliveryoption-list',
    templateUrl: './ui-cart-deliveryoption-list.component.html',
    styleUrls: ['./ui-cart-deliveryoption-list.component.css'],
    standalone: true,
    imports: [MatButton, MatIcon, NgFor, MatRadioGroup, FormsModule, MatRadioButton, UiPriceComponent]
})
export class UiCartDeliveryOptionListComponent implements OnInit {

  @Output('onPrevious') onPrevious = new EventEmitter<any>();
  @Output('onNext') onNext = new EventEmitter<any>();

  public deliveryOptions: CartDeliveryOptionList;
  public get deliveryOption(): CartDeliveryOption {

    var option = null;
    var storedId = this.ssv.getDeliveryOption();
    if (storedId)
      option = _.find(this.deliveryOptions.options, option => option.id == storedId);

    if (!option && this.deliveryOptions.options.length)
      option = this.deliveryOptions.options[0];

    return option;
  }
  public set deliveryOption(option: CartDeliveryOption)  {

    this.ssv.setDeliveryOption(option.id);

  }

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['view-shoppping-cart']; }

  public get options(): Array<CartDeliveryOption> { return this.deliveryOptions?.options; }
  public get isEmpty(): any { return !this.options || this.options.length == 0; }
  public get isSingle(): any { return this.options && this.options.length == 1; }
  public get isMultiple(): any { return this.options && this.options.length > 1; }
  public get messages(): Array<string> { return this.deliveryOptions?.messages; }

  public get priceDescription(): string {
    var vatText = null;
    if (this.deliveryOptions.priceType.includesVAT === true)
      vatText = this.text.items.incVAT;
    else
    if (this.deliveryOptions.priceType.includesVAT === false)
      vatText = this.text.items.excVAT;

    return `${this.deliveryOptions.priceType.label} ${vatText}`;

  }


  constructor(private me: MeService, private ssv: SelectService) { }

  ngOnInit() {

    this.me.call<ICartDeliveryOptionList>('browse.user.cart.deliveryoptions.get', {

      selectedItems: this.ssv.selection.items

    }).subscribe((deliveryOptions) => {

      // init delivery options
      this.deliveryOptions = new CartDeliveryOptionList(deliveryOptions);

      // find and initialize previously selected or default option
      this.deliveryOption = this.deliveryOption;

    });

  }

}
