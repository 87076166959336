import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import * as _ from 'lodash';
import { IEntity } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { UiEntityViewComponent } from '../ui-entity-view/ui-entity-view.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';

export interface IEntityListView {
  entity: IEntity,
  list: Array<IEntity>,
}

@Component({
  selector: 'ui-entity-list-view',
  templateUrl: './ui-entity-list-view.component.html',
  styleUrls: ['./ui-entity-list-view.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, MatIconButton, MatIcon, UiEntityViewComponent, UiBtnFabComponent, CdkScrollable]
})
export class UiEntityListViewComponent
  extends UiScrollableComponent
  implements OnInit {


  @Input('data')
  public data: IEntityListView;

  @Output('back')
  public back = new EventEmitter<void>();

  @ViewChild('content', { static: false, read: ElementRef<HTMLElement> })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  @ViewChild('entityView', { static: false })
  public entityView: UiEntityViewComponent;


  public get changed(): boolean { return this.entityDataChanged || this.entityView.changed; }
  private entityDataChanged: boolean = false;

  public get entity(): IEntity { return this.data.entity; }
  public get list(): Array<IEntity> { return this.data.list; }

  // document array
  public get numIds(): number { return this.list.length; }
  public currentIdx: number = 0;
  public get currentId(): string { return this.list[this.currentIdx].id; }

  // current entity
  public get title(): string {

    if (!this.scrolledMin)
      return null;

    if (this.entityView?.view)
      return this.entityView.view.name || this.id;
    else
      return this.entity?.name || this.id;
  }

  public get subtitle(): string {

    if (!this.scrolledMin)
      return null;

    return this.id;
  }

  public get id(): string {
    if (this.entityView?.view)
      return this.me.getPathLast(this.entityView.view.id);
    else
      return this.me.getPathLast(this.entity?.id);
  }


  constructor(public override me: MeService,
    renderer: Renderer2,
    host: ElementRef,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone);

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

    // if no list => create one
    if (!this.data.list)
      this.data.list = [this.data.entity];
    else {
      // find position within list
      this.currentIdx = _.indexOf(this.data.list, this.data.entity);
      if (this.currentIdx < 0)
        this.currentIdx = 0;
    }

  }


  ////////////////////////////////////////////
  // next / previous

  public get canNext() { return this.currentIdx < this.list.length - 1; }

  public onNext() {

    if (this.canNext)
      this.currentIdx++;

  }

  public get canPrev() { return this.currentIdx > 0; }

  public onPrev() {

    if (this.canPrev)
      this.currentIdx--;

  }


  ////////////////////////////////////////////
  // swipe

  public onSwipeLeft() {

    if (this.canNext)
      this.onNext();

  }

  public onSwipeRight() {

    if (this.canPrev)
      this.onPrev();

  }

  ////////////////////////////////////////////
  // back
  public get backVisible(): boolean {
    return this.back.observed;
  }

  public onBack() {
      this.back.emit();
  }


}
