import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[sectionHeader]',
  standalone: true,
})
export class UiSectionHeaderDirective {
  constructor(public templateRef: TemplateRef<unknown>) { }
}

@Component({
    selector: 'ui-section-header',
    templateUrl: './ui-section-header.component.html',
    styleUrls: ['./ui-section-header.component.css'],
    standalone: true,
    imports: [MatIcon, MatDivider, MatIconButton, NgTemplateOutlet]
})
export class UiSectionHeaderComponent {

  @Input('icon') public icon: string;
  @Input('label') public label: string;
  @Input('divider') public divider: boolean = true;
  @Input('button') public button: boolean = true;
  @Input('expanded') public expanded: boolean = false;

  @ContentChild(UiSectionHeaderDirective) content: UiSectionHeaderDirective;

  public get btnIcon(): string { return this.expanded ? 'expand_less' : 'expand_more'; }

  constructor() {
  }

  public toggle() { this.expanded = !this.expanded; }

}
