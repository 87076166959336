
@if (view) {

  @if (viewCartCount) {
    <ui-cart-count [productId]="view.id"></ui-cart-count>
  }

  @if (manufacturerVisible) {
  <div class="label-medium">{{view.manufacturer}}</div>
  }

  @if (view.name) {
  <div class="title-medium title">{{view.name}}</div>
  }

  @if (view.subtitle) {
  <div class="label-medium subtitle outline-text">{{view.subtitle}}</div>
  }

  <ui-image-list [media]="view.media"></ui-image-list>

  <div class="row-space label-large">

    <div>
      <ui-state [state]="view.state"></ui-state>
      @if (pricing) {
        @if (pricing.length == 1) {
        <ui-price-product class="simple-price" [price]="pricing[0]" [hint]="false" ></ui-price-product>
        }
        @else if (pricing.length > 1) {
        <ui-pricing [pricing]="pricing" [hint]="true"></ui-pricing>
        }
      }
    </div>

    <ui-note-rating #rating [docId]="view.id" [note]="view.note"></ui-note-rating>

  </div>

  @if (textNote) {
  <div class="body-medium note" [innerHTML]="textNote | lineBreaks" ></div>
  }

  @if (view.description) {
  <div class="body-medium description" [innerHtml]="view.description | lineBreaks"></div>
  }

  <ui-property-group [group]="view.properties"></ui-property-group>

}
