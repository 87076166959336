import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable, catchError, mergeMap, of, switchMap } from 'rxjs';
import { IntersectionStatus } from '../../directives/intersection.directive';
import { IUiPage, IUiPageSection } from '../../models/ui.interface';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { UiPageSectionComponent } from '../ui-page-section/ui-page-section.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';

@Component({
    selector: 'view-page',
    templateUrl: './view-page.component.html',
    styleUrls: ['./view-page.component.css'],
    standalone: true,
  imports: [UiToolbarTopComponent, CdkScrollable, UiPageSectionComponent, AsyncPipe, UiBtnFabComponent]
})
export class ViewPageComponent
  extends UiScrollableComponent
  implements OnInit, OnDestroy {

  @ViewChild('content', { static: false })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  @ViewChildren('sections')
  sections: QueryList<UiPageSectionComponent>;

  // Page
  public page$: Observable<IUiPage>;
  public page: IUiPage;

  // Header
  public _headerTitle: string;
  public get headerTitle(): string {

     if (this.headerInContent || this.headerFixed)
      return this._headerTitle;

    if (this.scrolledMin)
      return this._headerTitle;

    return null;

  }

  public get headerTitleClass(): string {

    return 'title-large';

  }

  public get headerState(): string {

    if (this.scrolledMin && !this.headerInContent)
      return 'scrolled';

    return this.headerTheme;
  }

  public get headerInContent(): boolean {

    if (_.startsWith(this.headerTheme, 'transparent'))
      return true;
    else
      return false;

  }

  public get headerFixed(): boolean {

    if (_.startsWith(this.headerTheme, 'fixed'))
      return true;
    else
      return false;

  }

  public get headerTheme(): string {

    if (this.page) {

      // first section
      if (this.page.sections?.length &&
          this.page.sections[0].headerTheme)
        return this.page.sections[0].headerTheme;

      // second page
      if (this.page.headerTheme)
        return this.page.headerTheme;

    }

    return null;

  }

  public get headerBackVisible(): boolean {

    if (this.page) {
      const segments = this.page.pageId.split('/');
      return _.last(segments) != 'home' && _.last(segments) != 'products';
    }

    return false;
  }

  public get headerCartBtnVisible(): boolean {
    return this.nav.isA('/products');
  }


  constructor(public override me: MeService, private nav: NavService,
    renderer: Renderer2,
    host: ElementRef,
    protected route: ActivatedRoute,
    private title: Title,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone)
  {
    super(me, renderer, host, scrollDispatcher, zone)
  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

    // Multiple http requests
    // https://stackoverflow.com/questions/56278367/multiple-identical-async-pipe-in-angular-causing-multiple-http-requests

    this.page$ = this.route.url.pipe(
      switchMap(url => {

        // defaults to home
        var nav = 'home';

        // concatenate url segments, e.g. 'about/gdpr'
        if (url.length)
          nav = _.map(url, urlSeg => urlSeg.path).join('/');

        // load page, error MUST be handled here
        return this.loadPage(nav).pipe(

          catchError(error => {

            // Handle or log the error
            console.error(error);

            // Return a fallback value or a new Observable
            return of(this.page); // Ensure this.page is a sensible fallback

          })
        );

      }),
      mergeMap(page => this.updatePage(page))
    );

    // set this component as current page
    this.me.currentPage = this;
  }

  ngAfterViewChecked() {

    // set image after tile is stabilized - problems with virtual scroll
    // measuring offsetWidth and offsetHeight breaks scrolling to top
    super.afterViewChecked(this.page);

  }

  ngOnDestroy(): void {

    // reset this component as current page
    this.me.currentPage = null;

  }


  private loadPage(pageId: string): Observable<IUiPage> {

    return this.me.call<IUiPage>('browse.page', {

      areaId: "public",
      pageId: pageId

    });

  }

  public reloadPage() {

    // defaults to home
    var nav = 'home';

    // concatenate url segments, e.g. 'about/gdpr'
    if (this.route.snapshot.url.length)
      nav = _.map(this.route.snapshot.url, urlSeg => urlSeg.path).join('/');

    this.loadPage(nav)
        .subscribe(page => this.updatePage(page));
  }

  private updatePage(page: IUiPage): Observable<IUiPage> {

    // nothing to do
    if (!page)
      return of(page);

    // update CSS and style
    super.update(page);

    // set page
    this.page = page;

    // set page title
    if (this.page.title) {
      this._headerTitle = this.page.title;
      this.title.setTitle(`${this.me.domainTitle} - ${this.page.title}`);
    }
    else
    if (this.me.domainTitle) {
      this._headerTitle = this.me.domainTitle;
      this.title.setTitle(this.me.domainTitle);
    }
    else {
      this._headerTitle = this.me.session.domainId;
      this.title.setTitle(this.me.session.domainId);
    }

    // scroll to top
    this.scrollElement?.nativeElement.scrollTo(0, 0);
    this.scrollLastOffset = 0;
    this.scrolled = false;
    this.scrollDir = null;

    return of(page);
  }

  public onVisibilityChanged(section: IUiPageSection, status: IntersectionStatus) {
    console.log(`section ${section} ${status}`);
  }

  public onSectionClick(section: IUiPageSection) {

    const navigate = section.navigate ?? section.data?.navigate;
    if (navigate) {

      // scroll or navigate
      if (navigate.startsWith('#'))
        this.scrollToSection(navigate.substring(1));
      else
        this.me.navigate(navigate);

    }
  }

  public scrollToSection(sectionId: string) {

    if (!this.page?.sections?.length)
      return;

    var idx = _.findIndex(this.page.sections, (section) => section.sectionId == sectionId);
    if (idx >= 0) {
      const element = this.sections.toArray()[idx].host.nativeElement;
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }

  public scrollToTop() {

    this.scrollElement.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });

  }

}
