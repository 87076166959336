import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'ui-navsheet-btn',
    templateUrl: './ui-navsheet-btn.component.html',
    styleUrls: ['./ui-navsheet-btn.component.css'],
    standalone: true,
    imports: [MatIcon, NgClass]
})
export class UiNavsheetBtnComponent {

  @Input('icon') icon: string;
  @Input('label') label: string;
  @Input('active') active: boolean;
  @Input('forward') forward: boolean;
  @Input('value') value: any;

  constructor()
  {
  }

  get sectionIconClass(): string {
    return `section-icon ${this.active ? 'active' : 'material-icons-outlined inactive'}`;
  }

  get labelClass(): string {
    return `label-large ${this.active ? 'label-active' : 'inactive'}`;
  }

  get forwardIconClass(): string {
    return `forward-icon ${this.active ? 'active' : 'material-icons-outlined inactive'}`;
  }

}
