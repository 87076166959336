
@if (orderSummary) {

  <div class="toolbar">

    <button mat-flat-button (click)="previous()">
      <span class="toolbar-button-content">
        <mat-icon>chevron_left</mat-icon>
        {{text.form.caption}}
      </span>
    </button>

    <div class="spacer"></div>

    <button mat-flat-button (click)="next()">
      <span class="toolbar-button-content">
        {{text.buy.order}}
        <mat-icon>chevron_right</mat-icon>
      </span>
    </button>

  </div>

  <div class="form">

    <ui-order-summary [summary]="orderSummary"></ui-order-summary>

  </div>

}
