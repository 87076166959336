import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiBtnMenuComponent } from '../ui-btn-menu/ui-btn-menu.component';
import { UiCartBtnComponent } from '../ui-cart-btn/ui-cart-btn.component';

@Component({
    selector: 'ui-toolbar-top',
    templateUrl: './ui-toolbar-top.component.html',
    styleUrls: ['./ui-toolbar-top.component.css'],
    standalone: true,
    imports: [NgClass, MatIconButton, MatIcon, UiBtnMenuComponent, UiCartBtnComponent]
})
export class UiToolbarTopComponent implements OnInit, OnChanges {

  @Input('state') public state: string;
  @Input('animate') public animate: boolean = true;
  @Input('title') public title: string;
  @Input('titleClass') public titleClass: string;
  @Input('subtitle') public subtitle: string;
  @Input('subtitleClass') public subtitleClass: string;
  @Input('backVisible') public backVisible: boolean = undefined;
  @Input('cartBtn') public cartBtn: boolean = false;
  @Input('systemMenu') public systemMenu: boolean = false;
  @Input('contentOnly') public contentOnly: boolean = false;

  @Output('back') public back = new EventEmitter<void>();

  public get isBackVisible(): boolean {

    // if explicitly set backVisible
    if (this.backVisible !== undefined)
      return this.backVisible;

    // true, if observed
    return this.back.observed;

  }

  public get css(): string {
    return `${this.me.windowSizeCompact ? 'compact' : ''}  ${this.state ?? ''} ${this.animate ? 'animate' : ''}`;
  }

  public getTitleClass(): string {
    if (this.titleClass)
      return this.titleClass;
    else
      return 'title-medium strong';
  }

  public getSubtitleClass(): string {
    if (this.subtitleClass)
      return this.subtitleClass;
    else
      return 'label-medium dimmed';
  }

  constructor(public me: MeService,
    private nav: NavService,
    private renderer: Renderer2,
    private host: ElementRef)
  {
  }

  ngOnInit(): void {

    this.renderer.setAttribute(this.host.nativeElement, 'css', this.css);

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.renderer.setAttribute(this.host.nativeElement, 'css', this.css);

  }

  onBack() {

    if (this.back.observed)
      this.back.emit();
    else
      this.nav.back();

  }
}
