import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, NgZone, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatList, MatListItem, MatListItemLine, MatListItemTitle } from '@angular/material/list';
import { Title } from '@angular/platform-browser';
import { Observable, mergeMap, of } from 'rxjs';
import { IUiEventsHistory } from '../../models/ui.interface';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';

@Component({
  selector: 'view-events',
  templateUrl: './view-events.component.html',
  styleUrls: ['./view-events.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, CdkScrollable, AsyncPipe, UiBtnFabComponent, UiSectionHeaderComponent,
            MatList, MatListItem, MatListItemTitle, MatListItemLine, MatRipple]
})
export class ViewEventsComponent
  extends UiScrollableComponent
  implements OnInit {

  @ViewChild('content', { static: false })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  @ViewChildren('years')
  years: QueryList<UiSectionHeaderComponent>;

  // Events
  public events$: Observable<IUiEventsHistory>;
  public events: IUiEventsHistory;

  // properties
  public get headerTitle(): string {

    if (this.events)
      return this.events.title;

    return null;

  }

  constructor(
    me: MeService,
    public nav: NavService,
    renderer: Renderer2,
    host: ElementRef,
    private title: Title,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone)

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

    this.events$ = this.loadEvents()
      .pipe(
        mergeMap(events => this.updateEvents(events))
      );

}

  ngAfterViewChecked() {

    // set image after tile is stabilized - problems with virtual scroll
    // measuring offsetWidth and offsetHeight breaks scrolling to top
    super.afterViewChecked(this.events);

  }

  private loadEvents(): Observable<IUiEventsHistory> {

    return this.me.call<IUiEventsHistory>('browse.event.history');

  }

  private updateEvents(events: IUiEventsHistory): Observable<IUiEventsHistory> {

    // nothing to do
    if (!events)
      return of(events);

    // update CSS and style
    super.update(events);

    // set page
    this.events = events;

    // set page title
    if (this.events.title) {
      this.title.setTitle(`${this.me.domainTitle} - ${this.events.title}`);
    }

    // scroll to top
    this.scrollElement?.nativeElement.scrollTo(0, 0);
    this.scrollLastOffset = 0;
    this.scrolled = false;
    this.scrollDir = null;

    return of(events);
  }


  public scrollToTop() {

    this.scrollElement.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });

  }

  isExpanded(index: number) {
    return this.years.get(index)?.expanded;
  }

}
