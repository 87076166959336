import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MeService } from '../../services/me.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { UiEntityViewComponent } from '../ui-entity-view/ui-entity-view.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';

@Component({
  selector: 'ui-entity-detail',
  templateUrl: './ui-entity-detail.component.html',
  styleUrls: ['./ui-entity-detail.component.css'],
  standalone: true,
  imports: [MatIconButton, MatIcon, UiToolbarTopComponent, UiEntityViewComponent, UiBtnFabComponent, CdkScrollable]
})
export class UiEntityDetailComponent
  extends UiScrollableComponent
  implements OnInit {


  @Input('docId')
  public docId: string;

  @Output('back')
  public back = new EventEmitter<void>();

  @ViewChild('content', { static: true, read: ElementRef<HTMLElement> })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  @ViewChild('entityView', { static: true })
  public entityView: UiEntityViewComponent;

  // current entity
  public get title(): string {

    if (!this.scrolledMin)
      return null;

    if (this.entityView.view)
      return this.entityView.view.name || this.id;
    else
      return null;
  }

  public get id(): string {

    if (!this.scrolledMin)
      return null;

    if (this.entityView.view)
      return this.me.getPathLast(this.entityView.view.id);
    else
      return null;
  }

  constructor(public override me: MeService,
    renderer: Renderer2,
    host: ElementRef,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone);

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

  }

  ////////////////////////////////////////////
  // back
  public get backVisible(): boolean {
    return this.back.observed;
  }

  public onBack() {
      this.back.emit();
  }


}
