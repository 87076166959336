import { Component, Input } from '@angular/core';
import { ProductPrice } from '../../models/cart.class';
import { MeService } from '../../services/me.service';

@Component({
    selector: 'ui-price-product',
    templateUrl: './ui-price-product.component.html',
    styleUrls: ['./ui-price-product.component.css'],
    standalone: true,
    imports: []
})
export class UiPriceProductComponent {

  @Input('price') public price: ProductPrice;
  @Input('hint') public hint: boolean = undefined;
  @Input('end') public end: boolean = false;

  public get text(): any { return this.me.texts.components['ui-price']; }

  public get hasSource(): boolean { return this.hasStandardPrice || this.hasSpecialPrice; }

  public get sourceLabel(): string
  {
    if (!this.price)
      return null;

    // first line
    var firstLine: string = null;

    // show other VAT only if applicable
    if (this.price.standardPrice.type.includesVAT !== null) {

      // format special price
      if (this.price.specialPrice) {
        if (this.price.specialPrice.type.includesVAT === true)
          firstLine = this.price.specialPrice.excVAT ? `${this.text.excVAT} ${this.price.specialPrice.valueText(this.me.locale, 'excVAT')}` : null;
        else
        if (this.price.specialPrice.type.includesVAT === false)
          firstLine = this.price.specialPrice.incVAT ? `${this.text.incVAT} ${this.price.specialPrice.valueText(this.me.locale, 'incVAT')}` : null;
        else
          firstLine = this.price.specialPrice.valueText(this.me.locale, 'incVAT');
      }
      else
      // format standard price
      if (this.price.standardPrice) {
        if (this.price.standardPrice.type.includesVAT === true)
          firstLine = this.price.standardPrice.excVAT ? `${this.text.excVAT} ${this.price.standardPrice.valueText(this.me.locale, 'excVAT')}` : null;
        else
        if (this.price.standardPrice.type.includesVAT === false)
          firstLine = this.price.standardPrice.incVAT ? `${this.text.incVAT} ${this.price.standardPrice.valueText(this.me.locale, 'incVAT')}` : null;
        else
          firstLine = this.price.standardPrice.valueText(this.me.locale, 'incVAT');
      }

    }

    // second line
    var secondLine: string = null;

    // format special price
    if (this.price.specialPrice) {

      // fill in price list type, if any
      if (this.price.specialPrice.priceListType == 'action')
        secondLine = `${this.text.action} ${this.price.specialPrice.type.label}`;
      else
      if (this.price.specialPrice.priceListType == 'individual')
        secondLine = `${this.text.individual} ${this.price.specialPrice.type.label}`;
      else
          secondLine = this.price.specialPrice.type.label;
    }
    else
    // format standard price
    if (this.price.standardPrice)
        secondLine = this.price.standardPrice.type.label;

    // third line - allocations
    var thirdLine: string = null;
    if (this.price.allocated) {
      thirdLine = `${this.text.allocated} ${this.price.allocated}, ${this.text.remaining} ${this.price.remaining}`;
    }

    var result = firstLine ? `${firstLine}<br/>${secondLine}` : secondLine;

    return thirdLine ? `${result}<br/>${thirdLine}` : result;

  }

  public get sourceVisible(): boolean {

    if (this.hint == true) return true;
    if (this.hint == false) return false;
    return !this.me.isVisitor && this.hasSource;

  }

  // standard price
  public get hasStandardPrice(): boolean {
    return this.price?.standardPrice != null;
  }
  public get standardPriceText(): string {
      return this.price?.standardPrice.valueText(this.me.locale);
  }

  // special price
  public get hasSpecialPrice(): boolean {
    return this.price?.specialPrice != null;
  }
  public get specialPriceText(): string {
    return this.price?.specialPrice.valueText(this.me.locale);
  }

  constructor(public me: MeService) { }

}
