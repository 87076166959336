import { Component, Input } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import * as _ from 'lodash';
import { SelectService } from '../../services/select.service';
import { UiCartBtnComponent } from '../ui-cart-btn/ui-cart-btn.component';

@Component({
    selector: 'ui-cart-count',
    templateUrl: './ui-cart-count.component.html',
    styleUrls: ['./ui-cart-count.component.css'],
    standalone: true,
    imports: [MatBadge, MatIconButton, MatIcon, UiCartBtnComponent]
})
export class UiCartCountComponent {

  @Input('productId') public productId: string;
  @Input('toggleOnly') public toggleOnly: boolean = false;
  @Input('countOnly') public countOnly: boolean = false;

  public get count(): number {
    return this.select.getCount(this.productId);
  };

  public set count(value: number) {
    this.select.select(this.productId, value);
  };

  public get btnIcon(): string {
    return this.count > 0 ? 'add' : 'add_shopping_cart';
  }

  constructor(public select: SelectService) { }

  public decrementCount(event: Event) {

    if (_.isNumber(this.count))
      this.count--;
    else
      this.count = 0;

    event.stopPropagation();
    return false;
  }

  public onCart(event: Event) {

    if (this.toggleOnly)
      this.count = this.count > 0 ? 0 : 1;
    else
    if (_.isNumber(this.count))
      this.count++;
    else
      this.count = 1;

    event.stopPropagation();
    return false;
  }

}
