
<div class="rating">

  <mat-icon (click)="onClick(1)">{{icon(1)}}</mat-icon>

  <mat-icon (click)="onClick(2)">{{icon(2)}}</mat-icon>

  <mat-icon (click)="onClick(3)">{{icon(3)}}</mat-icon>

  <mat-icon (click)="onClick(4)">{{icon(4)}}</mat-icon>

  <mat-icon (click)="onClick(5)">{{icon(5)}}</mat-icon>

</div>

@if (note.time) {
<div class="label-small subtitle">
  {{note.time | date:'short':null:me.locale}}
</div>
}
@else {
<div class="label-small subtitle dimmed">
  {{msg.note.my}}
</div>
}
