import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from "lodash";
import { IEntityCollectionList, IEntityFilter, IEntityFilterData, IEntityGrouping, IEntitySelector, IEntitySort, ISelector, ISelectorSection } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { MatDivider } from '@angular/material/divider';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ui-selector',
    templateUrl: './ui-selector.component.html',
    styleUrls: ['./ui-selector.component.css'],
    standalone: true,
    imports: [MatToolbar, NgIf, MatIconButton, MatBadge, MatMenuTrigger, MatIcon, NgFor, UiSectionHeaderComponent, MatChipListbox, MatChipOption, MatMenu, MatMenuContent, MatMenuItem, MatDivider]
})
export class UiSelectorComponent {

  @Input('filter')
  public filter: IEntityFilter;

  public get selector(): IEntitySelector { return this.filter.selector; }

  @Output('selectionChanged')
  public selectionChanged = new EventEmitter<Array<string>>();

  public get text(): any { return this.me.texts.components['ui-selector']; }
  public get sections(): Array<ISelectorSection> { return this.selector?.sections; }
  public get data(): { [key: string]: IEntityFilterData } { return this.filter.data; }
  public get resetDisabled(): boolean {

    var anySelected = false;

    _.forEach(this.sections, (section) => {

      if (this.anySelectorSelected(section))
        anySelected = true;

    });

    return !anySelected;
  }

  public get selectedSections(): Array<ISelectorSection> {

    if (!this.selector.selectedSections)
      this.selector.selectedSections = [];

    return this.selector.selectedSections;
  }

  public set selectedSections(sections: Array<ISelectorSection>) {
    this.selector.selectedSections = sections;
  }

  public get selectionPath(): string {

    var path = '';

    _.forEach(this.selectedSections, (section) => {

      _.forEach(section.selectors, (selector) => {

        if (selector.state) {

          if (path != '')
            path += ', ';

          path += selector.title;

        }

      });

    });

    if (path)
      return `${this.text.selectTitle} ${path}`;
    else
      return null;
  }


  constructor(public me: MeService) { }

  public toggleSelected(section: ISelectorSection, selector: ISelector) {

    // ignore with current count < 1
    if (selector.currentCount < 1)
      return;

    // toggle state
    selector.state = !selector.state

    // if selected
    if (selector.state) {

      // push section into selected years
      if (!_.includes(this.selectedSections, section))
        this.selectedSections.push(section);

      // if last section -> show
      // if within remaining unselected years is max one available selector
      // if ~ 5 products available
      //if ((selector.state && this.selectedSections.length == this.years.length) ||
      //    this.remainingSectionsUnambiguous() ||
      //    (this.countEntitySelection() <= 5)) {

      //  this.onShow();

      //}
      //else

      }
    else {

      // deselected, if no selector selected within section,
      // remove it from selectedSections
      if (_.find(section.selectors, (selector) => { return selector.state; }) == undefined)
        _.pull(this.selectedSections, section);

      //if (!_.reduce(section.selectors, (result, selector) => { return result |= selector.selected; }, false))
      //  _.pull(this.selectedSections, section);

    }

    // re-compute
    this.compute(section);

  }

  public onShow() {

    var docIds = new Array<string>;
    _.forEach(this.data, (value: IEntityFilterData, key) => {
      if (value.select[0])
        docIds.push(key);
    });

    this.selectionChanged.emit(docIds);
  }

  public onReset() {

    // reset selector state
    this.reset();

  }

  // re-compute state
  public compute(section: ISelectorSection) {

    // reset entity selections
    this.resetEntitySelection()

    // select only entities
    _.forEach(this.data, (value: IEntityFilterData, key) => {

      value.select[0] = this.computeSelection(value.select);

    });

    // update current counts
    this.updateCurrentCounts(section);

    // update selector path
    this.selector.path = this.selectionPath;
  }

  public computeSelection(value: Array<boolean>): boolean {

    var selected = true;

    _.forEach(this.sections, (section) => {

      // if any selector within section selected
      if (this.anySelectorSelected(section)) {

        // or selected selectors
        var multiSelection = false;

        // if selector is selected, entity must match, otherwise fails
        _.forEach(section.selectors, (selector) => {

          if (selector.state && value[selector.id + 1])
            multiSelection = true;

        });

        // if no selection within section -> not selected
        if (!multiSelection)
          selected = false;

      }

    });

    return selected;
  }

  public updateCurrentCounts(currrentSection: ISelectorSection) {

    _.forEach(this.sections, (section) => {

      if (section == currrentSection)
        return;

      _.forEach(section.selectors, (selector) => {

        selector.currentCount = 0;
        _.forEach(this.data, (value: IEntityFilterData, key) => {

          if (value.select[0] && value.select[selector.id + 1])
            selector.currentCount++;

        });

      });

    });

  }

  public anySelectorSelected(section: ISelectorSection) {

    var anySelected = false;

    // if any selector within section selected
    _.forEach(section.selectors, (selector) => {

      if (selector.state)
        anySelected = true;

    });

    return anySelected;
  }

  // reset state
  public reset() {

    // reset selector state
    _.forEach(this.sections, (section) => {

      _.forEach(section.selectors, (selector) => {

        selector.state = false;
        selector.currentCount = selector.count;

      });

    });

    // reset entity selection
    this.resetEntitySelection();

    // reset selected years
    this.selectedSections = [];

    // reset selector path
    this.selector.path = null;
  }

  public countEntitySelection() {

    // count entities selected
    var count = 0;

    _.forEach(this.data, (value: IEntityFilterData, key) => {

      if (value.select[0])
        count++;

    });

    return count;

  }

  public resetEntitySelection() {

    // all entities selected
    _.forEach(this.data, (value: IEntityFilterData, key) => {

      value.select[0] = true;

    });

  }

  private remainingSectionsUnambiguous(): boolean {

    var unambiguous = true;

    _.forEach(this.sections, (section) => {

      if (!_.includes(this.selectedSections, section)) {

        var numSelectorsWithCurrentCountNonZero = 0;

        _.forEach(section.selectors, (selector) => {

          if (selector.currentCount > 0)
            numSelectorsWithCurrentCountNonZero++;

        });

        if (numSelectorsWithCurrentCountNonZero > 1)
          unambiguous = false;

      };

    });

    return unambiguous;

  }

  // sort
  public get sortVisible(): boolean { return this.filter.sorter ? true : false; }

  public get sorts(): Array<IEntitySort> { return this.filter.sorter?.sorts; }

  public get isSorted(): boolean {
    return this.filter.sorter.selectedSort ? true : false;
  }

  public isSortedBy(sort: IEntitySort): boolean {

    if (sort == null && !this.filter.sorter?.selectedSort)
      return true;

    return this.filter.sorter?.selectedSort === sort;

  }

  public sortBy(sort: IEntitySort) {

    if (this.isSortedBy(sort)) {

      if (sort) {
        if (sort.state == 'asc')
          sort.state = 'desc';
        else
          sort.state = 'asc';

      }

    }
    else {

      this.filter.sorter.selectedSort = sort;
      if (sort && !sort.state)
        sort.state = 'asc';

    }
  }

  public get sortPath(): string {

    var currentSort = this.filter.sorter.selectedSort;
    if (currentSort) {
      var sortDirection = currentSort.state == 'desc' ?
                          this.text.sortDesc :
                          this.text.sortAsc;

      return `${this.text.sortTitle} ${currentSort.title} ${sortDirection}`;
    }

    return null;
  }

  // group
  public get groupVisible(): boolean { return this.filter.grouper ? true : false; }

  public get groupings(): Array<IEntityGrouping> { return this.filter.grouper?.groupings; }

  public get isGrouped(): boolean {
    return this.filter.grouper.selectedGrouping ? true : false;
  }

  public isGroupedBy(grouping: IEntityGrouping): boolean {

    if (grouping == null && !this.filter.grouper?.selectedGrouping)
      return true;

    return this.filter.grouper?.selectedGrouping === grouping;
  }

  public groupBy(grouping: IEntityGrouping) {
    this.filter.grouper.selectedGrouping = grouping;
  }

  public get groupPath(): string {

    var currentGrouping = this.filter.grouper.selectedGrouping;
    if (currentGrouping)
      return `${this.text.groupTitle} ${currentGrouping.title}`;

    return null;
  }

}
