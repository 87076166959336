import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ProductPrice } from '../../models/cart.class';
import { IProductProperties } from '../../models/entity.interface';
import { LineBreaksPipe } from '../../pipes/line-breaks.pipe';
import { MeService } from '../../services/me.service';
import { SheetNoteComponent } from '../sheet-note/sheet-note.component';
import { UiCartCountComponent } from '../ui-cart-count/ui-cart-count.component';
import { UiImageListComponent } from '../ui-image-list/ui-image-list.component';
import { UiNoteRatingComponent } from '../ui-note-rating/ui-note-rating.component';
import { UiPriceProductComponent } from '../ui-price-product/ui-price-product.component';
import { UiPricingComponent } from '../ui-pricing/ui-pricing.component';
import { UiPropertyGroupComponent } from '../ui-property-group/ui-property-group.component';
import { UiStateComponent } from '../ui-state/ui-state.component';

@Component({
    selector: 'ui-entity-view',
    templateUrl: './ui-entity-view.component.html',
    styleUrls: ['./ui-entity-view.component.css'],
    standalone: true,
    imports: [UiImageListComponent, UiStateComponent, UiCartCountComponent, UiPricingComponent, UiPriceProductComponent, UiNoteRatingComponent, MatMiniFabButton, MatIcon, UiPropertyGroupComponent, LineBreaksPipe]
})
export class UiEntityViewComponent implements OnInit {

  @Input('docId')
  public set docId(docId: string) {
    this._docId = docId;
    if (this.isInitialized)
      this.loadView();
  }
  public get docId(): string { return this._docId; }
  private _docId: string;

  @ViewChild('rating', { static: false }) rating: UiNoteRatingComponent;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['ui-entity-view']; }

  public isInitialized: boolean = false;
  public loading: boolean = false;
  public view: IProductProperties = null;
  public pricing: Array<ProductPrice> = null;

  public get changed(): boolean { return this.rating.changed; }

  constructor(public me: MeService, private bottomSheet: MatBottomSheet) {

  }

  ngOnInit() {

    this.isInitialized = true;
    this.loadView();

  }

  loadView() {

    if (this.docId) {

      this.loading = true;

      this.me.call<IProductProperties>('browse.entity.view', {

        docId: this.docId

      }).subscribe((data) => {

        // copy att
        this.view = data;

        // map pricing
        if (data.pricing) {

          this.pricing = data.pricing.map(productPrice =>
            productPrice ? new ProductPrice(productPrice) : null);
        }

      }, null, () => {

        this.loading = false;

      });

    }

  }

  public get manufacturerVisible(): boolean {
    return this.me.session.domainId !== this.view?.manufacturerId;
  }

  public get viewCartCount(): boolean {

    if (!this.me.isVisitor)
      return true;

    return this.view.state.state == 'sale';

  }

  ///////////////////////////////////////
  // note
  public get textNote(): string {
    return this.view.note?.note;
  };
  public set textNote(value: string) {

    if (!this.view || !this.view.note)
      return;

    this.view.note.note = value;

  }
  public get canNote(): boolean {

    return true;

  }

  public openNote(event: Event = null) {

    this.bottomSheet.open(SheetNoteComponent, { data: { entity: this.view } });

    if (event)
      event.preventDefault();

    return false;

  }

}
