import { Component } from '@angular/core';

@Component({
  selector: 'view-assist',
  templateUrl: './view-assist.component.html',
  styleUrls: ['./view-assist.component.css'],
  standalone: true,
  imports: []
})
export class ViewAssistComponent {

  constructor() {

  }

}
