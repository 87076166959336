
<img [src]="imageSrc" loading="lazy" decoding="async" />

<div class="text">

  @if (manufacturerVisible) {
  <div class="label-small manufacturer">{{entity.manufacturer}}</div>
  }

  <div class="label-medium title">{{entity.name || me.getPathLast(entity.id)}}</div>

  <div class="label-small data">

    @if (hasPricing) {
    <ui-price-product [price]="price"
                      [hint]="false"></ui-price-product>
    }

    <ui-state [state]="entity.state"></ui-state>

  </div>

</div>

@if (viewCartCount) {
<ui-cart-count [productId]="entity.id"
               [toggleOnly]="true"
               [countOnly]="true">
</ui-cart-count>
}
