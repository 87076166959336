
<div (click)="close($event)">

  <div class="row">

    <mat-icon>info</mat-icon>

    <span [innerHTML]="text.message"></span>

  </div>

  <div class="row actions">

    <button mat-flat-button color="accent" (click)="reload()">{{text.reload}}</button>

    <button mat-flat-button (click)="close()">{{text.later}}</button>

  </div>

</div>
