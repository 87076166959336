
<mat-icon [ngClass]="sectionIconClass">{{icon}}</mat-icon>

<div [ngClass]="labelClass">{{label}}</div>

@if (forward) {

<mat-icon [ngClass]="forwardIconClass">arrow_forward</mat-icon>

}
@else if (value) {

<span class="label-large value">{{value}}</span>

}
