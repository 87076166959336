
<div mat-dialog-title>{{text.title}}</div>

<mat-dialog-content>

  <div class="label-large" [innerHtml]="text.message"></div>
  <div class="label-medium">{{settings.subject}}</div>

</mat-dialog-content>

<mat-dialog-actions align="end">

  @if (settings.type == 'confirm') {
    <button mat-button mat-dialog-close cdkFocusInitial>{{msg.edit.cancel}}</button>
    <button mat-button [mat-dialog-close]="true">{{text.confirm}}</button>
  }
  @else if (settings.type == 'yesno') {
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>{{msg.option.no}}</button>
    <button mat-button [mat-dialog-close]="true">{{msg.option.yes}}</button>
  }
  @else if (settings.type == 'yesnocancel') {
    <button mat-button mat-dialog-close cdkFocusInitial>{{msg.edit.cancel}}</button>
    <button mat-button [mat-dialog-close]="false">{{msg.option.no}}</button>
    <button mat-button [mat-dialog-close]="true">{{msg.option.yes}}</button>
  }

</mat-dialog-actions>
