import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'ui-dlg-header',
    templateUrl: './ui-dlg-header.component.html',
    styleUrls: ['./ui-dlg-header.component.css'],
    standalone: true,
    imports: [MatIconButton, MatDialogClose, MatIcon, NgIf]
})
export class UiDlgHeaderComponent {

  @Input('title') public title: string;
  @Input('subject') public subject: string;
  @Input('disabled') public disabled: boolean = false;

  constructor() {
  }

}
