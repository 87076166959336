import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MeService } from '../../services/me.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'sheet-common',
  templateUrl: './sheet-common.component.html',
  styleUrls: ['./sheet-common.component.css'],
  standalone: true,
  imports: [MatIcon]
})
export class SheetCommonComponent implements OnInit {

  public message: string
  public icon: string
  public iconColor: string

  constructor(private me: MeService,
    private _bottomSheetRef: MatBottomSheetRef<SheetCommonComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: { message: string, type: string, delay: number }) { }

  ngOnInit() {

    // set message
    this.message = this.data?.message;

    // set content
    switch (this.data?.type) {

      case 'done':
        this.icon = 'done';
        this.iconColor = 'primary';
        break;

      case 'warn':
        this.icon = 'warning';
        this.iconColor = 'warn';
        break;

      case 'error':
        this.icon = 'error';
        this.iconColor = 'warn';
        break;

      case 'info':
      default:
        this.icon = 'info';
        this.iconColor = 'primary';
        break;
    }

    // set timeout, if delay
    if (this.data?.delay) {

      setTimeout(() => {

        this._bottomSheetRef.dismiss();

      }, this.data.delay);

    }

  }

  close(event: MouseEvent): void {

    this._bottomSheetRef.dismiss();
    event.preventDefault();

  }

}
