import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Directive, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MeService } from '../../services/me.service';
import { UiElementComponent } from '../ui-element/ui-element';

@Directive()
export class UiScrollableComponent
       extends UiElementComponent
       implements OnInit {

  
  // Scrolling
  public scrollElement: ElementRef<HTMLElement>;
  public scrollLastOffset: number = 0;
  public scrolledMin: boolean = false;
  public scrolled: boolean = false;
  public scrollDir: string = null;
  public scrollThreshold: number = undefined;

  public get scrollState(): string {
    return this.scrolledMin ? 'scrolled' : null;
  }


  constructor(me: MeService,
    renderer: Renderer2,
    host: ElementRef,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone)
  {
    super(me, renderer, host);
  }

  ngOnInit(): void {

    // handle scrolling
    this.scrollDispatcher.scrolled(200)
      .subscribe((cdkScrollable) => {

        if (cdkScrollable) {
          this.zone.run(() => {

            const target: CdkScrollable = cdkScrollable;
            if (target.getElementRef().nativeElement === this.scrollElement?.nativeElement) {

              const offset = target.measureScrollOffset('top');
              const threshold = this.scrollThreshold ?? target.getElementRef().nativeElement.offsetHeight / 2;
              const delta = 4;

              if (offset > delta)
                this.scrolledMin = true;
              else
              if (offset < delta)
                this.scrolledMin = false;

              if (offset > threshold + delta)
                this.scrolled = true;
              else
              if (offset < threshold - delta)
                this.scrolled = false;

              if (offset - this.scrollLastOffset > delta)
                this.scrollDir = 'down';
              else
              if (offset - this.scrollLastOffset < delta)
                this.scrollDir = 'up';
              else
                this.scrollDir = null;

              this.scrollLastOffset = offset;

            }
          });
        }
      });

  }

}
