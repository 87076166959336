
<div class="header" [class.nobutton]="!button">

  @if (icon) {
  <mat-icon (click)="toggle()">{{icon}}</mat-icon>
  }

  <div class="label label-large" [innerHtml]="label" (click)="toggle()"></div>

  @if (divider) {
  <mat-divider></mat-divider>
  }

  @if (button) {
  <button mat-icon-button (click)="toggle()">
    <mat-icon>{{btnIcon}}</mat-icon>
  </button>
  }

</div>

@if (content && expanded) {
<ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
}

