import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export class FormFieldBaseComponent {

  constructor() { }

  // common inputs
  public formGroup: any;
  public formSchema: any;
  public attribute: any;     // attributeId as string for objects, itemField as object for arrays
  public parentReadonly: boolean = false;
  public culture: any;
  public docId: string;
  public doc: any;
  public idx: any;

  public static baseInputs: string[] = [
    'formGroup',
    'formSchema',
    'attribute',
    'parentReadonly:readonly',
    'culture',
    'docId',
    'doc',
    'idx',
  ];

  public get field() {

    var field = null;

    if (_.isString(this.attribute))
      field = this.formSchema['fields'][this.attribute];
    else
      field = this.attribute; // for array item definition

    return field ? field : {};
  }
  public get schema() { return this.field.schema ? this.field.schema : {}; }
  public get errMsgs() { return this.field.errorMessages; }
  public get defaultValue() { return this.field.defaultValue; }

  public get controlType() { return this.hidden ? 'hidden' : this.field.type; }
  public get ctrlText() {
    return this.controlType == 'gsui/form/field/type/text' ||
      this.controlType == 'gsui/form/field/type/text/email' ||
      this.controlType == 'gsui/form/field/type/text/tel';
  }
  public get ctrlTextArea() { return this.controlType == 'gsui/form/field/type/textArea'; }
  public get ctrlDate() { return this.controlType == 'gsui/form/field/type/date'; }
  public get ctrlHTML() { return this.controlType == 'gsui/form/field/type/html'; }
  public get ctrlCSS() { return this.controlType == 'gsui/form/field/type/css'; }
  public get ctrlAuto() { return this.controlType == 'gsui/form/field/type/auto'; }
  public get ctrlCheckbox() { return this.controlType == 'gsui/form/field/type/checkbox'; }
  public get ctrlSelect() { return this.controlType == 'gsui/form/field/type/select'; }
  public get ctrlFlags() { return this.controlType == 'gsui/form/field/type/flags'; }
  public get ctrlQuantity() { return this.controlType == 'gsui/form/field/type/quantity'; }
  public get ctrlArray() { return this.controlType == 'gsui/form/field/type/array'; }
  public get ctrlObject() { return this.controlType == 'gsui/form/field/type/object'; }

  public get title() { return this.field.title; }
  public get description() { return this.field.description; }
  public get label() { return this.field.label; }
  public get hint() { return this.field.hint; }
  public get placeholder() { return this.field.placeholder; }
  public get prefix() { return this.field.prefix; }
  public get suffix() { return this.field.suffix; }
  public get readonly() { return this.field.readonly || this.parentReadonly; }
  public get hidden() { return this.field.hidden; }
  public get options() { return this.field.valueEnum; }
  public set options(options) { this.field.valueEnum = options; }
  public get enumOnly() { return this.field.enumOnly; }
  public get textInputType() {

    if (this.field.textInputType)
      return this.field.textInputType;

    switch (this.controlType) {
      case 'gsui/form/field/type/text':
        return 'text';

      case 'gsui/form/field/type/text/email':
        return 'email';

      case 'gsui/form/field/type/text/tel':
        return 'tel';

      default:
        return 'text';
    }

  }

  // localization support
  public get localizable() { return this.field.localizable; }
  public get currentCulture() { return this.culture ? this.culture.id : null; }
  public get currentLocale() { return this.culture ? this.culture.locale : null; }

  // array support
  public get isArrayItem() { return _.isInteger(this.idx); }
  public arrButtons: boolean = false;
  public toggle() { this.arrButtons = !this.arrButtons; }

  public get array(): any[] {
    var arr = this.doc;
    if (!_.isArray(arr))
      return null;
    return arr;
  }
  public get canDelete(): boolean { return this.isArrayItem && this.idx >= 0; }
  public get canMoveUP(): boolean { return this.isArrayItem && this.idx > 0; }
  public get canMoveDOWN(): boolean { return this.isArrayItem && this.array && this.idx < this.array.length - 1; }
  public addItem() {

    var arr = this.array;
    if (!arr) return;
    var positions = (arr as any)['positions'];

    // default value
    var defaultValue = _.cloneDeep(this.field.defaultValue);
    if (this.idx == arr.length - 1) {
      arr.push(defaultValue);
      if (positions)
        positions.push(new Object);
    }
    else {
      arr.splice(this.idx - 1, 0, defaultValue);
      if (positions)
        positions.splice(this.idx - 1, 0, new Object);
    }
  }
  public deleteItem() {
    var arr = this.array;
    if (!arr) return;
    var positions = (arr as any)['positions'];

    arr.splice(this.idx, 1);
    if (positions)
      positions.splice(this.idx, 1);
  }
  public moveItemUp() {
    var arr = this.array;
    if (!arr || this.idx === 0) return;

    var temp = arr[this.idx - 1];
    arr[this.idx - 1] = arr[this.idx];
    arr[this.idx] = temp;

    var pos = (arr as any)['positions'];
    if (pos) {
      temp = pos[this.idx - 1];
      pos[this.idx - 1] = pos[this.idx];
      pos[this.idx] = temp;
    }
  }
  public moveItemDown() {
    var arr = this.array;
    if (!arr || this.idx === arr.length - 1) return;

    var temp = arr[this.idx + 1];
    arr[this.idx + 1] = arr[this.idx];
    arr[this.idx] = temp;

    var pos = (arr as any)['positions'];
    if (pos) {

      temp = pos[this.idx + 1];
      pos[this.idx + 1] = pos[this.idx];
      pos[this.idx] = temp;

    }
  }

  // field label
  public get fieldLabel(): string {
    if (this.localizable)
      return `${this.label || this.title}&nbsp;<span>( ${this.currentLocale} )</span>`;
    else
      return this.label || this.title;
  }

  // get/set value, handles localization
  public get value() {
    // if array
    if (_.isArray(this.doc)) {

      if (_.isNumber(this.idx))
        return this.doc[this.idx];

    }

    // if group
    if (_.isObject(this.doc)) {

      var value = (this.doc as any)[this.attribute];

      if (this.localizable && _.isObjectLike(value))
        return value[this.currentCulture];
      else
        return value;

    }

    return undefined;
  }
  public set value(value: any) {

    // if array
    if (_.isArray(this.doc)) {

      if (_.isNumber(this.idx))
        this.doc[this.idx] = value;

    }

    // if group
    if (_.isObjectLike(this.doc)) {

      var exValue = this.doc[this.attribute];

      // set localizable attribute
      if (this.localizable) {

        // if localization exists
        if (_.isObjectLike(exValue)) {

          // set only if existent or value is non-null
          if (_.has(exValue, this.currentCulture) || value)
            exValue[this.currentCulture] = value;
        }
        else
        // if localization does not exist, set only if not null
        if (value)
        {
          var localization = {};
          (localization as any)[this.currentCulture] = value;
          this.doc[this.attribute] = localization;
        }

      }
      else
        this.doc[this.attribute] = value;

    }

  }

  // value type
  public get valueType() { return this.schema['gsdm/attribute/valueType']; }
  public valueTypeIs(type: string): boolean {
    var valueType = this.valueType;
    if (!valueType || !valueType.length)
      return false;

    return valueType[0] === `/${type}`;
  }

  // validator support
  public get required() { return this.schema['gsdm/attribute/valueRequired'] === true; }
  public get requiredErrMsg() { return this.errMsgs['gsdm/attribute/valueRequired']; }

  public get pattern() { return this.schema['gsdm/string/pattern']; }
  public get patternErrMsg() { return this.errMsgs['gsdm/string/pattern']; }

  public get email() { return this.schema['gsdm/attribute/validation/email']; }
  public get emailErrMsg() { return this.errMsgs['gsdm/attribute/validation/email']; }

  public get number() { return this.valueTypeIs('gsdm/number') || this.valueTypeIs('gsdm/quantity'); }
  public get numberErrMsg() { return this.errMsgs['gsdm/number']; }

  public get integer() { return this.valueTypeIs('gsdm/integer'); }
  public get integerErrMsg() { return this.errMsgs['gsdm/integer']; }

  public get reference() { return this.valueTypeIs('gsdm/reference'); }

  // common field control
  public fieldCtrl: FormControl;

  public get errMsg() {

    if (this.fieldCtrl)
      return this.fieldCtrl.hasError('required') ? this.requiredErrMsg :
        this.fieldCtrl.hasError('pattern') ? this.patternErrMsg :
          this.fieldCtrl.hasError('email') ? this.emailErrMsg :
            this.fieldCtrl.hasError('number') ? this.numberErrMsg :
              this.fieldCtrl.hasError('integer') ? this.integerErrMsg :
                '';
    else
      return '';
  }

  // validators

  // must be number
  private numberRegex: RegExp = /^[-+]?(?:\d*\.?\d+|\d+\.?\d*)(?:[eE][-+]?\d+)?$/;

  numberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      // to string
      var value = _.toString(control.value);

      // empty string = null
      if (value == '')
        return null;
      else
        value = _.replace(value, ',', '.');

      // first check regex
      if (!this.numberRegex.test(value))
        return { 'number': { value: value } };

      // then try to convert to number
      var number = Number.parseFloat(value);
      if (_.isNaN(number))
        return { 'number': { value: value } };

      return null;

    };
  }

  // must be integer
  private intRegex: RegExp = /^[-+]?\d+$/;

  intValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      // empty string = null
      if (_.toString(control.value) == '')
        return null;

      // first check regex
      if (!this.intRegex.test(control.value))
        return { 'integer': { value: control.value } };

      // then try to convert to number
      var number = Number.parseInt(control.value);
      if (_.isNaN(number))
        return { 'integer': { value: control.value } };

      return null;

    };
  }

  // form registration
  public register(ctrl: AbstractControl) {

    // add fieldCtrl to formGroup
    if (this.formGroup instanceof FormGroup)
      this.formGroup.registerControl(this.field.id, ctrl);
    else
    if (this.formGroup instanceof FormArray)
      this.formGroup.push(ctrl);

  }


}
