import { Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MeService } from '../../services/me.service';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';
import { UiUserAccountComponent } from '../ui-user-account/ui-user-account.component';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';

@Component({
  selector: 'view-user-account',
  templateUrl: './view-user-account.component.html',
  styleUrls: ['./view-user-account.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, UiUserAccountComponent, CdkScrollable]
})
export class ViewUserAccountComponent
  extends UiScrollableComponent
  implements OnInit {

  @ViewChild('content', { static: true, read: ElementRef<HTMLElement> })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  constructor(public override me: MeService,
    renderer: Renderer2,
    host: ElementRef,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone)

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

  }

}
