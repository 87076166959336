
@if (form && form.sections) {

  @if (form.sections.length >= 1 && form.sections[0].title) {

    @for(section of form.sections; track $index; let i = $index) {

    <div class="step">

      <div class="step-header" (click)="toggleSection(section)">

        <div class="step-icon" [ngClass]="sectionClass(section)">
          <div class="step-icon-content label-medium">{{startAt + i + 1}}</div>
        </div>
        <div class="step-label title-small">{{section.title}}</div>

      </div>

      <div class="step-content" [style.display]="sectionExpanded(section) ? 'block' : 'none'">

        <ng-container *ngTemplateOutlet="formTemplate;context:{section:section}"></ng-container>

      </div>

    </div>

    }

  }
  @else if (form.sections.length == 1 && !form.sections[0].title) {
  

  <div class="formContent no-layout">

    @for (attribute of form.sections[0].attributes; track $index) {

    <form-field [formGroup]="formGroup"
                [formSchema]="form"
                [attribute]="attribute"
                [culture]="culture"
                [docId]="docId"
                [doc]="data"></form-field>

    }

  </div>
   
  }

}

<ng-template #formTemplate let-section="section">

  @if (section.layout && !singleColumn) {

  <div class="formContent">

    @for(row of section.layout.rows; track $index) {

    <div class="formRow"
         [ngClass]="{'itemHidden':isHidden(row)}"
         [ngStyle]="row.style">

      @for (column of row.columns; track $index) {

        <form-field [formGroup]="formGroup"
                    [formSchema]="form"
                    [attribute]="column.attribute"
                    [culture]="culture"
                    [docId]="docId"
                    [doc]="data"
                    [style.flex]="column.flex"></form-field>

      }

    </div>

    }

  </div>

  }
  @else {

  <div class="formContent">

    @for (attribute of section.attributes; track $index) {

    <form-field [formGroup]="formGroup"
                [formSchema]="form"
                [attribute]="attribute"
                [culture]="culture"
                [docId]="docId"
                [doc]="data"></form-field>

    }

  </div>

  }

</ng-template>
