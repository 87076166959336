import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiUserNoteComponent } from '../ui-user-note/ui-user-note.component';

@Component({
  selector: 'ui-user-notes',
  templateUrl: './ui-user-notes.component.html',
  styleUrls: ['./ui-user-notes.component.css'],
  standalone: true,
  imports: [NgFor, UiUserNoteComponent]
})
export class UiUserNotesComponent implements OnInit {

  public get text(): any { return this.me.texts.components['ui-favorites']; }
  public get msg(): any { return this.me.texts.components['common']; }

  public notes: Array<any> = [];
  public get any(): boolean { return this.notes && this.notes.length > 0; }

  constructor(private me: MeService, private nav: NavService) { }

  ngOnInit(): void {

    this.me.call<any>('browse.user.notes', {

      imageRequest: {
        width: 120,
        height: 120,
        roles: null
      }

    }).subscribe((result) => {

      this.notes = result.notes;

    });

  }

  onClick(note: any) {

    this.nav.to(this.me.getAppPath(note.id));

  }

}
