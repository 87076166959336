import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiNavbarBtnComponent } from '../ui-navbar-btn/ui-navbar-btn.component';
import { UiWorkingComponent } from '../ui-working/ui-working.component';
import { UiBtnMenuComponent } from '../ui-btn-menu/ui-btn-menu.component';
import { AuthService } from '../../services/auth.service';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'ui-navbar-bottom',
    templateUrl: './ui-navbar-bottom.component.html',
    styleUrls: ['./ui-navbar-bottom.component.css'],
    standalone: true,
    imports: [UiNavbarBtnComponent, AsyncPipe, UiWorkingComponent, UiBtnMenuComponent]
})
export class UiNavbarBottomComponent {

  public get msg(): any { return this.me.texts.components['common']; }

  constructor(public me: MeService, public ssv: SelectService, public auth: AuthService, private nav: NavService)
  {
  }

  public get cartCount(): number {
    return this.ssv.totalCount > 0 ? this.ssv.totalCount : null;
  }

  public get menuText(): string {
    return this.auth.isSignedIn ? this.me.userInitials : 'Menu';
  }

  public openMenu() {

      this.nav.sideNavToggle();

  }
}
