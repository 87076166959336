import { Component, Input } from '@angular/core';
import { ProductPrice } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { UiPriceProductComponent } from '../ui-price-product/ui-price-product.component';

@Component({
    selector: 'ui-pricing',
    templateUrl: './ui-pricing.component.html',
    styleUrls: ['./ui-pricing.component.css'],
    standalone: true,
    imports: [UiPriceProductComponent]
})
export class UiPricingComponent {

  @Input('pricing') public pricing: Array<ProductPrice>;
  @Input('hint') public hint: boolean = undefined;

  constructor(public me: MeService) { }

}
