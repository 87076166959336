import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SwUpdate } from '@angular/service-worker';
import { MeService } from '../../services/me.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'sheet-application-update',
    templateUrl: './sheet-application-update.component.html',
    styleUrls: ['./sheet-application-update.component.css'],
    standalone: true,
    imports: [MatIcon, MatButton]
})
export class SheetApplicationUpdateComponent implements OnInit {

  public get text(): any { return this.me.texts.components['sheet-application-update']; }

  constructor(public me: MeService, private _updates: SwUpdate,
              private _bottomSheetRef: MatBottomSheetRef<SheetApplicationUpdateComponent>) { }

  ngOnInit() {

  }

  reload() {

    // not enabled
    if (!this._updates.isEnabled) {
      this.me.newVersionAvailable = false;
      return;
    }

    this._updates.activateUpdate()
      .then(() => {

        document.location.reload();

      });

  }

  close(event: MouseEvent = null): void {

    this._bottomSheetRef.dismiss();

    if (event)
      event.preventDefault();

  }

}
