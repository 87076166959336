<mat-form-field>

  <mat-label>
    {{currentTitle}}
    @if (localizable) {
      &nbsp;<span>( {{currentLocale}} )</span>
    }
  </mat-label>

  <input #autoCompleteInput matInput [formControl]="fieldCtrl" [matAutocomplete]="auto"
         (focus)="onFocus()" (blur)="onBlur()" spellcheck="true"
         [lang]="currentLocale" [class.link]="valueIsLink()">

  @if (loading) {

  <mat-icon matSuffix class="spin">hourglass_empty</mat-icon>

  }

  @if (fieldCtrl?.invalid) {

  <mat-error>{{errMsg}}</mat-error>

  }

</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">

  @for (option of autoOptions; track $index) {

  <mat-option [value]="option">
    <span [class.link]="isLink(option)">{{option.text || option.value}}</span>
  </mat-option>

  }

</mat-autocomplete>
