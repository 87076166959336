import { Component, Input } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { LineBreaksPipe } from '../../pipes/line-breaks.pipe';
import { MeService } from '../../services/me.service';
import { UiNoteRatingComponent } from '../ui-note-rating/ui-note-rating.component';
import { UiStateComponent } from '../ui-state/ui-state.component';

@Component({
  selector: 'ui-user-note',
  templateUrl: './ui-user-note.component.html',
  styleUrls: ['./ui-user-note.component.css'],
  standalone: true,
  imports: [UiStateComponent, UiNoteRatingComponent, MatDivider, LineBreaksPipe]
})
export class UiUserNoteComponent {

  @Input('note') public note: any;

  public get imageSrc(): string {
    return this.note.image?.imageSrc;
  }

  public get manufacturerVisible(): boolean {
    return this.me.session.domainId !== this.note.manufacturerId;
  }

  constructor(public me: MeService) { }

}
