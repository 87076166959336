import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Input, NgZone, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatList, MatListItem, MatListItemLine, MatListItemTitle } from '@angular/material/list';
import { Title } from '@angular/platform-browser';
import { Observable, mergeMap, of } from 'rxjs';
import { IUiEventSet, IUiEventsHistory } from '../../models/ui.interface';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';

@Component({
  selector: 'view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, CdkScrollable, AsyncPipe, UiBtnFabComponent, UiSectionHeaderComponent,
            MatList, MatListItem, MatListItemTitle, MatListItemLine, MatRipple]
})
export class ViewEventComponent
  extends UiScrollableComponent
  implements OnInit {

  @Input('eventId')
  public eventId: string;

  @ViewChild('content', { static: false })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  // Events
  public eventSet$: Observable<IUiEventSet>;
  public eventSet: IUiEventSet;

  constructor(
    me: MeService,
    public nav: NavService,
    renderer: Renderer2,
    host: ElementRef,
    private title: Title,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone)

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

    this.eventSet$ = this.loadEventSet(this.eventId)
      .pipe(
        mergeMap(eventSet => this.updateEventSet(eventSet))
      );

}

  ngAfterViewChecked() {

    // set image after tile is stabilized - problems with virtual scroll
    // measuring offsetWidth and offsetHeight breaks scrolling to top
    super.afterViewChecked(this.eventSet);

  }

  private loadEventSet(eventId: string): Observable<IUiEventSet> {

    return this.me.call<IUiEventSet>('browse.event', {
      eventId: eventId
    });

  }

  private updateEventSet(eventSet: IUiEventSet): Observable<IUiEventSet> {

    // nothing to do
    if (!eventSet)
      return of(eventSet);

    // update CSS and style
    super.update(eventSet);

    // set page
    this.eventSet = eventSet;

    // set page title
    if (this.eventSet.title) {
      this.title.setTitle(`${this.me.domainTitle} - ${this.eventSet.title}`);
    }

    // scroll to top
    this.scrollElement?.nativeElement.scrollTo(0, 0);
    this.scrollLastOffset = 0;
    this.scrolled = false;
    this.scrollDir = null;

    return of(eventSet);
  }


  public scrollToTop() {

    this.scrollElement.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });

  }


}
