<mat-form-field>

  <mat-label>{{label || title}}</mat-label>

  <mat-select [formControl]="fieldCtrl" [required]="required ? 'required' : null">

    @for (option of options; track $index) {

    <mat-option [value]="option.value" [disabled]="option.disabled">
      {{option.text || option.value}}
    </mat-option>

    }

  </mat-select>

  @for (note of field?.noteMessages; track $index) {

  <mat-hint>{{note}}</mat-hint>

  }

</mat-form-field>
