import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import * as _ from "lodash";
import { IPropertyValue } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { UiPropertyGroupComponent } from '../ui-property-group/ui-property-group.component';

@Component({
    selector: 'ui-property-value',
    templateUrl: './ui-property-value.component.html',
    styleUrls: ['./ui-property-value.component.css'],
    standalone: true,
  imports: [
    DatePipe,
    forwardRef(() => UiPropertyGroupComponent)
  ]
})
export class UiPropertyValueComponent implements OnInit {

  constructor(public me: MeService) { }

  @Input('value') public value: IPropertyValue;

  ngOnInit() {
  }

  valueType(value: any) {

    if (value.type == 'gsdm/reference')
      return 'reference';

    if (value.type == 'gsdm/url')
      return 'url';

    if (value.type == 'gsdm/date' ||
        value.type == 'gsdm/time' ||
        value.type == 'gsdm/dateTime')
      return 'dateTime';

    if (_.isArray(value.formatted))
      return 'array';

    if (_.isObjectLike(value.formatted))
      return 'object';

    return 'text';
  }

  url(value: any): string {

    return value.original ?? value.formatted;

  }

}
