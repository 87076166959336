@if (data) {

  <ui-toolbar-top [title]="title"
                  [subtitle]="subtitle"
                  [state]="scrollState"
                  [backVisible]="backVisible"
                  (back)="onBack()">

    @if (numIds > 1) {

    <div class="navigation">

      <button mat-icon-button [disabled]="!canPrev" (click)="onPrev()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      <span class="index label-medium dimmed">{{currentIdx + 1}} / {{numIds}}</span>

      <button mat-icon-button [disabled]="!canNext" (click)="onNext()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>

    </div>

    }

  </ui-toolbar-top>

  <div #content class="content" cdkScrollable>

    <ui-entity-view #entityView
                    [docId]="currentId">

      <!--(swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()"-->

    </ui-entity-view>

  </div>

  @if (entityView?.canNote) {

    <ui-btn-fab icon="edit" (onClick)="entityView.openNote()"></ui-btn-fab>

  }


}
