
@if (!isEmpty) {

  <div class="toolbar">

    <button mat-flat-button (click)="onPrevious.emit()">
      <span class="toolbar-button-content">
        <mat-icon>chevron_left</mat-icon>
        {{text.items.caption}}
      </span>
    </button>

    <div class="spacer"></div>

    <button mat-flat-button [disabled]="!deliveryOption" (click)="onNext.emit()">
      <span class="toolbar-button-content">
        {{text.form.caption}}
        <mat-icon>chevron_right</mat-icon>
      </span>
    </button>

  </div>

  <div class="items">

    <div class="messages">
      <div *ngFor="let message of messages" class="label-large message">{{message}}</div>
    </div>

    <div class="title-medium title">{{text.delivery.title}}</div>

    <mat-radio-group [(ngModel)]="deliveryOption">

      <mat-radio-button *ngFor="let option of options"
                        class="item"
                        [value]="option">

        <div class="item-content">

          <div class="item-title">
            <span class="label-large">{{option.name}}</span>
            @if (option.description) {
            <span class="label-small">{{option.description}}</span>
            }
          </div>

          <ui-price class="label-large" [price]="option.price" [hint]="false"></ui-price>

        </div>

      </mat-radio-button>

    </mat-radio-group>

    <div class="price-description label-medium">{{text.items.priceDescription}}<br />{{priceDescription}}</div>

  </div>

}
