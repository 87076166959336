import { Location } from '@angular/common'
import { EventEmitter, Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import * as _ from 'lodash'
import { MeService } from './me.service'

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private history: string[] = [];

  public onNav = new EventEmitter<any>();

  constructor(private me: MeService,
              private router: Router,
              private location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })

  }

  back(): void {

    this.history.pop()
    if (this.history.length > 0)
      this.location.back()
    else
      this.router.navigateByUrl('/')
  }

  sideNavToggle(section: string = null): void {

    this.onNav.emit(section);

  }

  isA(path: string): boolean {

    return _.startsWith(this.router.url, path);

  }

  to(path: string) {
    this.router.navigateByUrl(path)
  }

}
