import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-checkbox',
  templateUrl: './form-field-checkbox.component.html',
  styleUrls: ['./form-field-checkbox.component.css'],
  standalone: true,
  imports: [MatCheckbox, FormsModule, ReactiveFormsModule],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldCheckboxComponent extends FormFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);

    // create field control and set value
    this.fieldCtrl = new FormControl(this.value, {
      validators: validators,
      updateOn: 'change'
    });

    // set disabled
    if (this.readonly)
      this.fieldCtrl.disable();

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(100))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do not promote undefined
        if (_.isUndefined(value))
          return;

        // set value
        this.value = value;

      });

    // register control
    this.register(this.fieldCtrl);

  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.value);

  }

}
