
<div>

  <div class="row">

    <mat-icon>info</mat-icon>

    <span class="label-medium" [innerHTML]="msg.user.signinMessage"></span>

  </div>

  <div class="show-message">

    <mat-checkbox (change)="noshow($event.checked)">
      <span class="label-medium">{{text.noshow}}</span>
    </mat-checkbox>

  </div>

  <div class="row actions">

    <button class="signin-btn" mat-button (click)="signIn()">
      <mat-icon>login</mat-icon>
      <span class="label-large">{{msg.nav.user_signin}}</span>
    </button>

    <button mat-flat-button (click)="close()">
      <span class="label-large">{{text.later}}</span>
    </button>

  </div>

</div>
