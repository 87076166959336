@if (headerTheme && !headerInContent) {

<ui-toolbar-top [title]="headerTitle"
                [titleClass]="headerTitleClass"
                [state]="headerState"
                [backVisible]="headerBackVisible"
                [cartBtn]="headerCartBtnVisible"></ui-toolbar-top>

}

@if (page$ | async; as page)
{

<div #content class="content" cdkScrollable>

  @if (headerTheme && headerInContent) {

  <ui-toolbar-top [title]="headerTitle"
                  [titleClass]="headerTitleClass"
                  [state]="headerState"
                  [backVisible]="headerBackVisible"
                  [cartBtn]="headerCartBtnVisible"></ui-toolbar-top>

  }

  @for (section of page.sections; track section) {

  <ui-page-section #sections
                   [pageComponent]="this"
                   [section]="section"></ui-page-section>

  }

</div>

}

<ui-btn-fab icon="vertical_align_top" [class.visible]="scrolled" (onClick)="scrollToTop()"></ui-btn-fab>
