import { Component, OnInit, ViewChild, Input, NgZone } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MeService } from '../../services/me.service';
import { MatIcon } from '@angular/material/icon';
import { CopyClipboardDirective } from '../../directives/copy-clipboard.directive';
import { MatIconButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'form-field-text-copy',
    templateUrl: './form-field-text-copy.component.html',
    styleUrls: ['./form-field-text-copy.component.css'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatInput, MatIconButton, MatSuffix, MatTooltip, CopyClipboardDirective, MatIcon]
})
export class FormFieldTextCopyComponent implements OnInit {

  @Input('label') public label: string;
  @Input('value') public value: any;
  @ViewChild('tooltip', { static: false }) toolTip: MatTooltip;

  public get msg(): any { return this.me.session.texts.components['common']; }
  public copyTooltipText: string;

  constructor(private me: MeService) {
  }

  ngOnInit() {

    this.copyTooltipText = this.msg.clipboard.copy;

  }

  public copied() {

    this.copyTooltipText = this.msg.clipboard.copied;
    this.toolTip.show();

    setTimeout(() => {

      this.toolTip.hide();

      setTimeout(() => {

        this.copyTooltipText = this.msg.clipboard.copy;

      }, 1000);

    }, 2000);

  }

}
