
<mat-form-field>

  <mat-label>{{label}}</mat-label>

  <input matInput [type]="type"
                  [formControl]="fieldCtrl"
                  [errorStateMatcher]="matcher"
                  [required]="required ? 'required' : null"
                  [readonly]="!editing">

  @if (!editing && !saving) {

  <button mat-icon-button matSuffix (click)="edit()">
    <mat-icon>mode_edit</mat-icon>
  </button>

  }
  @else if (editing) {

  <button mat-icon-button matSuffix [disabled]="!saveEnabled" (click)="save()">
    <mat-icon>done</mat-icon>
  </button>
  <button mat-icon-button matSuffix (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>

  }

  @if (saving) {

  <span matSuffix class="saving">{{msg.io.saving}}...</span>

  }

  @if (fieldCtrl.invalid) {

  <mat-error>{{errMsg}}</mat-error>

  }

</mat-form-field>
