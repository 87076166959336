<div class="property-list">

  @for (property of group?.properties; track property) {
  <div class="property-row">

    @if (showPropertyName) {
    <div class="dt">{{property.name}}</div>
    }
    <ui-property-value [value]="property.value"></ui-property-value>

  </div>
  }

</div>

