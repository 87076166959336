import { Component, OnInit, Input } from '@angular/core';
import { MeService } from '../../services/me.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as _ from 'lodash';
import { FormFieldComponent } from '../form-field/form-field.component';
import { NgClass, NgTemplateOutlet, NgStyle } from '@angular/common';

@Component({
    selector: 'ui-form',
    templateUrl: './ui-form.component.html',
    styleUrls: ['./ui-form.component.css'],
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, NgStyle, FormFieldComponent, FormsModule, ReactiveFormsModule]
})
export class UiFormComponent implements OnInit {

  @Input('form') public form: any;
  @Input('docId') public docId: string;
  @Input('data') public data: any;
  @Input('culture') public culture: any;
  @Input('startAt') public startAt: number = 0;

  public singleColumn: boolean = false;
  public formGroup: FormGroup = new FormGroup({});

  constructor(public me: MeService) { }

  ngOnInit() {

    // breakpoint observer - Android software keyboard problem
    this.singleColumn = this.me.windowSizeCompact;

  }

  public sectionClass(section: any) {

    if (section.disabled || !section.expanded)
      return 'step-icon-collapsed';
    else
      return 'step-icon-expanded';

  }

  public toggleSection(section: any) {

    if (section.disabled)
      return;

    section.expanded = section.expanded ? false : true;

  }

  public sectionExpanded(section: any) {

    if (section.disabled)
      return false;

    return section.expanded;

  }

  public isHidden(item: any) {
    if (item.visible)
      return !_.isMatch(this.data, item.visible);
    else
      return false;
  }

}
