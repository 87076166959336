import { Pipe, PipeTransform } from '@angular/core';
import { MeService } from '../services/me.service';

@Pipe({
    name: 'lineBreaks',
    standalone: true
})
export class LineBreaksPipe implements PipeTransform {

  constructor(private me: MeService) {

  }
  
  transform(text: string = ''): string {
    return this.me.lineBreaksToHtml(text);
  }
}
