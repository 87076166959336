import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'ui-btn-fab',
    templateUrl: './ui-btn-fab.component.html',
    styleUrls: ['./ui-btn-fab.component.css'],
    standalone: true,
    imports: [MatMiniFabButton, MatIcon]
})
export class UiBtnFabComponent {

  @Input('icon') public icon: string;

  @Output('onClick') public onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

}
