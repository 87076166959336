import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private me: MeService,
    private _router: Router) {

  }

  open() {

    // this._bottomSheet.open(SheetSearchComponent);
    var currentRoute = this._router.routerState.snapshot.url;
    this._router.navigateByUrl(`/search${currentRoute}`);
  }

}
