
<div class="user-detail">

  <div>

    <div class="label-medium">{{msg.user.roles}}</div>
    <ul>
      <li class="label-small" *ngFor="let role of user.roles">
        {{role.name}}
      </li>
    </ul>

  </div>

  <div>

    <div class="label-medium">{{msg.user.areas}}</div>
    <ul>
      <li class="label-small" *ngFor="let area of user.areas">
        {{area.name}}
      </li>
    </ul>

  </div>

  <div>

    <div class="label-medium">{{msg.user.permissions}}</div>
    <ul>
      <li class="label-small" *ngFor="let permission of user.permissions">
        {{permission.name}}
      </li>
    </ul>

  </div>

</div>
