
<div class="price-value" [class.bold]="sourceVisible" [class.end]="end">
  @if(hasStandardPrice) {
  <span [class.strikethrough]="hasSpecialPrice">{{standardPriceText}}</span>
  }
  @if (hasSpecialPrice) {
  <span class="action">{{specialPriceText}}</span>
  }
</div>

@if (sourceVisible) {
<div class="price-type label-small" [class.red]="hasSpecialPrice" [innerHtml]="sourceLabel"></div>
}

