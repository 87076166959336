
@switch(valueType(value)) {

  @case('text') {
  <div class="dd" [innerHtml]="value.formatted"></div>
  }
  @case('dateTime') {
  <div class="dd">{{value.formatted | date:'medium':null:me.locale}}</div>
  }
  @case('reference') {
  <div class="dd reference">{{value.formatted}}</div>
  }
  @case('url') {
  <a class="dd url" href="{{url(value)}}" target="_blank" [innerHtml]="value.formatted"></a>
  }
  @case('array') {
  <div class="dd">
    <ul>
      @for(item of value.formatted; track $index) {
      <li>
        @switch(valueType(item)) {
          @case('text') {
          <span [innerHtml]="item.formatted"></span>
          }
          @case('reference') {
          <span class="reference">{{item.formatted}}</span>
          }
          @case('url') {
          <a  class="url" href="{{url(item)}}" target="_blank" [innerHtml]="item.formatted"></a>
          }
          @default {
          <ui-property-value [value]="item"></ui-property-value>
          }
        }
      </li>
      }
    </ul>
  </div>
  }
  @case('object') {
  <div class="dd">
    <ui-property-group [group]="value.formatted"></ui-property-group>
  </div>
  }

}
