
<mat-form-field>

  <mat-label>{{labelText}}</mat-label>

  <input [class.action] = "!standard"
         matInput type="number"
         [formControl]="fieldCtrl"
         [errorStateMatcher]="matcher"
         [required]="required ? 'required' : null"
         [readonly]="readonly">

  <span class="suffix dimmed" matSuffix>{{suffix}}</span>
  <mat-hint class="dimmed" align="end">{{computedValueText}}</mat-hint>

  @if (errMsg) {

  <mat-error>{{errMsg}}</mat-error>

  }

</mat-form-field>
