
@if (!contentOnly) {

  @if (isBackVisible) {
  <button mat-icon-button (click)="onBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  }
  @else {
  <div class="spacer-left"></div>
  }

  <div class="title">
    <span [ngClass]="getTitleClass()">{{title}}</span>
    @if (subtitle) {
    <span [ngClass]="getSubtitleClass()">{{subtitle}}</span>
    }
  </div>

  <div class="spacer"></div>
}

  <ng-content></ng-content>

@if (!contentOnly) {

  @if (cartBtn) {
  <ui-cart-btn></ui-cart-btn>
  }

  @if (systemMenu) {
  <ui-btn-menu></ui-btn-menu>
  }

}
