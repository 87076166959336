
@if (section.html) {

<div class="htmlhost" [innerHTML]="html"
     matRipple [matRippleCentered]="true" [matRippleDisabled]="!section.navigate"
     (click)="onSectionClick(section)"></div>

}
@else {

    @switch(section.data?.type) {

      @case('img') {

      <!-- IMG -->
      <img [src]="section.data.src" (click)="onSectionClick(section)" />

      }

      @case('button') {

      <!-- BUTTON -->
      <button mat-button matRipple [matRippleColor]="section.data.ripple" (click)="onSectionClick(section)">

        @if (section.data.subtitle) {
        <span class="btn-with-subtitle">
          <span class="title-medium">{{section.data.title}}</span>
          <span class="label-medium">{{section.data.subtitle}}</span>
        </span>
        }
        @else {
        {{section.data.title}}
        }
      </button>

      }

      @case('button.icon') {

      <!-- BUTTON.ICON -->
      <button mat-icon-button (click)="onSectionClick(section)">
        @if (svgIcon(section)) {
          <mat-icon [svgIcon]="svgIcon(section)"></mat-icon>
        } @else {
          <mat-icon>{{section.data.icon}}</mat-icon>
        }
      </button>

      }

      @case('divider') {

      <!-- DIVIDER -->
      <mat-divider></mat-divider>

      }

      @case('tile.product') {

      <!-- TILE.PRODUCT -->
      <ui-entity-tile [entity]="section.data.product"
                      matRipple [matRippleCentered]="true"
                      (click)="onSectionClick(section)"></ui-entity-tile>

      }

      @default {

      <ng-content></ng-content>

      }

    }

}

<!-- SECTIONS -->
@for (section of section.sections; track $index) {

  <ui-page-section [pageComponent]="pageComponent"
                   [section]="section"></ui-page-section>

}
