import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { interval } from 'rxjs';
import * as _ from "lodash";

interface ISpeechSynthesisUtterance extends Window {
  webkitSpeechSynthesisUtterance: any;
  mozSpeechSynthesisUtterance: any;
  msSpeechSynthesisUtterance: any;
  oSpeechSynthesisUtterance: any;
  SpeechSynthesisUtterance: any;
}

interface ISpeechRecognition extends Window {
  webkitSpeechRecognition: any;
  //mozSpeechRecognition: any;
  //msSpeechRecognition: any;
  //oSpeechRecognition: any;
  SpeechRecognition: any;
}

/////////////////////////////////////////////
// add ALL system voices on Windows 10 for Chrome and Firefox
// https://stackoverflow.com/questions/47379725/how-do-i-add-a-voice-language-to-speechsynthesis


@Injectable({
  providedIn: 'root'
})
export class SpeechService implements OnDestroy {

  constructor(private zone: NgZone) {

    // speech synthesis
    const { SpeechSynthesisUtterance }: ISpeechSynthesisUtterance = <ISpeechSynthesisUtterance>(<unknown>window);
    if (SpeechSynthesisUtterance)
      this.speechSynthesis = window.speechSynthesis;

    // create speech recognition service
    const { webkitSpeechRecognition }: ISpeechRecognition = <ISpeechRecognition>(<unknown>window);
    if (webkitSpeechRecognition) {

      this.speechRecognition = new webkitSpeechRecognition();
      this.speechRecognition.continuous = false;
      this.speechRecognition.interimResults = false;
      this.speechRecognition.lang = this.locale;
      this.speechRecognition.maxAlternatives = 1;
      this.speechRecognition.onresult = (speech: any) => this.onListenResult(speech);
      this.speechRecognition.onerror = (error: any) => this.onListenError(error);
      this.speechRecognition.onstart = () => this.onListenStart();
      this.speechRecognition.onend = () => this.onListenEnd();

    }

    // set speech availability window object - for http service to 
    //window['speech.synthesisAvaliable'] = this.synthesisAvaliable;
    //window['speech.recognitionAvaliable'] = this.recognitionAvaliable;

    // set caret
    this.caretInterval = interval(750)
      .subscribe(() => {

        if (this.listening)
          this.caret = (this.caret == 'on') ? 'off' : 'on';
        else
          this.caret = 'on';

      });

  }

  // locale
  public get locale(): string { return document.documentElement.getAttribute('lang'); }

  // dialog interaction
  public disabled: boolean = false;
  public get available(): boolean { return this.speechSynthesis || this.speechRecognition; }
  public get synthesisAvaliable(): boolean { return this.speechSynthesis ? !this.disabled : false; }
  public get synthesisEnabled(): boolean { return this._synthesisEnabled; }
  public set synthesisEnabled(value: boolean) {

    this._synthesisEnabled = value;
    if (value) {
      // enable on iOS
      this.synthesisEnableiOS();
    }

  }
  private _synthesisEnabled: boolean = false;
  public synthesisToggle(): boolean {

    // if enabled
    if (this.synthesisEnabled) {

      // disable
      this.synthesisEnabled = false;

      // stop speaking
      this.speechStop();

    }
    else {

      // enable
      this.synthesisEnabled = true;

      // enable on iOS
      this.synthesisEnableiOS();

    }

    // return current speech synthesis state
    return this.synthesisEnabled;
  }
  private _synthesisEnablediOS: boolean = false;
  public synthesisEnableiOS() {

    // do it just once
    if (!this._synthesisEnablediOS) {

      // for iOS, speak "" to enable speech synthesis
      // must be called from onclick handler
      this.speechSynthesis.speak(new SpeechSynthesisUtterance(''));

      this._synthesisEnablediOS = true;

    }

  }

  public get recognitionAvaliable(): boolean { return this.speechRecognition ? !this.disabled : false; }
  public get recognitionEnabled(): boolean { return this._recognitionEnabled; }
  public set recognitionEnabled(value: boolean) { this._recognitionEnabled = value; }
  private _recognitionEnabled: boolean = false;

  public enabled: boolean = false;
  public toggle() {

    if (this.speaking) {
      this.speechStop();
      this.listenStart();
    }
    else
      if (this.listening) {
        this.listenStop();
        this.enabled = false;
      }
      else
        if (!this.enabled) {
          this.enabled = true;
          if (this.speechUtterance)
            this.speakUtterance(this.speechUtterance);
          else
            this.listenStart();
        }
        else
          this.enabled = false;
  }

  // synthesis
  private speechSynthesis: SpeechSynthesis;
  private speechUtterance: SpeechSynthesisUtterance;
  public get canSpeak(): boolean { return this.synthesisAvaliable && this.synthesisEnabled; }
  public get speaking(): boolean { return this.speechSynthesis && (this.speechSynthesis.speaking || this.speechSynthesis.pending); }
  public speak(speech: string, onSpeechEnd: any = null) {

    // test we can speak
    if (!this.canSpeak)
      return;

    // clear micHint
    this.micHint = false;

    // create new utterance
    this.speechUtterance = new SpeechSynthesisUtterance(speech);
    this.speechUtterance.lang = this.locale;

    // save callback
    this.onSpeechEnd = onSpeechEnd;

    // for longer texts
    this.speechUtterance.onstart = (event) => {
      this.resumeInfinity();
    };

    // on end, start listening
    this.speechUtterance.onend = () => {

      // clear timeout resume
      clearTimeout(this.timeoutResumeInfinity);

      // clear utterance
      this.speechUtterance = null;

      // Run this code inside Angular's Zone and perform change detection
      this.zone.run(() => {

        // call function provided by the caller
        if (this.onSpeechEnd) {

          this.onSpeechEnd();

        }

      });

    }

    // start speaking
    this.speakUtterance(this.speechUtterance);

  }
  public speakUtterance(utterance: SpeechSynthesisUtterance) {

    // test we can speak
    if (!this.canSpeak)
      return;

    // stop listening
    this.listenStop();

    // find voice
    var speechVoices = this.speechSynthesis.getVoices();
    for (var i = 0; i < speechVoices.length; i++) {
      var voice = speechVoices[i];
      if (_.startsWith(voice.lang, utterance.lang))
      {
        utterance.voice = voice;
        break;
      }
    }

    // speak
    this.speechSynthesis.speak(utterance);

  }
  public speechStop() {

    // cancel all utterances
    this.speechSynthesis.cancel();

    // clear current utterance
    this.speechUtterance = null;

  }
  public onSpeechEnd: any;

  // https://stackoverflow.com/questions/21947730/chrome-speech-synthesis-with-longer-texts/23808155#23808155
  public timeoutResumeInfinity: any;
  public resumeInfinity() {
    window.speechSynthesis.resume();
    this.timeoutResumeInfinity = setTimeout(this.resumeInfinity, 1000);
  }

  // listening
  private speechRecognition: any;
  public get canListen(): boolean { return this.recognitionAvaliable && this.recognitionEnabled; }
  public listening: boolean = false;
  public listenStart(onResult: any = null) {

    // if available
    if (this.canListen) {

      // stop speaking
      this.speechStop();

      // save callback
      this.onResult = onResult;

      // start recognition
      this.listening = true;
      this.speechRecognition.lang = this.locale;
      this.speechRecognition.start();

      // no micHint
      this.resultFound = false;
      this.micHint = false;
    }

  }
  public listenStop() {

    // stop speech recognition
    if (this.speechRecognition && this.listening)
      this.speechRecognition.abort();

  }
  private onResult: any;

  private onListenResult(speech: { results: { [x: string]: any; }; resultIndex: string | number; }) {

    // Run this code inside Angular's Zone and perform change detection
    this.zone.run(() => {

      // get the speech result
      let term: string = "";
      if (speech.results) {

        var result = speech.results[speech.resultIndex];
        var transcript = result[0].transcript;
        if (result.isFinal) {
          if (result[0].confidence < 0.3) {
            console.log("--rec: Unrecognized result - Please try again");
          }
          else {
            term = _.trim(transcript);
            console.log("--rec: " + term);
          }
        }
      }

      // if we have something
      if (term) {

        // result has been found
        this.resultFound = true;

        // send it to signal service as input text
        // this.signal.test(term);

        // call function provided by the caller
        if (this.onResult) {

          this.onResult(term);

        }

      }

    });

  }
  private onListenError(error: { error: string; }) {

    //error
    if (error.error == "no-speech") {

      console.log("--rec: no-speech --");

      // Run this code inside Angular's Zone and perform change detection
      this.zone.run(() => {

        this.listening = false;
        this.micHint = true;

      });

    }
    else
    if (error.error == "aborted") {
      console.log("--rec: aborted --");
    }
    else {
      console.log(error);
    }

  }
  private onListenStart() {

    // Fired when the speech recognition service has begun listening to incoming audio
    console.log("--rec: start --");
    this.micHint = false;

  }
  private onListenEnd() {

    // end
    console.log("--rec: end --");

    // Run this code inside Angular's Zone and perform change detection
    this.zone.run(() => {

      this.listening = false;
      if (!this.resultFound)
        this.micHint = true;

    });

  }
  public resultFound: boolean = false;
  public micHint: boolean = false;

  // caret support
  private caretInterval: any;
  public caret: string = 'on';

  ngOnDestroy() {

    this.speechStop();
    this.listenStop();

  }
}
