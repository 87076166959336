import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMediaView } from '../../models/media.interface';
import { MeService } from '../../services/me.service';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';


@Component({
    selector: 'dlg-image-view',
    templateUrl: './dlg-image-view.component.html',
    styleUrls: ['./dlg-image-view.component.css'],
    standalone: true,
    imports: [MatIconButton, MatIcon, NgClass]
})
export class DlgImageViewComponent {

  public get imageSrc(): string {
    return this.image.imageSrc;
  }

  @ViewChild('imgHost', { static: true })
  public imgHost: ElementRef;

  @ViewChild('img', { static: true })
  public img: ElementRef;

  public isOverview: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public image: IMediaView,
    private dialogRef: MatDialogRef<DlgImageViewComponent>,
    public me: MeService) {
  }

  public scrollTo: any = null;

  ngAfterViewChecked() {

    if (this.scrollTo) {

      this.imgHost.nativeElement.scrollTo(this.scrollTo.x, this.scrollTo.y);
      this.scrollTo = null;

    }
  }

  public onClose() {
    this.dialogRef.close();
  }

  public onImageClick(event: MouseEvent) {

    // toggle overview and detail
    this.isOverview = this.isOverview ? false : true;

    // scroll to click
    if (!this.isOverview) {

      // click coordinates relative to img element
      var x = (event.offsetX / this.img.nativeElement.offsetWidth);
      var y = (event.offsetY / this.img.nativeElement.offsetHeight);

      // scroll to center
      this.scrollTo = {
        x: this.image.imageSet[0].width * x - event.clientX,
        y: this.image.imageSet[0].height * y - event.clientY
      }

      //// scroll after image is zoomed in
      //setTimeout((scroll) => {

      //  this.imgHost.nativeElement.scrollTo(scroll.x, scroll.y);

      //}, 100, scroll);

    }

  }
}
