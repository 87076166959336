
<ui-toolbar-top [title]="title" (back)="onBack()"></ui-toolbar-top>

@if (step == 'items') {

<ui-cart-item-list class="step"
                   (onNext)="step = 'delivery'"></ui-cart-item-list>

} @else if (step == 'delivery') {

<ui-cart-deliveryoption-list class="step"
                             (onPrevious)="step = 'items'"
                             (onNext)="step = 'form'"></ui-cart-deliveryoption-list>

} @else if (step == 'form') {


<ui-cart-form class="step"
              (onPrevious)="step = 'delivery'"
              (onNext)="step = 'summary'"></ui-cart-form>

} @else if (step == 'summary') {

<ui-cart-summary class="step"
                 (onPrevious)="step = 'form'"
                 (onNext)="cartSent()"></ui-cart-summary>

} @else if (step == 'sent') {

<ui-cart-sent class="step"
              (onNext)="onBack()"></ui-cart-sent>

}
