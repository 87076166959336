import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, forwardRef } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatExpansionPanel, MatExpansionPanelActionRow, MatExpansionPanelContent, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import * as _ from 'lodash';
import { MeService } from '../../services/me.service';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'form-object',
  templateUrl: './form-object.component.html',
  styleUrls: ['./form-object.component.css'],
  standalone: true,
  imports: [
    MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatExpansionPanelContent,
    NgClass, NgTemplateOutlet, NgStyle, FormsModule, ReactiveFormsModule, MatExpansionPanelActionRow, MatButton,
    forwardRef(() => FormFieldComponent)],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormObjectComponent extends FormFieldBaseComponent implements OnInit {

  constructor(private me: MeService) {
    super();
  }

  public singleColumn: boolean;
  public objectGroup: FormGroup = new FormGroup({});

  public get isDefined(): boolean { return !_.isUndefined(this.value) && !_.isNull(this.value); }
  public get objectSchema(): any { return this.field.objectForm; }
  public get objectTitle(): string {
    if (_.isString(this.field.objectForm.objectTitle))
      return this.field.objectForm.objectTitle;
    else
      return this.title;
  }

  ngOnInit() {

    // register control
    this.register(this.objectGroup);

    // breakpoint observer - Android software keyboard problem
    this.singleColumn = this.me.windowSizeCompact;

  }

  public createNew() {

    if (this.defaultValue)
      this.value = _.cloneDeep(this.defaultValue);

  }

  public sectionClass(section: any) {

    if (section.disabled || !section.expanded)
      return 'step-icon-collapsed';
    else
      return 'step-icon-expanded';

  }

  public toggleSection(section: any) {

    if (section.disabled)
      return;

    section.expanded = section.expanded ? false : true;

  }

  public sectionExpanded(section: any) {

    if (section.disabled)
      return false;

    return section.expanded;

  }

}
