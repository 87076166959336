import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-text',
  templateUrl: './form-field-text.component.html',
  styleUrls: ['./form-field-text.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatHint, MatError],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldTextComponent extends FormFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);
    if (this.email) validators.push(Validators.email);
    if (this.pattern) validators.push(Validators.pattern(this.pattern));
    if (this.number) validators.push(this.numberValidator());
    if (this.integer) validators.push(this.intValidator());
    //Validators.maxLength(255),

    // create field control
    this.fieldCtrl = new FormControl(this.value, {
      validators: validators,
      updateOn: 'change' // this.isArrayItem ? 'blur' : 'change'
    });

    // set disabled
    if (this.readonly)
      this.fieldCtrl.disable();

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(500))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do not promote undefined
        if (_.isUndefined(value))
          return;

        // if null
        if (_.isNull(value)) {
          this.value = null;
          return;
        }

        // if empty string
        if (value === '') {
          this.value = null;
          return;
        }

        // try to convert
        var parsedValue: number;
        if (this.integer)
          parsedValue = Number.parseInt(value);
        else
        if (this.number)
          parsedValue = Number.parseFloat(value);
        else
        {
          this.value = value;
          return;
        }

        // test parsed value
        if (_.isFinite(parsedValue))
          this.value = parsedValue;
        else
          this.value = null;

      });

    // register control
    this.register(this.fieldCtrl);

  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.value);

  }

}
