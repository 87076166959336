import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-select',
  templateUrl: './form-field-select.component.html',
  styleUrls: ['./form-field-select.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatOption, MatHint],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldSelectComponent extends FormFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

    // create field control and set value
    this.fieldCtrl = new FormControl(this.value);

    // set disabled
    if (this.readonly)
      this.fieldCtrl.disable();

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(100))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do not promote undefined
        if (_.isUndefined(value))
          return;

        // set current value
        this.value = value;

      });

    // register control
    this.register(this.fieldCtrl);

  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.value);

  }

}
