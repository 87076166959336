
<div class="label-large">{{label || title}}</div>

<p class="checkbox-list">

  @for (flagCtrl of flagsCtrlArr; track $index) {

  <mat-checkbox [formControl]="flagCtrl">
    {{flagText(flagCtrl)}}
  </mat-checkbox>

  }

</p>
