
<button mat-icon-button class="closebtn" (click)="onClose()">
  <mat-icon>arrow_back</mat-icon>
</button>

<div #imgHost class="image-host" [ngClass]="{'center':isOverview}">

  <img #img class="image" [src]="imageSrc" (click)="onImageClick($event)" />

</div>
