import { Component, EventEmitter, Output } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../services/auth.service';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { SelectService } from '../../services/select.service';

const MENU_BTN =
`<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
</svg>`;

const MENU_BTN_USER =
`<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M11 18h10v-2h-10zm-2-5h12v-2h-12zm-6-5h18v-2h-18z"/>
</svg>`;

@Component({
    selector: 'ui-btn-menu',
    templateUrl: './ui-btn-menu.component.html',
    styleUrls: ['./ui-btn-menu.component.css'],
    standalone: true,
    imports: [MatIconButton, MatBadge, MatIcon]
})
export class UiBtnMenuComponent {

  @Output('onMenu') onMenu = new EventEmitter<any>();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private auth: AuthService, private me: MeService, private nav: NavService) {

    iconRegistry.addSvgIconLiteral('menu-btn', sanitizer.bypassSecurityTrustHtml(MENU_BTN));
    iconRegistry.addSvgIconLiteral('menu-btn-user', sanitizer.bypassSecurityTrustHtml(MENU_BTN_USER));

  }

  public get menuBadgeHidden(): boolean {
    return !this.auth.isSignedIn;
  }

  public get menuText(): string {
    return this.me.userInitials;
  }

  public get menuIcon(): string {
    return this.menuBadgeHidden ? 'menu-btn' : 'menu-btn-user';
  }
  
  openMenu() {

    if (this.onMenu.observed)
      this.onMenu.emit();
    else
      this.nav.sideNavToggle();
      
  }

}
