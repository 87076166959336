import { Component, Input } from '@angular/core';
import * as _ from "lodash";
import { Price } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ui-price',
    templateUrl: './ui-price.component.html',
    styleUrls: ['./ui-price.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class UiPriceComponent {

  @Input('price') public price: Price;
  @Input('hint') public hint: boolean = true;
  @Input('free') public free: boolean = true;

  public get text(): any { return this.me.texts.components['ui-price']; }


  public get sourceLabel(): string
  {
    if (!this.price)
      return null;

    // first line
    var firstLine: string = null;

    // show other VAT only if applicable
    if (this.price.type.includesVAT !== null) {

        if (this.price.type.includesVAT === true)
          firstLine = this.price.excVAT ? `${this.text.excVAT} ${this.price.valueText(this.me.locale, 'excVAT')}` : null;
        else
        if (this.price.type.includesVAT === false)
          firstLine = this.price.incVAT ? `${this.text.incVAT} ${this.price.valueText(this.me.locale, 'incVAT') }` : null;
        else
          firstLine = this.price.valueText(this.me.locale, 'incVAT');

    }

    return firstLine;

  }

  public get priceText(): string {

    if (this.price?.value === 0 && this.free)
      return this.text.free;
    else
      return this.price?.valueText(this.me.locale);
  }


  constructor(public me: MeService) { }

}
