<mat-expansion-panel>

  <mat-expansion-panel-header>

    <mat-panel-title>{{title}}</mat-panel-title>

  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>

    <div>

      @for(item of items.positions; track item; let i=$index) {

      <form-field [formGroup]="arrayGroup"
                  [formSchema]="formSchema"
                  [attribute]="arrayField"
                  [readonly]="readonly"
                  [culture]="culture"
                  [doc]="items"
                  [idx]="i"></form-field>

      }

      @if (!readonly) {

      <div>

        <button mat-icon-button color="primary" (click)="addArrayItem()">
          <mat-icon>add_circle</mat-icon>
        </button>

      </div>

      }

    </div>

  </ng-template>

</mat-expansion-panel>

