import { Component, OnInit, Input } from '@angular/core';
import { MeService } from '../../services/me.service';
import { DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'ui-note-rating',
    templateUrl: './ui-note-rating.component.html',
    styleUrls: ['./ui-note-rating.component.css'],
    standalone: true,
    imports: [MatIcon, DatePipe]
})
export class UiNoteRatingComponent {

  @Input('docId') public docId: string;
  @Input('note') public note: any;

  public get msg(): any { return this.me.texts.components['common']; }

  private get rating(): number {
    return this.note.rating;
  };
  private set rating(value: number) {
    this.note.rating = value;
  }

  public changed: boolean = false;

  constructor(public me: MeService) { }

  public icon(rating: number): string {

    if (!this.rating)
      return 'star_border';

    return this.rating >= rating ? 'star' : 'star_border';

  }

  public onClick(rating: number) {

    // readonly
    if (!this.docId)
      return;

    // toggle rating by clicking on it
    if (this.rating === rating)
      this.rating = rating - 1;
    else
      this.rating = rating;

    // update note
    this.me.call<any>('browse.entity.note.set', {

      entityId: this.docId,
      note: this.note

    }).subscribe((note) => {

      this.note.time = note?.time;
      this.changed = true;

    });

  }


}
