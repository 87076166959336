import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IPropertyGroup } from '../../models/entity.interface';
import { UiPropertyListComponent } from '../ui-property-list/ui-property-list.component';

@Component({
    selector: 'ui-property-group',
    templateUrl: './ui-property-group.component.html',
    styleUrls: ['./ui-property-group.component.css'],
    standalone: true,
    imports: [NgFor, UiPropertyListComponent]
})
export class UiPropertyGroupComponent implements OnInit {

  @Input('group') public hostGroup: IPropertyGroup;

  constructor() { }

  ngOnInit() {
  }

}
