import { Component, OnInit, forwardRef } from '@angular/core';
import { FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatExpansionPanel, MatExpansionPanelContent, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import * as _ from 'lodash';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.css'],
  standalone: true,
  imports: [
    MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelContent,
    MatIconButton, MatIcon,
    FormsModule, ReactiveFormsModule, 
    forwardRef(() => FormFieldComponent)],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormArrayComponent extends FormFieldBaseComponent implements OnInit {

  public arrayGroup: any = new FormArray([]);

  constructor() {
    super();
  }

  ngOnInit() {

    // register control
    this.register(this.arrayGroup);

  }

  public get arrayField() { return this.field.arrayField; }

  // simulates an array with one empty value to create first field
  public get items(): any {

    // what if underlying value is null or empty array or something else
    var array = this.value;

    if (!_.isArray(array) || !array.length)
      return [];
    else {

      // make sure we have positions
      if (!(array as any)['positions']) {

        var positions = [];
        for (var i = 0; i < array.length; i++)
          positions.push(new Object());
        (array as any)['positions'] = positions;

      }

      return array;
    }
  }

  public addArrayItem() {

    // array must be current value
    var arr = this.value;
    if (!_.isArray(arr))
      arr = [];

    // default value
    if (this.field.arrayField)
      arr.push(_.cloneDeep(this.field.arrayField.defaultValue));
    else
      arr.push(_.cloneDeep(this.field.defaultValue));

    if (arr['positions'])
      arr['positions'].push(new Object);

    this.value = arr;
  }

}
