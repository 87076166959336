
<ui-toolbar-top [title]="title"
                [subtitle]="id"
                [state]="scrollState"
                [backVisible]="true"></ui-toolbar-top>

<div #content class="content" cdkScrollable>

  <ui-entity-view #entityView [docId]="docId"></ui-entity-view>

</div>

@if (entityView?.canNote) {

  <ui-btn-fab icon="edit" (onClick)="entityView.openNote()"></ui-btn-fab>

}
