
<ui-navbar-btn icon="home" [label]="msg.nav.home" path="/home" [inactivePaths]="['/products', '/me', '/admin', '/assist']"></ui-navbar-btn>

<ui-navbar-btn icon="shopping_bag" [label]="msg.nav.products_wines" path="/products" [activePaths]="['/products', '/me/cart']" [badge]="cartCount"></ui-navbar-btn>

@if (me.hasAssistent) {
<ui-navbar-btn icon="chat" [label]="msg.nav.assist" path="/assist"></ui-navbar-btn>
}

@if (me.isAdmin) {
<ui-navbar-btn icon="shield" [label]="msg.nav.admin" path="/admin"></ui-navbar-btn>
}

<ui-navbar-btn icon="menu" [label]="menuText" (click)="openMenu()"></ui-navbar-btn>



@if (me.isWorking$ | async) {

<ui-working></ui-working>

}
