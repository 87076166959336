
<ui-btn-menu></ui-btn-menu>

<div class="nav-targets">

  <ui-navbar-btn icon="home" path="/home" [inactivePaths]="['/products', '/me', '/admin', '/assist']"></ui-navbar-btn>

  <ui-navbar-btn icon="shopping_bag" path="/products" [activePaths]="['/products', '/me/cart']" [badge]="cartCount"></ui-navbar-btn>

  @if (me.isAdmin) {
  <ui-navbar-btn icon="shield" path="/admin"></ui-navbar-btn>
  }

  @if (me.hasAssistent) {
  <ui-navbar-btn icon="chat" path="/assist"></ui-navbar-btn>
  }

</div>

@if (me.isWorking$ | async) {

<ui-working></ui-working>

}
