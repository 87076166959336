@if (account) {

  <ui-section-header icon="person" label="{{msg.user.user}}" [divider]="true" [button]="false"></ui-section-header>

  <div class="form">

    <form-field-text-edit class="form-field"
                          [label]="msg.user.name"
                          type="text"
                          [value]="account.user"
                          property="name"
                          [required]="auth.isSignedIn"
                          [errRequired]="text.errNameRequired"
                          (onSave)="saveUserName($event)">
    </form-field-text-edit>

    <form-field-text-edit class="form-field"
                          [label]="msg.user.email"
                          type="email"
                          [value]="account.user"
                          property="email"
                          [required]="auth.isSignedIn"
                          [errRequired]="text.errEmailRequired"
                          [errValid]="text.errEmailValid"
                          (onSave)="saveUserEmail($event)">
    </form-field-text-edit>

  </div>


  <ui-section-header icon="account_circle" label="{{msg.user.account}}" [divider]="true" [button]="false"></ui-section-header>

  <div class="form">

    @if (auth.isSignedIn) {

      <form-field-text-copy class="form-field" [label]="msg.auth.accountId" [value]="account.user.id"></form-field-text-copy>
      <form-field-text-copy class="form-field" [label]="text.deviceID" [value]="deviceId"></form-field-text-copy>

      <div class="account-actions">
        <button mat-button (click)="onArchive()">
          {{text.btnArchive}}
        </button>
        <button mat-button (click)="onRestore()">
          {{text.btnRestore}}
        </button>
      </div>

    } @else {

      <mat-form-field class="form-field">
        <mat-label>{{msg.auth.accountId}}</mat-label>
        <input #deviceIdInput matInput [formControl]="deviceIdCtrl" [errorStateMatcher]="matcher"
               autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
        <button mat-icon-button matSuffix *ngIf="deviceIdCtrl.disabled && me.isMobile" (click)="share()">
          <mat-icon>share</mat-icon>
        </button>
        <button mat-icon-button matSuffix *ngIf="deviceIdCtrl.disabled && !me.isMobile"
                #tooltip="matTooltip" [matTooltip]="copyTooltipText"
                [copy-clipboard]="deviceId" (copied)="copied()">
          <mat-icon>content_copy</mat-icon>
        </button>
        <mat-error *ngIf="errDeviceId">{{errDeviceId}}</mat-error>
      </mat-form-field>

      @if (deviceIdCtrl.disabled) {
      <div class="account-actions">
        @if (!originalId) {
        <button mat-button (click)="onChangeId()">
          {{text.btnChange}}
        </button>
        }
        <button mat-button (click)="onArchive()">
          {{text.btnArchive}}
        </button>
        <button mat-button (click)="restoreInput.click()">
          {{text.btnRestore}}
        </button>
        <input #restoreInput type="file" accept=".json" (change)="onRestore()" style="display: none">
      </div>
      }

      @if (deviceIdCtrl.enabled) {
      <div class="account-actions">
        <button mat-raised-button color="primary" (click)="onCancelId()">
          {{text.btnCancel}}
        </button>
        <button mat-raised-button color="accent" (click)="onCommitId()" [disabled]="onCommitIdDisabled">
          {{text.btnChange}}
        </button>
      </div>
      }


    }

  </div>

  @if (!auth.isSignedIn && originalId) {

  <!--<ui-section-header icon="account_circle" label="{{text.originalAccount}}" [divider]="true" [button]="false"></ui-section-header>-->

  <div class="form body-small">

    <form-field-text-copy [label]="text.originalID" [value]="originalId"></form-field-text-copy>

    <div class="account-actions">
      <button mat-button (click)="onResetId()">{{text.btnReset}}</button>
    </div>

    <p>Tlačítkem <b>RESET</b> nastavíte aktuální číslo účtu zpět na původní číslo účtu.</p>

  </div>

  }


  @if (auth.isSignedIn) {

    <ui-section-header #domainRoles icon="verified_user" label="{{msg.user.rights}} <b>{{me.session.domainId}}</b>" [divider]="true" [button]="!isVisitor"></ui-section-header>

    @if (!domainRoles.expanded) {

    <div class="label-large simple-value">
      {{account.user.roleNames}}
    </div>

    }
    @else {

    <ui-user-detail [user]="account.user"></ui-user-detail>

    }

  } @else {

    <div class="form body-small">

      <!--<p>Protože nejste přihlášeni, tak číslo vašeho účtu je uloženo pouze na tomto zařízení a může být ztraceno (vymazání cookies, použití jiného prohlížeče, přeinstalování aj.).</p>
      <div class="account-actions">
        <button mat-button (click)="onSignIn()">
          {{msg.nav.user_signin}}
        </button>
      </div>-->

      <p>Vaše poznámky k vínům a jejich hodnocení, seznam vybraných vín a další údaje jsou vztaženy k tomuto účtu. Poznamenejte si prosím číslo účtu - to vám umožní vrátit se kdykoli k vašim údajům.</p>
      <p>Stejný účet můžete nastavit na více zařízeních (mobilních telefonech, tabletech, PC, prohlížečích). Změny provedené na jednom zařízení jsou pak viditelné ihned na všech ostatních zařízeních.</p>
      <!--<p>Tlačítko <b>SDÍLET</b> <mat-icon>share</mat-icon> umožní sdílet číslo účtu emailem a dalšími aplikacemi.</p>
      <p>Tlačítkem <b>ZMĚNIT</b> nastavte jiný existující účet na tomto zařízení.</p>-->

    </div>

    @if (!auth.isSignedIn && !originalId) {
    <div class="form body-small">

      <div class="account-actions">
        <button mat-button (click)="onDelete()">
          {{text.btnDelete}}
        </button>
      </div>

      <p>Tlačítkem <b>ZRUŠIT ÚČET</b> vymažete všechny údaje uložené pod tímto účtem. Doporučujeme nejdříve archivovat vaše data, abyste je v případě potřeby mohli obnovit v novém účtu.</p>

    </div>
    }

  }

}
