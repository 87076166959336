import { Component, Input } from '@angular/core';
import { MeService } from '../../services/me.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ui-user-detail',
    templateUrl: './ui-user-detail.component.html',
    styleUrls: ['./ui-user-detail.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class UiUserDetailComponent {

  @Input('user') public user: any;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['ui-user-detail']; }

  constructor(public me: MeService) { }

}
