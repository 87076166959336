import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-date',
  templateUrl: './form-field-date.component.html',
  styleUrls: ['./form-field-date.component.css'],
  standalone: true,
  imports: [MatFormField, MatInput, MatDatepickerInput, FormsModule, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, MatNativeDateModule, MatLabel],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldDateComponent extends FormFieldBaseComponent implements OnInit {

  private adapterLocale: string;

  constructor(private _adapter: DateAdapter<any>) {
    super();
  }

  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);

    // create field control
    this.fieldCtrl = new FormControl(this.value, {
      validators: validators,
      updateOn: 'change'
    });

    // set disabled
    if (this.readonly)
      this.fieldCtrl.disable();

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(500))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do nothing if undefined
        if (_.isUndefined(value))
          return;

        // if null or empty string
        if (_.isNull(value)) {
          this.value = null;
          return;
        }

        // set value
        this.value = value;

      });

    // register control
    this.register(this.fieldCtrl);

    // set current locale
    this.updateAdapterLocale();
  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.value);

    this.updateAdapterLocale();
  }

  private updateAdapterLocale() {

    if (this.adapterLocale != this.currentLocale) {

      // set current locale
      this.adapterLocale = this.currentLocale;
      this._adapter.setLocale(this.currentLocale);

    }

  }

}
