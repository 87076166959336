
@if (cartVisible) {

  <button class="cart" mat-icon-button
          (click)="openCart()"
          [matBadge]="cartText"
          [matBadgeHidden]="cartBadgeHidden">
    <mat-icon [svgIcon]="cartIcon"></mat-icon>
  </button>

}
