import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IProductProperties } from '../../models/entity.interface';
import { UiEntityNoteComponent } from '../ui-entity-note/ui-entity-note.component';

@Component({
    selector: 'sheet-note',
    templateUrl: './sheet-note.component.html',
    styleUrls: ['./sheet-note.component.css'],
    standalone: true,
    imports: [UiEntityNoteComponent]
})
export class SheetNoteComponent {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: { entity: IProductProperties },
              private sheetRef: MatBottomSheetRef<SheetNoteComponent>) {

  }

  close(event: MouseEvent = null): void {

    this.sheetRef.dismiss();

    if (event)
      event.preventDefault();

  }

}
