import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-flags',
  templateUrl: './form-field-flags.component.html',
  styleUrls: ['./form-field-flags.component.css'],
  standalone: true,
  imports: [MatCheckbox, FormsModule, ReactiveFormsModule],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldFlagsComponent extends FormFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  public flagsCtrlArr: Array<FormControl>;

  public getFlag(flag: string) {

    if (!_.isArray(this.value))
      return false;

    var flags: Array<string> = this.value;
    return flags.indexOf(flag) >= 0;

  }
  public setFlag(flag: string, value: boolean) {

    // get current flags
    var currentFlags: Array<string> = new Array<string>();
    if (_.isArray(this.value))
      currentFlags = this.value;

    // new flags
    var newFlags: Array<string> = new Array<string>();

    // use options
    _.forEach(this.options, option => {

      if (option.value == flag) {

        if (value)
          newFlags.push(option.value);

      }
      else
        if (currentFlags.indexOf(option.value) >= 0)
          newFlags.push(option.value);

    });

    // set new flags
    this.value = newFlags;

  }

  ngOnInit() {

    // create array of FormControls
    this.flagsCtrlArr = new Array<FormControl>();

    _.forEach(this.options, option => {

      // create flag control
      var flagCtrl = new FormControl(this.getFlag(option.value));

      // set disabled
      if (this.readonly)
        flagCtrl.disable();

      // add change value handler
      flagCtrl.valueChanges
        .subscribe((value: boolean) => { this.setFlag(option.value, value); });

      // attach flag text
      (flagCtrl as any)['text'] = option.text;

      // add to array
      this.flagsCtrlArr.push(flagCtrl);

    });

    // register control
    this.register(new FormArray(this.flagsCtrlArr));

  }

  ngOnChanges() {

    // rebuild array
    this.ngOnInit();

  }

  flagText(flagCtrl: any) {
    return flagCtrl['text'];
  }
}
