import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MeService } from '../../services/me.service';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'sheet-order-error',
    templateUrl: './sheet-order-error.component.html',
    styleUrls: ['./sheet-order-error.component.css'],
    standalone: true,
    imports: [MatIcon]
})
export class SheetOrderErrorComponent implements OnInit {

  public get text(): any { return this.me.texts.components['sheet-order-error']; }

  constructor(public me: MeService, private _bottomSheetRef: MatBottomSheetRef<SheetOrderErrorComponent>) { }

  ngOnInit() {
  }

  close(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

}
