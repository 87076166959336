import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICulture } from '../../models/session.interface';
import { MeService } from '../../services/me.service';
import { NgFor } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'ui-btn-cultures',
    templateUrl: './ui-btn-cultures.component.html',
    styleUrls: ['./ui-btn-cultures.component.css'],
    standalone: true,
    imports: [MatButton, MatMenuTrigger, MatMenu, NgFor, MatMenuItem]
})
export class UiBtnCulturesComponent {

  constructor(private me: MeService, private router: Router, private route: ActivatedRoute) { }

  public get cultures(): ICulture[] {

    return this.me.session.cultures;

  }

  public get locale(): string {

    return this.me.session.locale;

  }

  public async setLocale(locale: string) {

    // reload session for new locale
    this.me.session.locale = locale;
    await this.me.initSession(this.me.session.domainId);

    // reload page
    await this.me.reload();

  }

}
