
@if (!hidden) {

<div class="field-host">

  @if (isArrayItem && !readonly) {

  <div class="menuBtn">

    <button mat-icon-button (click)="toggle()">
      <mat-icon>more_vert</mat-icon>
    </button>

    @if (arrButtons) {

    <button mat-icon-button [disabled]="!canMoveUP" (click)="moveItemUp()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>

    <button mat-icon-button [disabled]="!canMoveDOWN" (click)="moveItemDown()">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>

    <button mat-icon-button color="accent" [disabled]="!canDelete" (click)="deleteItem()">
      <mat-icon>clear</mat-icon>
    </button>

    }

  </div>

  }

  @if (ctrlCheckbox) {

  <form-field-checkbox class="field"
                       [formGroup]="formGroup"
                       [formSchema]="formSchema"
                       [attribute]="attribute"
                       [readonly]="readonly"
                       [culture]="culture"
                       [docId]="docId"
                       [doc]="doc"
                       [idx]="idx"></form-field-checkbox>

  }
  @else if (ctrlText) {

  <form-field-text class="field"
                   [formGroup]="formGroup"
                   [formSchema]="formSchema"
                   [attribute]="attribute"
                   [readonly]="readonly"
                   [culture]="culture"
                   [docId]="docId"
                   [doc]="doc"
                   [idx]="idx"></form-field-text>

  }
  @else if (ctrlTextArea) {

  <form-field-textarea class="field"
                       [formGroup]="formGroup"
                       [formSchema]="formSchema"
                       [attribute]="attribute"
                       [readonly]="readonly"
                       [culture]="culture"
                       [docId]="docId"
                       [doc]="doc"
                       [idx]="idx"></form-field-textarea>

  }
  @else if (ctrlDate) {

  <form-field-date class="field"
                   [formGroup]="formGroup"
                   [formSchema]="formSchema"
                   [attribute]="attribute"
                   [readonly]="readonly"
                   [culture]="culture"
                   [docId]="docId"
                   [doc]="doc"
                   [idx]="idx"></form-field-date>

  }
  @else if (ctrlAuto) {

  <form-field-auto class="field"
                   [formGroup]="formGroup"
                   [formSchema]="formSchema"
                   [attribute]="attribute"
                   [readonly]="readonly"
                   [culture]="culture"
                   [doc]="doc"
                   [docId]="docId"
                   [idx]="idx"></form-field-auto>

  }
  @else if (ctrlSelect) {

  <form-field-select class="field"
                     [formGroup]="formGroup"
                     [formSchema]="formSchema"
                     [attribute]="attribute"
                     [readonly]="readonly"
                     [culture]="culture"
                     [docId]="docId"
                     [doc]="doc"
                     [idx]="idx"></form-field-select>

  }
  @else if (ctrlFlags) {

  <form-field-flags class="field"
                    [formGroup]="formGroup"
                    [formSchema]="formSchema"
                    [attribute]="attribute"
                    [readonly]="readonly"
                    [culture]="culture"
                    [docId]="docId"
                    [doc]="doc"
                    [idx]="idx"></form-field-flags>

  }
  @else if (ctrlQuantity) {

  <form-field-quantity class="field"
                       [formGroup]="formGroup"
                       [formSchema]="formSchema"
                       [attribute]="attribute"
                       [readonly]="readonly"
                       [culture]="culture"
                       [docId]="docId"
                       [doc]="doc"
                       [idx]="idx"></form-field-quantity>

  }
  @else if (ctrlArray) {

  <form-array class="field"
              [formGroup]="formGroup"
              [formSchema]="formSchema"
              [attribute]="attribute"
              [readonly]="readonly"
              [culture]="culture"
              [docId]="docId"
              [doc]="doc"
              [idx]="idx"></form-array>

  }
  @else if (ctrlObject) {

  <form-object class="field"
               [formGroup]="formGroup"
               [formSchema]="formSchema"
               [attribute]="attribute"
               [readonly]="readonly"
               [culture]="culture"
               [docId]="docId"
               [doc]="doc"
               [idx]="idx"></form-object>

  }

</div>

}


