@if (summary) {
<div>

  <div class="section-header">

    <div class="title-medium section-title">{{title}}</div>
    <div class="label-medium section-subtitle">{{summary.orderId}}</div>

  </div>

  <table mat-table class="table" [dataSource]="summary.items">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="label-large"> {{text.item}} </th>
      <td mat-cell class="table-name" *matCellDef="let item">
        <span class="label-small">{{item.manufacturer}}</span>
        <span class="label-large item-title">{{item.name}}</span>
        @if (item.subtitle) {
          <span class="label-small">{{item.subtitle}}</span>
        }
      </td>
      <td mat-footer-cell class="table-footer label-large" *matFooterCellDef> {{text.total}} </td>
    </ng-container>

    <!-- Count Column -->
    <ng-container matColumnDef="count">
      <th mat-header-cell class="table-count label-large" *matHeaderCellDef> {{text.count}} </th>
      <td mat-cell class="table-count label-large" *matCellDef="let item"> {{item.count}} </td>
      <td mat-footer-cell class="table-count table-footer label-large" *matFooterCellDef> {{summary.itemsCount}} </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price" align="right">
      <th mat-header-cell class="table-price label-large" *matHeaderCellDef> {{text.price}} </th>
      <td mat-cell class="table-price" *matCellDef="let item"> <ui-price class="label-large" [price]="item.price.actual" [hint]="false"></ui-price> </td>
      <td mat-footer-cell class="table-price table-footer" *matFooterCellDef> <ui-price class="label-large" [price]="summary.totalPrice" [hint]="false"></ui-price> </td>
    </ng-container>


    <!-- deliveryType Column -->
    <ng-container matColumnDef="deliveryType">
      <td mat-footer-cell class="table-name" *matFooterCellDef>
        <div class="item-title label-large">{{text.delivery}} {{summary.deliveryOption.name}}</div>
        @if (summary.deliveryOption.packagesCount) {
          <span class="item-subtitle label-small">{{text.packages}} {{summary.deliveryOption.packagesCount}}</span><br />
        }
      </td>
    </ng-container>

    <!-- packageCount Column -->
    <ng-container matColumnDef="packageCount">
      <td mat-footer-cell class="table-count" *matFooterCellDef></td>
    </ng-container>

    <!-- deliveryPrice Column -->
    <ng-container matColumnDef="deliveryPrice">
      <td mat-footer-cell class="table-price" *matFooterCellDef> <ui-price class="label-large" [price]="summary.deliveryOption.price" [hint]="false"></ui-price> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="deliveryColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

  </table>

  <div class="price-description label-medium">{{text.priceDescription}}<br />{{priceDescription}}</div>

  @if (repeatBtnVisible) {
  <div class="section-header">

    <div class="fill-remaining-space"></div>

    <button mat-flat-button color="accent" (click)="repeatOrder()">
      <span class="repeat-order">{{msg.shopping.cart.addToCart}}</span>
      <mat-icon>add_shopping_cart</mat-icon>
    </button>

  </div>
  }

  <ui-property-group [group]="summary.formInfo"></ui-property-group>

</div>
}
