
<mat-sidenav-container autosize>

  <mat-sidenav #sidenav [position]="layoutCompact ? 'end' : 'end'" mode="over">

    <ui-navsheet-root #navroot (onClose)="sidenav.toggle()"></ui-navsheet-root>

  </mat-sidenav>

  <mat-sidenav-content [ngStyle]="{'flex-direction': layoutCompact ? 'column' : 'row-reverse'}">

    @if (layoutCompact) {

    <div>

      <router-outlet></router-outlet>

    </div>

    <ui-navbar-bottom></ui-navbar-bottom>

    } @else {

    <ui-navbar-rail></ui-navbar-rail>

    <div>

      <router-outlet></router-outlet>

    </div>

    }


  </mat-sidenav-content>

</mat-sidenav-container>

