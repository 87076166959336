import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MeService } from '../../services/me.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class ImmediateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
    selector: 'form-field-text-edit',
    templateUrl: './form-field-text-edit.component.html',
    styleUrls: ['./form-field-text-edit.component.css'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatIconButton, MatSuffix, MatIcon, MatError]
})
export class FormFieldTextEditComponent implements OnInit {

  @Input('label') public label: string;
  @Input('type') public type: string = "text";
  @Input('value') public value: any;
  @Input('property') public property: string;
  @Input('required') public required: boolean = false;

  @Input('errRequired') public errRequired: string;
  @Input('errValid') public errValid: string;
  @Input('isSaving') public saving: boolean = false;

  @Output('onSave') onSave = new EventEmitter<any>();

  // common field control
  public fieldCtrl: FormControl;
  public matcher = new ImmediateErrorStateMatcher();
  public editing: boolean = false;
  public get currentValue() {
    if (this.property)
      return this.value[this.property];
    else
      return this.value;
  }
  public set currentValue(value) {
    if (this.property)
      this.value[this.property] = value;
    else
      this.value = value;
  }

  public get saveEnabled(): boolean {
    return this.edit && this.fieldCtrl.valid && !this.saving;
  }


  private originalValue: any;
  private visitorIdRegex = /^\s*v-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\s*$/i;


  public get msg(): any { return this.me.session.texts.components['common']; }


  constructor(private me: MeService) {
  }

  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);
    if (this.type == 'email') validators.push(Validators.email);
    if (this.type == 'visitorId') validators.push(Validators.pattern(this.visitorIdRegex));

    // create field control
    this.fieldCtrl = new FormControl(this.currentValue, {
      validators: validators,
      updateOn: 'change'
    });

  }

  edit() {
    this.originalValue = this.currentValue;
    this.editing = true;
  }

  save() {
    this.currentValue = this.fieldCtrl.value;
    this.saving = true;
    this.editing = false;
    this.onSave.emit(this);
  }

  cancel() {
    this.currentValue = this.originalValue;
    this.fieldCtrl.setValue(this.originalValue);
    this.editing = false;
  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.currentValue);

  }

  get errMsg(): string {
    return this.fieldCtrl.hasError('required') ?
      this.errRequired : this.errValid;
  }

}
