import { Component, Input } from '@angular/core';
import { IProduct } from '../../models/entity.interface';


@Component({
    selector: 'ui-entity-image',
    templateUrl: './ui-entity-image.component.html',
    styleUrls: ['./ui-entity-image.component.css'],
    standalone: true,
    imports: [],
})
export class UiEntityImageComponent {

  @Input('entity')
  public entity: IProduct = null;

  public get imageSrc(): string {
    return this.entity.image?.imageSrc;
  }

  public get imageSrcSet(): string {
    return this.entity.image.imageSrcSet;
  }


  constructor() {

  }

}
