import { Component, Input } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import * as _ from "lodash";
import { BkgImageDirective } from '../../directives/bkgimage.directive';
import { CartItem, CartItemList, ProductPrice } from '../../models/cart.class';
import { LineBreaksPipe } from '../../pipes/line-breaks.pipe';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { SelectService } from '../../services/select.service';
import { UiNoteRatingComponent } from '../ui-note-rating/ui-note-rating.component';
import { UiPriceProductComponent } from '../ui-price-product/ui-price-product.component';
import { UiStateComponent } from '../ui-state/ui-state.component';

@Component({
    selector: 'ui-cart-item',
    templateUrl: './ui-cart-item.component.html',
    styleUrls: ['./ui-cart-item.component.css'],
    standalone: true,
    imports: [MatButton, MatIconButton, MatIcon, BkgImageDirective, UiStateComponent, UiPriceProductComponent, UiNoteRatingComponent, MatDivider, LineBreaksPipe]
})
export class UiCartItemComponent {

  @Input('cart') public cart: CartItemList;
  @Input('item') public item: CartItem;

  public get text(): any { return this.me.texts.components['ui-cart-item']; }

  constructor(private me: MeService, private ssv: SelectService, private nav: NavService) { }

  public get singleColumn(): boolean { return this.me.windowSizeCompact; }
  public get condensed(): boolean { return this.singleColumn || (this.items && this.items.length > 3); }
  public viewButtons: boolean = false;

  public get items(): Array<CartItem> { return this.cart?.items; }
  public get itemIndex(): number { return _.indexOf(this.items, this.item); }

  public get member(): boolean { return this.me.isMember; }
  public get canMoveUP(): boolean { return this.itemIndex > 0; }
  public get canDelete(): boolean { return true; }
  public get canMoveDOWN(): boolean { return this.itemIndex < this.items.length - 1; }
  public get soldOut(): boolean { return this.item.state && this.item.state.state == 'soldOut'; }
  public get readyForSale(): boolean { return this.item.state && this.item.state.state == 'readyForSale'; }
  public get isNew(): boolean { return this.item.state && this.item.state.new; }

  public get skuVisible(): boolean {
    if (this.item.sku)
      return this.member;
    else
      return false;
  }
  public get stateVisible(): boolean {
    if (this.member)
      return true;
    else
      return this.isNew || this.soldOut;
  }

  public get ratingVisible(): boolean {
    return this.item.note.note?.length > 0 || this.item.note.rating > 0;
  }

  public priceHintVisible(price: ProductPrice): boolean {
    return price.actual.type.id != this.cart.itemsPrice.type.id;
  }

  public deleteItem() {

    _.pullAt(this.items, [this.itemIndex]);
    this.ssv.remove(this.item.id);
    this.cart.updateItemsPrice();

  }

  public moveItemUP() {

    var index = this.itemIndex;
    var items = _.pullAt(this.items, [index]);
    this.items.splice(index - 1, 0, items[0]);
    this.ssv.moveItemUP(this.item.id);

  }

  public moveItemDOWN() {

    var index = this.itemIndex;
    var items = _.pullAt(this.items, [index]);
    this.items.splice(index + 1, 0, items[0]);
    this.ssv.moveItemDOWN(this.item.id);

  }

  // count
  private updateCount() {
    this.ssv.select(this.item.id, this.item.count);
  }

  public incrementCount() {

    this.item.incrementCount();
    this.updateCount();
    this.cart.updateItemsPrice();

  }

  public decrementCount() {

    if (this.item.count > 1) {

      this.item.decrementCount();
      this.updateCount();
      this.cart.updateItemsPrice();

    }
    else
      this.deleteItem();
  }

  public get canDecrement(): boolean {
    return this.item.count > 1;
  }

  public loadDetail() {

    if (this.me.isVisitor)
      return;

    // remove domain id
    if (this.item.id.startsWith(this.me.session.domainId))
      this.nav.to(this.item.id.substring(this.me.session.domainId.length + 1));

  }

}
