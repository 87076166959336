import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
    selector: "[autoFocus]",
    standalone: true
})
export class AutoFocusDirective implements OnInit {

  @Input('autoFocusDelay')
  public autoFocusDelay: number = 0;

  private inputElement: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.inputElement = this.elementRef.nativeElement;
  }

  ngOnInit(): void {

    if (this.autoFocusDelay) {

      setTimeout(() => {

        this.inputElement.focus();

      }, this.autoFocusDelay);

    }
    else
      this.inputElement.focus();

  }

}
