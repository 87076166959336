import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'ui-navbar-btn',
    templateUrl: './ui-navbar-btn.component.html',
    styleUrls: ['./ui-navbar-btn.component.css'],
    standalone: true,
    imports: [MatIconButton, MatIcon, MatBadge, NgClass]
})
export class UiNavbarBtnComponent {

  @Input('icon') icon: string;
  @Input('label') label: string;
  @Input('path') path: string;
  @Input('active') active: boolean;
  @Input('activePaths') activePaths: Array<string>;
  @Input('inactivePaths') inactivePaths: Array<string>;
  @Input('badge') badge: any;

  @Output('click') click = new EventEmitter<any>();

  constructor(private nav: NavService)
  {
  }

  get isActive(): boolean {

    if (this.active == true)
      return true;
    if (this.active == false)
      return false;

    if (this.activePaths) {
      for (var i = 0; i < this.activePaths.length; i++)
        if (this.nav.isA(this.activePaths[i]))
          return true;
    }

    if (this.inactivePaths) {

      for (var i = 0; i < this.inactivePaths.length; i++)
        if (this.nav.isA(this.inactivePaths[i]))
          return false;

      return true;
    }

    return this.nav.isA(this.path);
  }

  get iconClass(): string {
    return this.isActive ? 'active' : 'material-icons-outlined inactive';
  }

  get labelClass(): string {
    return this.isActive ? 'label-active' : '';
  }

  get fontSet(): string {
    return this.isActive ? 'material-icons' : 'material-icons-outlined';
  }

  onClick() {

    if (this.click.observed)
      this.click.emit();
    else
      this.nav.to(this.path);

  }

}
