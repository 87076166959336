@if (countOnly) {

  <span [matBadge]="count"
        [matBadgeHidden]="!count"
        matBadgePosition="below before"
        [matBadgeOverlap]="false">&nbsp;</span>

}
@else {

  <button mat-icon-button
          [matBadge]="count"
          [matBadgeHidden]="!count || toggleOnly"
          [matBadgeOverlap]="true"
          matBadgePosition="below before"
          (click)="onCart($event)">
    <mat-icon>{{btnIcon}}</mat-icon>
  </button>

  @if (count && !toggleOnly) {

    <button mat-icon-button (click)="decrementCount($event)">
      <mat-icon>remove</mat-icon>
    </button>

    <ui-cart-btn [badgeHidden]="true"></ui-cart-btn>

  }

}


