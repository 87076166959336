import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-quantity',
  templateUrl: './form-field-quantity.component.html',
  styleUrls: ['./form-field-quantity.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatSuffix, MatHint, MatError],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldQuantityComponent extends FormFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  public get quantityValue() {

    // value from quantity
    var value = this.value;
    if (this.schema.valueIsQuantity && _.isObjectLike(value))
      return value['gsdm/quantity/value'];
    else
      return value;
  }
  public set quantityValue(newValue) {
    // value to quantity
    var oldValue = this.value;
    if (this.schema.valueIsQuantity && _.isObject(oldValue)) {
      if (_.isString(newValue) && newValue.length == 0)
        (oldValue as any)['gsdm/quantity/value'] = null;
      else
        (oldValue as any)['gsdm/quantity/value'] = newValue;
    }
    else
      this.value = newValue;
  }


  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);
    if (this.pattern) validators.push(Validators.pattern(this.pattern));
    if (this.number) validators.push(this.numberValidator());
    if (this.integer) validators.push(this.intValidator());
    //Validators.maxLength(255),

    // create field control
    this.fieldCtrl = new FormControl(this.quantityValue, {
      validators: validators,
      updateOn: 'change'
    });

    // set disabled
    if (this.readonly)
      this.fieldCtrl.disable();

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(500))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do nothing if undefined
        if (_.isUndefined(value))
          return;

        // insert default value, if original value undefined
        if (_.isUndefined(this.value))
          this.value = this.defaultValue;

        // if null or empty string
        if (_.isNull(value)) {
          this.quantityValue = null;
          return;
        }

        // if number
        if (_.isNumber(value)) {
          this.quantityValue = value;
          return;
        }

        // if empty string
        if (value === '') {
          this.quantityValue = null;
          return;
        }

        // replace , by . as decimal digit sepparator
        if (!_.isString(value))
          return;
        else
          value = _.replace(value, ',', '.')

        // try to convert
        var parsedValue;
        if (this.integer)
          parsedValue = Number.parseInt(value);
        else
        if (this.number)
          parsedValue = Number.parseFloat(value);
        else {
          this.quantityValue = value;
          return;
        }

        // test parsed value
        if (_.isFinite(parsedValue))
          this.quantityValue = parsedValue;
        else
          this.quantityValue = null;

      });

    // register control
    this.register(this.fieldCtrl);

  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.quantityValue);

  }

}
