import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProductProperties } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { AutoFocusDirective } from '../../directives/autofocus.directive';
import { FormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import _ from 'lodash';

@Component({
    selector: 'ui-entity-note',
    templateUrl: './ui-entity-note.component.html',
    styleUrls: ['./ui-entity-note.component.css'],
    standalone: true,
    imports: [MatToolbar, MatIconButton, MatIcon, MatFormField, MatLabel, MatInput, CdkTextareaAutosize, FormsModule, AutoFocusDirective]
})
export class UiEntityNoteComponent implements OnInit {

  @Input('entity')
  public entity: IProductProperties;

  @Output('close')
  public close = new EventEmitter<string>();

  public get text(): any { return this.me.texts.components['ui-entity-note']; }

  private _originalNote: string = null;
  public textNote: string = null;
  public get changed(): boolean { return this.textNote !== this._originalNote; }

  constructor(public me: MeService) { }


  ngOnInit(): void {

    this.textNote = this.entity?.note?.note;
    this._originalNote = this.textNote;

  }

  public onClose(save: boolean) {

    if (save) {

      if (this.changed) {

        var note = _.cloneDeep(this.entity.note);
        note.note = this.textNote;

        // update note
        this.me.call<any>('browse.entity.note.set', {

          entityId: this.entity.id,
          note: note

        }).subscribe((note) => {

          this.entity.note.note = note?.note;
          this.entity.note.time = note?.time;
          this.close.emit(this.textNote);

        });
      }
      else
        this.close.emit(this.textNote);

    }
    else
      this.close.emit(this._originalNote);

  }

}
