import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { MeService } from "../services/me.service";


@Injectable({ providedIn: 'root' })
export class DomainGuard implements CanActivate {

  constructor(private me: MeService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Promise<boolean> {

    // get domainName
    const domainId = route.params["domainId"];

    // initialize domain
    return this.me.initSession(domainId);

  }
}
