<!-- Groups -->
<div class="groups" *ngFor="let group of hostGroup?.groups">

  <div class="label-large title">{{group.name}}</div>

  <!-- Properties -->
  <ui-property-list class="label-small" [group]="group"></ui-property-list>

</div>

<!-- Properties -->
<ui-property-list class="label-small" [group]="hostGroup"></ui-property-list>
