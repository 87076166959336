import { Component, Input, OnInit } from '@angular/core';
import { IEntityStateInfo } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'ui-state',
    templateUrl: './ui-state.component.html',
    styleUrls: ['./ui-state.component.css'],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class UiStateComponent {

  @Input('state') public state: IEntityStateInfo;

  public get msg(): any { return this.me.texts.components['common']; }

  public get class(): string { return this.state?.state; }
  public get text(): string {

    if (this.state)
      return this.state.new && this.state.state == 'sale' ?
             this.msg.state.new : this.state.name;
    else
      return null;

  }

  public get visible(): boolean {

    if (this.state)
      return this.me.viewState(this.state);
    else
      return false;

  }

  constructor(private me: MeService) { }

}
