import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MeService } from '../../services/me.service';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'sheet-user-signin',
    templateUrl: './sheet-user-signin.component.html',
    styleUrls: ['./sheet-user-signin.component.css'],
    standalone: true,
    imports: [MatIcon, MatCheckbox, MatButton]
})
export class SheetUserSigninComponent {

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.session.texts.components['sheet-user-signin']; }

  constructor(public me: MeService, private nav: NavService,
    private _bottomSheetRef: MatBottomSheetRef<SheetUserSigninComponent>) { }

  noshow(hide: boolean) {

    this.me.setSignInHint(!hide);

  }

  signIn() {
    this._bottomSheetRef.dismiss();
    this.nav.sideNavToggle('signin');
  }

  close(event: MouseEvent = null): void {

    this._bottomSheetRef.dismiss();

    if (event)
      event.preventDefault();

  }

}
