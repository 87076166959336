<mat-toolbar>

  <span class="title-medium">{{entity.name}}</span>

  <span class="spacer"></span>

  @if (changed) {
  <button mat-icon-button (click)="onClose(false)">
    <mat-icon>close</mat-icon>
  </button>
  }

  <button mat-icon-button (click)="onClose(true)">
    <mat-icon>task_alt</mat-icon>
  </button>

</mat-toolbar>

<mat-form-field>
  <mat-label>{{text.title}}</mat-label>
  <textarea matInput [(ngModel)]="textNote"
            cdkTextareaAutosize
            cdkAutosizeMinRows="4"
            cdkAutosizeMaxRows="15"
            autoFocus [autoFocusDelay]="400" ></textarea>
</mat-form-field>

