import { Component, ElementRef, Input, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { IUiPageSection } from '../../models/ui.interface';
import { MeService } from '../../services/me.service';
import { UiElementComponent } from '../ui-element/ui-element';
import { UiEntityTileComponent } from '../ui-entity-tile/ui-entity-tile.component';
import { ViewPageComponent } from '../view-page/view-page.component';


@Component({
    selector: 'ui-page-section',
    templateUrl: './ui-page-section.component.html',
    styleUrls: ['./ui-page-section.component.css'],
    standalone: true,
    imports: [MatButton, MatIconButton, MatIcon, MatDivider, UiEntityTileComponent, MatRipple]
})
export class UiPageSectionComponent
       extends UiElementComponent
       implements OnInit {

  @Input('pageComponent') public pageComponent: ViewPageComponent;
  @Input('section') public section: IUiPageSection;

  public svgIcon(section: IUiPageSection): string {

    if (_.startsWith(section.data?.icon, 'svg-'))
      return section.data.icon.substring(4);
    else
      return null;
  }

  public get html(): any {    
    if (this.section.html)
      return this.sanitizer.sanitize(SecurityContext.HTML, this.section.html); //this.sanitizer.bypassSecurityTrustHtml(this.section.html);
    else
      return null;
  }

  constructor(me: MeService, renderer: Renderer2,
    public override host: ElementRef,
    private sanitizer: DomSanitizer)
  {
    super(me, renderer, host);
  }

  ngOnInit(): void {

    // update CSS and style
    super.update(this.section);

  }

  ngAfterViewChecked() {

    // set image after tile is stabilized - problems with virtual scroll
    // measuring offsetWidth and offsetHeight breaks scrolling to top
    super.afterViewChecked(this.section);

  }

  public onSectionClick(section: IUiPageSection) {

    this.pageComponent.onSectionClick(section);

  }
}
