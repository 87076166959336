import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
export type MessageCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root'
})
export class RelayService {

  private _subject: Subject<any>;
  private _item: Observable<any>;

  constructor() {
    this._subject = new Subject<any>();
    this._item = this._subject.asObservable();
  }

  broadcast(type: string, payload: any) {
    this._subject.next({ type, payload });
  }

  subscribe(type: string, callback: MessageCallback): Subscription {
    return this._item.pipe(
      filter(message => message.type === type),
      map(message => message.payload)
    ).subscribe(callback);
  }

  subscribeMany(types: Array<string>, callback: MessageCallback): Subscription {
    return this._item.pipe(
      filter(message => _.includes(types, message.type)),
      map(message => message.payload)
    ).subscribe(callback);
  }

  /////////////////////////////////////////
  // specialized broadcasts

  // entity changed
  private entityChangedType = 'entity-changed';

  public entityChanged(id: string) {
    this.broadcast(this.entityChangedType, {
      id: id
    });
  }

  onEntityChanged(callback: MessageCallback): Subscription {
    return this.subscribe(this.entityChangedType, callback);
  }

}
