import { Component } from '@angular/core';
import { MeService } from '../../services/me.service';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';
import { UiUserOrdersComponent } from '../ui-user-orders/ui-user-orders.component';

@Component({
  selector: 'view-user-orders',
  templateUrl: './view-user-orders.component.html',
  styleUrls: ['./view-user-orders.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, UiUserOrdersComponent]
})
export class ViewUserOrdersComponent {

  constructor(public me: MeService) {

  }

}
