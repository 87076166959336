<ui-toolbar-top [title]="headerTitle"
                [backVisible]="true"></ui-toolbar-top>

@if (events$ | async; as events)
{

<div #content class="content" cdkScrollable>


    @for (year of events.years; track year) {

      <ui-section-header #years [label]="year.title" [divider]="true" [expanded]="$index < 1"></ui-section-header>

      @if (isExpanded($index)) {

        <mat-list>

        @for(event of year.events; track event) {

        <mat-list-item matRipple (click)="nav.to(event.navigate)">
          <span matListItemTitle>{{event.title}}</span>
          <span matListItemLine >{{event.subtitle}}</span>
        </mat-list-item>

        }

        </mat-list>

      }

    }


</div>

}


<ui-btn-fab icon="vertical_align_top" [class.visible]="scrolled" (onClick)="scrollToTop()"></ui-btn-fab>
