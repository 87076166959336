import { Component, ViewChild } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { IProduct } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { UiBtnFabComponent } from '../ui-btn-fab/ui-btn-fab.component';
import { IEntityListView, UiEntityListViewComponent } from '../ui-entity-list-view/ui-entity-list-view.component';
import { UiEntityListComponent, UiEntityListItemDirective } from '../ui-entity-list/ui-entity-list.component';
import { UiEntityTileComponent } from '../ui-entity-tile/ui-entity-tile.component';
import { IImageRequest } from '../../models/media.interface';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'view-entity-list',
  templateUrl: './view-entity-list.component.html',
  styleUrls: ['./view-entity-list.component.css'],
  standalone: true,
  imports: [UiEntityListComponent, UiEntityListItemDirective, UiEntityListViewComponent, UiEntityTileComponent, UiBtnFabComponent, MatRipple, NgStyle]
})
export class ViewEntityListComponent {

  public hostId: string;
  public queryId: string;

  @ViewChild('list', { static: true })
  list: UiEntityListComponent;

  constructor(public me: MeService,
              private route: ActivatedRoute) {

    this.route.url.subscribe((url) => {

      this.hostId = `${this.me.session.domainId}/${url.join('/')}`;
      this.queryId = this.route.snapshot.queryParams['qid'];

    });

  }

  // detail
  public detail: IEntityListView = null;

  public showDetail(entity: IProduct) {
    this.detail = this.list.getDetail(entity);
  }

  // image request
  public get imageRequest(): IImageRequest {
    var size = this.me.windowCompact ? 160 : 240;
    return {
      width: size,
      height: size,
      roles: null
    }
  }

}
