import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import * as _ from "lodash";
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, finalize, map, switchMap, timer } from 'rxjs';
import { DlgConfirmComponent, IDlgConfirmSettings } from '../components/dlg-confirm/dlg-confirm.component';
import { SheetApplicationUpdateComponent } from '../components/sheet-application-update/sheet-application-update.component';
import { SheetCommonComponent } from '../components/sheet-common/sheet-common.component';
import { SheetUserLegalAgeComponent } from '../components/sheet-user-legal-age/sheet-user-legal-age.component';
import { ViewPageComponent } from '../components/view-page/view-page.component';
import { IEntityStateInfo, IProduct } from '../models/entity.interface';
import { IApplication, IExecutionRequest } from '../models/execrequest.interface';
import { ISession } from '../models/session.interface';
import { AuthService } from './auth.service';
import { SelectService } from './select.service';


@Injectable({
  providedIn: 'root'
})
export class MeService {

  // current application
  public application: IApplication;
  public newVersionAvailable: boolean = false;

  // implicit domain id for single apps
  public get implicitDomainId(): string { return (window as any)['clientCfg']?.domainId; }
  public get domainTitle(): string { return this.session?.title; }

  // current session
  public session: ISession;
  public get texts(): any { return this.session?.texts; }
  public get msg(): any { return this.texts?.components['common']; }
  public get admin(): any { return this.msg?.admin; }

  // subscription for log-in
  //private readonly _destroying$ = new Subject<void>();


  constructor(private _router: Router,
    private _http: HttpClient,
    private _auth: AuthService,
    private _select: SelectService,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private _updates: SwUpdate,
    private breakpointObserver: BreakpointObserver,
    private platform: Platform,
    private title: Title) {


    // single column form layout for windowSizeCompact portrait
    this.breakpointObserver.observe([

      this.windowWidths.compact,
      this.windowWidths.medium,
      this.windowWidths.expanded,

      this.windowHeights.compact,
      this.windowHeights.medium,
      this.windowHeights.expanded


    ]).subscribe(result => {

      // window size 
      this.windowSizeCompact = result.breakpoints[this.windowWidths.compact];
      this.windowSizeMedium = result.breakpoints[this.windowWidths.medium];
      this.windowSizeExpanded = result.breakpoints[this.windowWidths.expanded];

      // window height
      this.windowHeightCompact = result.breakpoints[this.windowHeights.compact];
      this.windowHeightMedium = result.breakpoints[this.windowHeights.medium];
      this.windowHeightExpanded = result.breakpoints[this.windowHeights.expanded];

    });

  }

  // app lifecycle
  async OnAppInit(): Promise<void>  {

    // make sure we have initialized auth
    await this._auth.OnAppInit(() => {

      // make sure we have valid session
      if (!this.session)
        this.initSession(null);

    });

  }

  public OnAppDestroy(): void {

    this._auth.OnAppDestroy();

  }


  //////////////////////////////////////////////////////
  // Layout - material
  // https://m3.material.io/foundations/layout/applying-layout/window-size-classes

  public windowWidths = {
    compact: '(max-width: 599.98px)',
    medium: '(min-width: 600px) and (max-width: 839.98px)',
    expanded: '(min-width: 840px)'
  };

  public windowSizeCompact: boolean = false;
  public windowSizeMedium: boolean = false;
  public windowSizeExpanded: boolean = false;

  public windowHeights = {
    compact: '(max-height: 479.98px)',
    medium: '(min-height: 480px) and (max-height: 899.98px)',
    expanded: '(min-height: 900px)'
  };

  public windowHeightCompact: boolean = false;
  public windowHeightMedium: boolean = false;
  public windowHeightExpanded: boolean = false;

  public get windowCompact(): boolean {
    return this.windowSizeCompact || this.windowHeightCompact;
  }

  //////////////////////////////////////////////////////
  // Platform
  // https://whatpwacando.today/

  public get isIOS(): boolean { return this.platform.IOS; }
  public get isAndroid(): boolean { return this.platform.ANDROID; }
  public get isMobile(): boolean { return this.platform.IOS || this.platform.ANDROID; }

  public get isMacOS(): boolean { return /Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(navigator.userAgent); }
  public get isWindows(): boolean { return /Win32|Win64|Windows|Windows NT|WinCE/gi.test(navigator.userAgent); }
  public get isChromeOS(): boolean { return /CrOS/gi.test(navigator.userAgent); }

  public getBrowser() {
    const { userAgent } = navigator;

    return userAgent.match(/edg/i) ? 'edge' :
      userAgent.match(/chrome|chromium|crios/i) ? 'chrome' :
        userAgent.match(/firefox|fxios/i) ? 'firefox' :
          userAgent.match(/safari/i) ? 'safari' :
            userAgent.match(/opr\//i) ? 'opera' :
              userAgent.match(/android/i) ? 'android' :
                userAgent.match(/iphone/i) ? 'iphone' : 'unknown';
  }

  public getPlatform = () => {
    return this.isIOS ? 'ios' :
           this.isAndroid ? 'android' :
           this.isMacOS ? 'macos' :
           this.isChromeOS ? 'chromeos' :
           this.isWindows ? 'windows' : 'unknown';
  }
  public get isTouchScreen(): boolean {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 0 ||
      window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches;
  };
  public get isOffline(): boolean { return 'onLine' in navigator && !navigator.onLine; }


  public get isChrome(): boolean { return this.getBrowser() === 'chrome'; }
  public get isFirefox(): boolean { return this.getBrowser() === 'firefox'; }
  public get isSafari(): boolean { return this.getBrowser() === 'safari'; }
  public get isOpera(): boolean { return this.getBrowser() === 'opera'; }
  public get isEdge(): boolean { return this.getBrowser() === 'edge'; }
  public get isIOSSafari(): boolean { return this.getBrowser() === 'safari' && this.isIOS; }
  public get isIOSChrome(): boolean { return this.getBrowser() === 'chrome' && this.isIOS; }
  public get isAndroidChrome(): boolean { return this.getBrowser() === 'chrome' && this.isAndroid; }
  public get isMacOSChrome(): boolean { return this.getBrowser() === 'chrome' && this.isMacOS; }
  public get isWindowsChrome(): boolean { return this.getBrowser() === 'chrome' && this.isWindows; }
  public get isIOSFirefox(): boolean { return this.getBrowser() === 'firefox' && this.isIOS; }
  public get isAndroidFirefox(): boolean { return this.getBrowser() === 'firefox' && this.isAndroid; }
  public get isIOSEdge(): boolean { return this.getBrowser() === 'edge' && this.isIOS; }
  public get isAndroidEdge(): boolean { return this.getBrowser() === 'edge' && this.isAndroid; }
  public get isMacOSEdge(): boolean { return this.getBrowser() === 'edge' && this.isMacOS; }
  public get isWindowsEdge(): boolean { return this.getBrowser() === 'edge' && this.isWindows; }
  public get isIOSOpera(): boolean { return this.getBrowser() === 'opera' && this.isIOS; }
  public get isAndroidOpera(): boolean { return this.getBrowser() === 'opera' && this.isAndroid; }

  public get isInstalled(): boolean { return window.matchMedia('(display-mode: standalone)').matches; }

  public get canInstall(): boolean {

    return this.isIOS || this.isAndroid ||
      (this.isMacOS && !this.isFirefox) ||
      (this.isWindows && !this.isFirefox);

  }


  //////////////////////////////////////////////////////
  // Sharing

  public get canShare(): boolean {

    const shareData = {
      title: this.domainTitle,
      url: window.location.href,
    };

    return navigator.canShare(shareData);

  }

  public share(url: string = null) {

    const shareData = {
      title: this.domainTitle,
      url: url ?? window.location.href,
    };

    navigator.share(shareData);

  }


  //////////////////////////////////////////////////////
  // Set application name and version

  public setApplication(name: string, version: string) {

    this.application = {
      name: name,
      version: version,
      installed: this.isInstalled,
      platform: this.getPlatform(),
      browser: this.getBrowser(),
    };

  }

  //////////////////////////////////////////////////////
  // Sign in/out 

  public signInDomain(provider: string = null) {

    this._auth.loginRedirect(provider);

  }

  public signOut() {

    this._auth.logout();

  }


  //////////////////////////////////////////////////////
  // User

  public get userInitials(): string {

    var name = this._auth.account?.name;
    if (!name)
      return '@';

    var names = name.split(' ');
    var initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  public get userAtDomain(): string {
    return this._auth.isSignedIn ?
      `${this.userInitials} @ ${this.session?.title}` :
      this.session?.title;
  }


  // Roles
  public get userRoles(): string[] { return this.session?.userRoles || []; }

  public isInRole(role: string): boolean {
    return _.includes(this.userRoles, role);
  }
  public get isVisitor(): boolean {
    return _.includes(this.userRoles, 'gss/domain/role/visitor');
  }
  public get isRegistered(): boolean {
    return _.includes(this.userRoles, 'gss/domain/role/registered');
  }
  public get isPartner(): boolean {
    return _.includes(this.userRoles, 'gss/domain/role/partner');
  }
  public get isMember(): boolean {
    return !this.isVisitor && !this.isPartner;
  }
  public get isAnonymousVisitor(): boolean {
    return this.isVisitor && !this.isRegistered;
  }
  public get isRegisteredVisitor(): boolean {
    return this.isVisitor && this.isRegistered;
  }
  public get isAdmin(): boolean {
    return _.includes(this.userRoles, 'gss/domain/role/admin');
  }
  public get isClientAdmin(): boolean {
    return _.includes(this.userRoles, 'gss/domain/role/clientadmin');
  }
  public get isOwner(): boolean {
    return _.includes(this.userRoles, 'gss/domain/role/owner');
  }

  // Permissions
  public get userPermissions(): string[] { return this.session?.userPermissions || []; }

  public get canEntityView(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/document/read'); }
  public get canEntityEdit(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/document/update'); }
  public get canEntityExport(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/document/export'); }

  public get canMediaView(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/media/view'); }
  public get canMediaUpload(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/media/upload'); }
  public get canMediaDownload(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/media/download'); }
  public get canMediaEdit(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/media/edit'); }
  public get canMediaDelete(): boolean { return _.includes(this.userPermissions, 'gss/domain/permission/media/delete'); }

  // Capabilities
  public get hasAssistent(): boolean { return this.session?.capabilities.hasAssistent ? true : false; }

  //////////////////////////////////////////////////////
  // Init session

  public async initSession(
    domainId: string,
    reloadDomain: boolean = false,
    reloadCss: boolean = false): Promise<boolean> {

    // if domain not specified, use implicit
    if (!domainId)
      domainId = this.implicitDomainId;

    // get session
    this.session = await this.call<ISession>("browse.session.init",
      {
        reloadDomain: reloadDomain,
        reloadCss: reloadCss,
        domainId: domainId
      }).toPromise();

    // call afterInitSession initialization
    this.afterInitSession();

    return true;
  }

  private afterInitSession() {

    // store assigned device id
    this.setDeviceId(this.session.deviceId);

    // store last used locale
    this.setLastUsedLocale(this.session.locale);

    // register domain css
    this.registerCSS(this.session.css);

    // set initial title
    if (this.domainTitle)
      this.title.setTitle(this.domainTitle);


    // check updates

    // load domain selection
    this._select.load(this.session.domainId);

    // notifications
    if (this.session.domainId !== 'ad4wine.com') {

      // open drinking age notification
      if (!this.getLegalAgeConsent()) {

        this._bottomSheet.open(SheetUserLegalAgeComponent, {
          disableClose: true
        });

      }
      else {

        this.setNotifications();

      }

    }

    // subscribe to available updates
    if (this._updates.isEnabled) {

      window.console.log(`CHECKING FOR UPDATE`);
      this._updates.checkForUpdate().then((available) => {

        // set we have new version available
        this.newVersionAvailable = available;
        if (available) {

          // open bottom sheet with updates available message
          this._bottomSheet.open(SheetApplicationUpdateComponent);

        }

      });
    }
  }

  public setNotifications() {

    // open after ... seconds within the domain
    //setTimeout((domainId) => {

    //  // check we are still on the same domain
    //  if (this.session.domainId == domainId && !this._auth.isSignedIn && !this.isDialogOpen('signin'))
    //    this._bottomSheet.open(SheetUserSigninComponent);

    //}, 60000, this.session.domainId);

  }

  //////////////////////////////////////////////////////
  // Page

  public currentPage: ViewPageComponent;

  //////////////////////////////////////////////////////
  // Localization

  public get locale(): string { return this.session.locale; }
  public set locale(value: string) {
    if (this.session.locale != value) {
      // reload texts
    }
  }

  public currentCulture(cultures: Array<any> = null): any {

    // handle no session
    if (!this.session)
      return null;

    // use session cultures, if not provided
    if (!cultures)
      cultures = this.session.cultures;

    // try to find culture
    var culture = _.find(cultures, { 'id': this.session.locale });
    if (culture)
      return culture;

    // try to find root culture
    var i = this.session.locale.indexOf('-');
    if (i > 0) {

      culture = _.find(cultures, { 'id': this.session.locale.substring(0, i) });
      if (culture)
        return culture;

    }

    // not found
    return null;
  }

  //////////////////////////////////////////////////////
  // Text

  public normalizeText(value: string) {
    value = _.toLower(value);
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private lineBreaks = new RegExp("(\r\n|\r|\n)", "g");
  public lineBreaksToHtml(text: string) {
    if (text)
      return _.replace(text, this.lineBreaks, "<br>");
    else
      return text;
  }

  public formatPriceValue(value: number): string {

    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(value);

  }

  //////////////////////////////////////////////////////
  // Navigation

  public navigate(path: string) {

    if (!path)
      return;

    if (path.startsWith('http'))
      window.open(path, '_blank');
    else
    if (path.startsWith('tel'))
      window.open(path);
    else
    if (path.startsWith('mailto'))
      window.open(path);
    else
    if (this.session) {
      if (this.session.domainId == this.implicitDomainId)
        this._router.navigateByUrl(`/${path}`);
      else
        this._router.navigateByUrl(`/${this.session.domainId}/${path}`);
    }

  }

  public async reload() {

    // https://stackoverflow.com/questions/39396075/how-to-reload-the-component-of-same-url-in-angular-2

    await this._router.navigateByUrl('/reload', { skipLocationChange: true });
    await this._router.navigateByUrl(window.location.pathname)

}

  //////////////////////////////////////////////////////
  // Dialogs

  public get topDialog() {

    if (this._dialog.openDialogs.length == 0)
      return null;

    return this._dialog.openDialogs[this._dialog.openDialogs.length - 1];
  }

  public isDialogOpen(id: string): boolean {

    return _.isObject(this._dialog.getDialogById(id));

  }

  public isDialogVisible(id: string): boolean {

    var topDlg = this.topDialog;
    if (!topDlg)
      return false;

    return topDlg.id == id ? true : false;

  }

  public getDialog(id: string): MatDialogRef<any, any> {

    return this._dialog.getDialogById(id);

  }

  public openFullscreenDialog<T>(type: ComponentType<T>, id: string, data: any = null): MatDialogRef<T, any> {

    // check already open
    var openDlg = this._dialog.getDialogById(id);
    if (openDlg) {

      // close all dialogs above
      var dialogs = this._dialog.openDialogs;
      var idx = dialogs.indexOf(openDlg);
      for (var i = idx + 1; i < dialogs.length; i++)
        dialogs[i].close();

      // set view
      //if (att) {

      //  if (openDlg.componentInstance.view)
      //    openDlg.componentInstance.view = att;
      //  else
      //  if (openDlg.componentInstance.att)
      //    openDlg.componentInstance.att = att;

      //}

      return openDlg;
    }

    return this._dialog.open(type, {

      id: id,
      data: data,
      width: '100%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      hasBackdrop: false,
      panelClass: 'fullscreen-dialog',
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: false

    });

  }

  public openDialog<T>(type: ComponentType<T>, id: string, data: any = null): MatDialogRef<T, any> {

    return this._dialog.open(type, {

      id: id,
      data: data,
      hasBackdrop: true,
      autoFocus: true,
      restoreFocus: false,
      closeOnNavigation: false

    });

  }

  public openConfirmDialog(id: string, subject: string): MatDialogRef<DlgConfirmComponent, any> {

    var settings: IDlgConfirmSettings = {
      id: id,
      subject: subject,
      type: 'confirm'
    };

    return this.openDialog(DlgConfirmComponent, 'confirm', settings);

  }

  public closeAllDialogs() {

    this._dialog.closeAll();

  }

  //////////////////////////////////////////////////////
  // Bottom sheets

  public get openedBottomSheet() {
    return this._bottomSheet._openedBottomSheetRef;
  }

  public openBottomSheetType<T>(component: ComponentType<T>, data: any = {})
    : MatBottomSheetRef<T, void> {
    return this._bottomSheet.open(component, {
      data: data,
    });
  }

  public openBottomSheet(message: string, type: string, delay: number)
    : MatBottomSheetRef<SheetCommonComponent, void>
  {
    return this._bottomSheet.open(SheetCommonComponent, {
      data: { message: message, type: type, delay: delay },
    });
  }


  //////////////////////////////////////////////////////
  // private implementations
  private registerCSS(css: string) {

    // nothing to register?
    if (!css) return;

    // store css under single id -> no dupplicates
    var domainId = 'domain.css';

    // try to get style for a domain
    var style: Element = document.getElementById(domainId);
    if (!style) {

      style = document.createElement('style');
      style.id = domainId;
      document.head.appendChild(style);

    }

    // insert css source
    style.innerHTML = css;

  }

  //////////////////////////////////////////////////////
  // Media

  // media download
  public mediaDownload(mediaUri: string) {

    if (mediaUri) {

      // if https://
      if (_.startsWith(mediaUri, 'http')) {
        const pathname = new URL(mediaUri).pathname;
        mediaUri = `api${pathname}`;
      }

      var iframe = document.getElementById('media_iframe') as HTMLIFrameElement;
      if (iframe)
        iframe.src = `${mediaUri}?download=true`;

    }

  }

  // media open
  public mediaOpen(mediaUri: string) {

    if (mediaUri)
      return window.open(mediaUri, '_blank');
    else
      return null;

  }

   public mediaDownloadAuthenticated(url: string, fileName: string) {

     // try to acquire token
     this._auth.acquireToken().then((token: string) => {

       this.loadIframeWithToken(url, token, fileName);

     });

  }

  private async loadIframeWithToken(url: string, token: string, fileName: string) {

     try {

       // Perform a fetch request with the Authorization bearer token
       const response = await fetch(url, {
         method: 'GET',
         headers: {
           'Authorization': `Bearer ${token}`
         }
       });

       if (!response.ok) {
         throw new Error('Network response was not ok');
       }

       // Get the response data
       const blob = await response.blob();

       this.downloadWithFilename(blob, fileName);


     } catch (error) {
       console.error('Failed to load iframe:', error);
     }

  }

  private downloadWithFilename(blob: Blob, filename: string) {

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);  // Free up the Blob URL

  }

  //////////////////////////////////////////////////////
  // http services
  readonly api: string = 'api/browse';
  readonly calls: Set<IExecutionRequest> = new Set<IExecutionRequest>();
  private workingSubject = new BehaviorSubject<boolean>(false);
  public isWorking$ = this.workingSubject.pipe(
      debounceTime(100), // Wait for the input to be true for 100 ms
      distinctUntilChanged(), // Only emit when the value changes
      switchMap(value => value
        ? timer(200).pipe(map(() => true)) // Stay true for at least 200 ms
        : timer(0).pipe(map(() => false)) // Immediately turn to false
      ),
      distinctUntilChanged() // Ensure we don't emit the same value consecutively
  );

  public createExecutionRequest(operation: string, parameters: any): IExecutionRequest {

    // get current domain or init session parameter
    var domainId = this.session?.domainId || parameters?.domainId;
    var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let body: IExecutionRequest = {

      domainId: domainId,
      sessionId: this.session?.sessionId,
      operation: operation,
      parameters: parameters,
      locale: this.session?.locale || this.getLastUsedLocale(domainId),
      timeZone: clientTimeZone,
      device: {

        id: this.getDeviceId(),
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
        orientation: window.orientation,
        isMobile: this.isMobile,
        isTouchScreen: this.isTouchScreen
      },
      application: this.application

    }

    return body;
  }

  public call<T>(operation: string, parameters: any = null): Observable<T> {

    // prepare execution request
    let body = this.createExecutionRequest(operation, parameters);
    let options = {
      headers: null as HttpHeaders,
      withCredentials: true
    };

    // if signed in -> send with bearer
    if (this._auth.isSignedIn) {

      return new Observable<T>(subscriber => {

        // Get the auth header from the service.
        this._auth.acquireToken().then((token: string) => {

          options.headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);

          let called = this.callSwitch<T>('POST', body, options);
          if (called)
            called.subscribe(
              result => subscriber.next(result),
              error => subscriber.error(error),
              () => subscriber.complete());
          else
            subscriber.error();

        },
        (error: any) => {

          subscriber.error(error);

        });

      });

    }
    else {

      return this.callSwitch('POST', body, options);

    };

  }

  private callSwitch<T>(method: string, body: any, options: Object): Observable<T> {

    this.calls.add(body);
    this.workingSubject.next(this.calls.size > 0);

    try {

      let call: Observable<T> = null;

      switch (method) {

        case 'GET':
          call = this._http.get<T>(this.api, options);
          break;

        case 'PUT':
          call = this._http.put<T>(this.api, body, options);
          break;

        case 'POST':
          call = this._http.post<T>(this.api, body, options);
          break;

        default:
          return null;
      }

      return call.pipe(

        finalize(() => {
          this.calls.delete(body);
          this.workingSubject.next(this.calls.size > 0);
        })

      )

    }
    catch {

      this.calls.delete(body);
      this.workingSubject.next(this.calls.size > 0);
      return null;

    }

  }


  //////////////////////////////////////////////////////
  // entity services

  public viewState(state: IEntityStateInfo): boolean {

    if (!this.isVisitor)
      return true;

    if (state?.new)
      return true;

    if (state?.state == "sale")
      return false;

    return true;

  }

  public updateEntity(entity: IProduct) {

    // reload entity
    this.call<IProduct>("browse.entity.info", {
      docId: entity.id
    }).subscribe((info) => {

      entity.lastModified = info.lastModified;
      entity.manufacturer = info.manufacturer;
      entity.name = info.name;
      entity.state = info.state;
      entity.supplier = info.supplier;
      entity.validation = info.validation;
      entity.pricing = info.pricing;
      entity.note = info.note;

    });

  }


  //////////////////////////////////////////////////////
  // syntactic sugar

  public getPathLast(id: string) {
    if (id)
      return _.last(id.split('/'));
    else
      return id;
  }

  // returns app path, dropping first domainId
  public getAppPath(id: string) {
    return _.drop(id.split('/'), 1).join('/');
  }


  //////////////////////////////////////////////////////
  // Locally stored user settings

  // device id

  private deviceIdKey: string = 'device:id';

  public getDeviceId(): string {
    return localStorage.getItem(this.deviceIdKey) || null;
  }

  public setDeviceId(id: string) {
    localStorage.setItem(this.deviceIdKey, id);
  }

  public resetDeviceId() {
    localStorage.removeItem(this.deviceIdKey);
  }

  // device id original

  private deviceIdOriginalKey: string = 'device:id:original';

  public getDeviceIdOriginal(): string {
    return localStorage.getItem(this.deviceIdOriginalKey) || null;
  }

  public setDeviceIdOriginal(id: string) {
    localStorage.setItem(this.deviceIdOriginalKey, id);
  }

  public resetDeviceIdOriginal() {
    localStorage.removeItem(this.deviceIdOriginalKey);
  }


  // locale - domain specific

  private lastUsedLocaleKey(domainId: string = null): string {
    return `locale:${domainId || this.session?.domainId}`;
  }

  private getLastUsedLocale(domainId: string = null): string {
    return localStorage.getItem(this.lastUsedLocaleKey(domainId)) || null;
  }

  private setLastUsedLocale(locale: string) {
    localStorage.setItem(this.lastUsedLocaleKey(), locale);
  }

  // legal age consent
  private get legalAgeKey(): string {
    return 'legalAge:consentAt';
  }

  public getLegalAgeConsent(): string {
    return localStorage.getItem(this.legalAgeKey) || null;
  }

  public setLegalAgeConsent() {
    var now = new Date().toISOString();
    localStorage.setItem(this.legalAgeKey, now);
  }

  public resetLegalAgeConsent() {
    localStorage.removeItem(this.legalAgeKey);
  }

  // signin hint
  private get signinHintKey(): string {
    return 'signInHint';
  }

  public getSignInHint(): boolean {
    var value = localStorage.getItem(this.signinHintKey);
    if (value)
      return value == 'true';
    else
      return true;
  }

  public setSignInHint(value: boolean) {
    localStorage.setItem(this.signinHintKey, value ? 'true' : 'false');
  }

  public resetSignInHint() {
    localStorage.removeItem(this.signinHintKey);
  }


  // session search
  public getSearch(): string {
    var session: any = this.session;
    return session.sessionSearch ?? '';
  }

  public setSearch(value: string) {
    var session: any = this.session;
    session.sessionSearch = value;
  }

  ////////////////////////////////////////////////////////
  // media support

  public createImageUrl(media: any, width: number, height: number): any {

    if (!media)
      return '';

    var imageSize = this.findImage(media.imageSet, width, height);
    if (imageSize)
      return `url('${imageSize.uri}')`;

    if (media.image)
      return `url('${media.image}')`;

    return '';
  }

  public findImage(imageSet: any, width: number, height: number) {

    // empty set
    if (!imageSet || imageSet.length == 0)
      return null;

    // single set
    if (imageSet.length == 1)
      return imageSet[0];

    // compute physical frame dimensions
    var frameWidth = width * window.devicePixelRatio;
    var frameHeight = height * window.devicePixelRatio;

    // Initialize variables to track the optimal image
    let optimalImage = null;
    let optimalImageLength = Number.MAX_VALUE; // Start with a very large number

    // frame aspect ratio
    _.forEach(imageSet, (imageSize) => {

      // Check if the image can cover the element
      if (imageSize.width >= frameWidth || imageSize.height >= frameHeight) {

        // Check if this image is smaller than the currently found optimal image
        if (imageSize.length < optimalImageLength) {
          optimalImage = imageSize;
          optimalImageLength = imageSize.length;
        }
      }

    });

    return optimalImage || imageSet[0];
  }

}
