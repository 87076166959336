import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FormArrayComponent } from '../form-array/form-array.component';
import { FormFieldAutoComponent } from '../form-field-auto/form-field-auto.component';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldCheckboxComponent } from '../form-field-checkbox/form-field-checkbox.component';
import { FormFieldDateComponent } from '../form-field-date/form-field-date.component';
import { FormFieldFlagsComponent } from '../form-field-flags/form-field-flags.component';
import { FormFieldQuantityComponent } from '../form-field-quantity/form-field-quantity.component';
import { FormFieldSelectComponent } from '../form-field-select/form-field-select.component';
import { FormFieldTextComponent } from '../form-field-text/form-field-text.component';
import { FormFieldTextareaComponent } from '../form-field-textarea/form-field-textarea.component';
import { FormObjectComponent } from '../form-object/form-object.component';

@Component({
    selector: 'form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.css'],
    standalone: true,
    imports: [MatIconButton, MatIcon, FormFieldCheckboxComponent, FormsModule, ReactiveFormsModule, FormFieldTextComponent, FormFieldTextareaComponent, FormFieldDateComponent, FormFieldAutoComponent, FormFieldSelectComponent, FormFieldFlagsComponent, FormFieldQuantityComponent, FormArrayComponent, FormObjectComponent],
    inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldComponent extends FormFieldBaseComponent {

  constructor() {

    super();

  }

}
