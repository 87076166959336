import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiBtnMenuComponent } from '../ui-btn-menu/ui-btn-menu.component';
import { UiNavbarBtnComponent } from '../ui-navbar-btn/ui-navbar-btn.component';
import { UiWorkingComponent } from '../ui-working/ui-working.component';

@Component({
    selector: 'ui-navbar-rail',
    templateUrl: './ui-navbar-rail.component.html',
    styleUrls: ['./ui-navbar-rail.component.css'],
    standalone: true,
    imports: [UiNavbarBtnComponent, UiBtnMenuComponent, AsyncPipe, UiWorkingComponent]
})
export class UiNavbarRailComponent {

  constructor(public me: MeService, public ssv: SelectService)
  {
  }

  public get cartCount(): number {
    return this.ssv.totalCount > 0 ? this.ssv.totalCount : null;
  }

}
