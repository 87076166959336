<ui-toolbar-top [title]="eventSet?.title"
                [backVisible]="true"></ui-toolbar-top>

@if (eventSet$ | async; as eventSet)
{

<div #content class="content" cdkScrollable>

  <mat-list>

  @for(event of eventSet.events; track event) {

  <mat-list-item class="event-link" matRipple (click)="nav.to(event.navigate)">
    <span matListItemTitle>{{event.title}}</span>
    <span matListItemLine >{{event.subtitle}}</span>
  </mat-list-item>

  }

  </mat-list>

</div>

}


<ui-btn-fab icon="vertical_align_top" [class.visible]="scrolled" (onClick)="scrollToTop()"></ui-btn-fab>
