import { Directive, ElementRef, Input } from '@angular/core';
import * as _ from "lodash";
import { MeService } from '../services/me.service';

@Directive({
    selector: '[bkgImage]',
    standalone: true
})
export class BkgImageDirective {

  @Input('bkgImage')
  public set view(view: any) {

    this._view = view;

    this._width = null;
    this._height = null;
    this.ngAfterViewChecked();

  };
  public get view(): any { return this._view; }
  private _view: any = null;

  @Input('bkgImageWidth')
  public bkgImageWidth: number = undefined;

  @Input('bkgImageHeight')
  public bkgImageHeight: number = undefined;

  private _width: any = null;
  private _height: any = null;

  private get width(): any { return this.bkgImageWidth ?? this.element.nativeElement.offsetWidth; }
  private get height(): any { return this.bkgImageHeight ?? this.element.nativeElement.offsetHeight; }

  constructor(private element: ElementRef, private me: MeService,) {
  }

  ngAfterViewChecked() {

    // set image after view is stabilized - problems with virtual scroll
    // measuring offsetWidth and offsetHeight breaks scrolling to top

    if (this.width == 0 || this.height == 0)
      return;

    if (this._width == this.width &&
        this._height == this.height)
          return;

    this.setBackground();
    this._width = this.width;
    this._height = this.height;

  }

  private setBackground() {

    this.element.nativeElement.style.backgroundImage = this.createImageUrl();

  }

  private createImageUrl(): any {

    if (this.view)
      return this.me.createImageUrl(this.view, this.width, this.height);
    else
      return '';

  }

}
