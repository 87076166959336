import { Inject, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EndSessionRequest, EventMessage, EventType, InteractionStatus, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import * as _ from "lodash";
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// MSAL documentation
// https://azuread.github.io/microsoft-authentication-library-for-js/ref/index.html

// B2C session behavior
// https://learn.microsoft.com/en-us/azure/active-directory-b2c/session-behavior?pivots=b2c-custom-policy

// Azure AD B2C with Azure AD Multi-Tenant - option to always choose MS account to login with?
// https://stackoverflow.com/questions/64777221/azure-ad-b2c-with-azure-ad-multi-tenant-option-to-always-choose-ms-account-to

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private authCfg: any = null;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {

    // current authentication
    this.authCfg = (window as any)['clientCfg'];

  }

  async OnAppInit(onInitialized: () => void): Promise<void> {

    this.authService.handleRedirectObservable().subscribe();

    // MSAL initialization
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v3-samples/angular-standalone-sample/src/app/app.component.ts

    this.setloginDisplay();
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setloginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {

        this.setloginDisplay();
        this.checkAndSetActiveAccount();

        console.log(`AUTH: account ${this.account?.name}`);
        if (onInitialized) {

          onInitialized();
          onInitialized = null;   // just once
          // console.log(`AUTH: onInitialized called`);

        }

      })

  }

  OnAppDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setloginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this._account = accounts[0];
    }
    else
      this._account = activeAccount;
  }

  loginRedirect(provider: string | null = null) {

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(this.buildRedirectRequestExt(provider));
    }

  }

  logout() {
    this.authService.logoutRedirect(this.endSessionRequest());
  }


  ////////////////////
  // GeneralSemantics implementation
  private _account: AccountInfo = null;

  public get canSignIn(): boolean { return this.authService != null; }
  public get isSignedIn(): boolean { return this._account != null; }
  public authenticationResult: AuthenticationResult;
  public get account(): AccountInfo {
    return this._account;
    // this.authService.instance.getActiveAccount();
  }


  private get redirectUri(): string {

    return window.location.origin;

  }
  private get redirectRequest(): RedirectRequest {

    var request: RedirectRequest = {

      scopes: this.authCfg.scopes,
      redirectUri: this.redirectUri,
      prompt: 'select_account',
      extraQueryParameters: {
        mkt: document.documentElement.getAttribute('lang')!             // locale
      }

    };

    // get login_hint
    //var loginHint = localStorage.getItem('user:login_hint');
    //if (loginHint)
    //  authParams.loginHint = loginHint;

    return request;
  }
  private silentRequest(aur: AuthenticationResult): SilentRequest {

    var request: SilentRequest = {

      scopes: this.authCfg.scopes,
      redirectUri: this.redirectUri,
      account: this.account,
      extraQueryParameters: {
        mkt: document.documentElement.getAttribute('lang')!            // locale
      }

    };

    if (aur) {

      request.authority = aur.authority;
      request.account = aur.account;
    }

    return request;
  }
  private endSessionRequest(): EndSessionRequest {

    var request: EndSessionRequest = {

      account: this.account,
      postLogoutRedirectUri: this.redirectUri,
      extraQueryParameters: {
        mkt: document.documentElement.getAttribute('lang')!             // locale
      }

    }

    return request;
  }

  private buildRedirectRequestExt(provider: string) {

    // build auth params
    var authParams = this.redirectRequest;

    // configure the authority for Azure AD B2C external providers
    let authority = `${this.authCfg.authority}/${this.authCfg.domain}/${this.authCfg.signUpSignInPolicyExt}`;

    // fill in domain hint
    authParams.authority = authority;
    authParams.extraQueryParameters['domain_hint'] = provider;
    authParams.state = provider;  // to have domain available after handling redirect

    return authParams;
  }

  private buildRedirectRequestLoc() {

    // build auth params
    var authParams = this.redirectRequest;

    // configure the authority for Azure AD B2C local account
    let authority = `${this.authCfg.authority}/${this.authCfg.domain}/${this.authCfg.signUpSignInPolicyLoc}`;

    // fill in domain hint
    authParams.authority = authority;
    authParams.state = 'local';  // to have domain available after handling redirect

    return authParams;
  }

  private buildRedirectRequest() {

    // build redirect request based on state from authenticationResult
    var state = this.authenticationResult?.state;

    if (!state)
      return this.redirectRequest;
    else
      if (state === 'local')
        return this.buildRedirectRequestLoc();
      else
        return this.buildRedirectRequestExt(state);

  }

  acquireToken(aur: AuthenticationResult = null): Promise<any> {

    if (!this.authService)
      return null;

    return new Promise<any>((resolve, reject) => {

      // return null if not signed in
      if (!aur && !this.loginDisplay)
        resolve(null);

      // try to silently acquire token
      this.authService.acquireTokenSilent(this.silentRequest(aur))
        .toPromise()
        .then(

        authenticationResult => {

          // store as actual authentication result
          this.authenticationResult = authenticationResult;

          // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/736
          // ... when using your own AAD app clientId as your scope, you are doing a self-authorizing,
          // and id_token == access_token.And in this case, when you call acquireTokenSilent(ATS)
          // when there's no id_token in cache, it'd first get you an id_token, and at this time,
          // acess_token will be null.And if you call ATS the second time,
          // it'd return you access_token, which is the same token as id_token.

          var accessToken = authenticationResult.accessToken;
          if (!accessToken)
            accessToken = authenticationResult.idToken;

          if (!accessToken)
            console.log('AUTH ERROR: access token null', authenticationResult);

          resolve(accessToken);

        },
        rejected => {

          if (!aur) {

            // build redirect request based on users from authenticationResult
            var req = this.buildRedirectRequest();

            // try to acquire token using redirect
            this.authService.acquireTokenRedirect(req);

          }
          else
            reject();

        });

    });

  }


}
