
@if (entities) {

  <div class="toolbar-top" [class.scrolled]="scrolled">

    @if (!twoPane) {
    <button mat-icon-button (click)="nav.back()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    }
    @else {
      <div style="width: 16px"></div>
    }


    @if (!searchVisible) {
    <div class="title">
      <span class="title-medium">{{listTitle}}</span>
      <span class="label-medium">{{listSubtitle}}</span>
    </div>
    }

    <mat-form-field class="search-field" [class.search-field-hidden]="!searchVisible">
      <input #searchInput matInput type="text" [(ngModel)]="search">
    </mat-form-field>

    <span class="spacer"></span>

    @if (!singleEntity) {

    <button matSuffix mat-icon-button (click)="onSearch()">
      <mat-icon>{{refreshVisible ? 'refresh' : 'search'}}</mat-icon>
    </button>

    }

    @if (!twoPane) {
    <ui-cart-btn></ui-cart-btn>
    }

  </div>

  <div #list class="item-host" cdkScrollable>

  @if (!isGrouped) {

      @for(entity of pagedData; track entity.id) {

      <ng-container *ngTemplateOutlet="content.templateRef; context: { entity: entity }"></ng-container>

      }

  }
  @else {

    @for (item of nonEmptyGroups(); track item.group.id) {

      <ui-section-header #sections [label]="item.group.title" [divider]="true" [expanded]="$index < 2"></ui-section-header>

      @if (isExpanded($index)) {

        @for(entity of item.entities; track entity.id) {

        <ng-container *ngTemplateOutlet="content.templateRef; context: { entity: entity }"></ng-container>

        }

      }

    }

  }

  </div>

  @if (!isGrouped) {

  <mat-paginator #paginator
                 [length]="filteredCount"
                 [hidePageSize]="true">
  </mat-paginator>

  }

}
