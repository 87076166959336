import { NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiNavbarBottomComponent } from '../ui-navbar-bottom/ui-navbar-bottom.component';
import { UiNavbarRailComponent } from '../ui-navbar-rail/ui-navbar-rail.component';
import { UiNavsheetRootComponent } from '../ui-navsheet-root/ui-navsheet-root.component';

@Component({
    selector: 'view-sidenav',
    templateUrl: './view-sidenav.component.html',
    styleUrls: ['./view-sidenav.component.css'],
    standalone: true,
  imports: [MatSidenavContainer, MatSidenav, UiNavsheetRootComponent, MatSidenavContent, RouterOutlet, UiNavbarBottomComponent, UiNavbarRailComponent, NgStyle]
})
export class ViewSidenavComponent
       implements OnInit, OnDestroy {

  @ViewChild('sidenav', { static: true })
  sidenav: MatSidenav;

  @ViewChild('navroot', { static: true })
  navroot: UiNavsheetRootComponent;

  constructor(private me: MeService, private nav: NavService) {

  }

  private onNav$: Subscription;

  ngOnInit(): void {

    this.onNav$ = this.nav.onNav.subscribe((section) => {
      if (section)
        this.navroot.section = section;
      //else
      //  this.navroot.section = 'main';
      this.sidenav.open();
    });

  }

  ngOnDestroy(): void {

    if (this.onNav$)
      this.onNav$.unsubscribe();

  }

  get layoutCompact(): boolean {
    return this.me.windowSizeCompact;
  }

  get layoutMedium(): boolean {
    return this.me.windowSizeMedium;
  }

}
