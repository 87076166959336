
@if (any) {
<ui-user-note *ngFor="let note of notes"
              [note]="note"
              (click)="onClick(note)"></ui-user-note>
}
@else {
<div class="label-large">{{text.help}}</div>
}

