import { Component, Input, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCell, MatCellDef, MatColumnDef, MatFooterCell, MatFooterCellDef, MatFooterRow, MatFooterRowDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { OrderSummary } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { UiPriceComponent } from '../ui-price/ui-price.component';
import { UiPropertyGroupComponent } from '../ui-property-group/ui-property-group.component';

@Component({
    selector: 'ui-order-summary',
    templateUrl: './ui-order-summary.component.html',
    styleUrls: ['./ui-order-summary.component.css'],
    standalone: true,
    imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, UiPriceComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow, MatButton, MatIcon, UiPropertyGroupComponent]
})
export class UiOrderSummaryComponent implements OnInit {

  @Input('summary') public summary: OrderSummary;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['ui-order-summary']; }

  public get title(): string { return this.summary.name || this.text.title;  }

  public get priceDescription(): string {
    var vatText = null;
    if (this.summary.itemsPrice.type.includesVAT === true)
      vatText = this.text.incVAT;
    else
    if (this.summary.itemsPrice.type.includesVAT === false)
      vatText = this.text.excVAT;

    return `${this.summary.itemsPrice.type.label} ${vatText}`;

  }

  public get repeatBtnVisible() {
    return this.summary.orderId; 
  }

  public displayedColumns: string[] = ['name', 'count', 'price'];
  public deliveryColumns: string[] = ['deliveryType', 'packageCount', 'deliveryPrice'];

  constructor(public me: MeService) { }

  ngOnInit() {

  }

  repeatOrder() {

    //this.me.executeOperation('ad4wine.order.repeat', {

    //  orderId: this.orderId

    //}).subscribe(() => {

    //  this.cts.open();

    //});

  }

}
