import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-working',
    template: '',
    styleUrls: ['./ui-working.component.css'],
    standalone: true
})
export class UiWorkingComponent {

  constructor() { }

}
