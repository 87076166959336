import { Component } from '@angular/core';

@Component({
  selector: 'view-user-documents',
  templateUrl: './view-user-documents.component.html',
  styleUrls: ['./view-user-documents.component.css'],
  standalone: true,
  imports: []
})
export class ViewUserDocumentsComponent {

  constructor() {

  }

}
