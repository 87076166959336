<mat-toolbar>

  <button mat-icon-button *ngIf="sortVisible"
          [matMenuTriggerFor]="sorts"
          [matMenuTriggerData]="{sorts: filter.sorter.sorts}"
          matBadge="1" [matBadgeHidden]="!isSorted" matBadgeColor="primary"
          matBadgeSize="small" matBadgePosition="below after">
    <mat-icon>sort</mat-icon>
  </button>

  <button mat-icon-button *ngIf="groupVisible"
          [matMenuTriggerFor]="groupings"
          [matMenuTriggerData]="{groupings: filter.grouper.groupings}"
          matBadge="1" [matBadgeHidden]="!isGrouped" matBadgeColor="primary"
          matBadgeSize="small" matBadgePosition="below after">
    <mat-icon>filter_1</mat-icon>
  </button>

  <span class="spacer"></span>

  <button mat-icon-button (click)="onReset()" [disabled]="resetDisabled">
    <mat-icon>refresh</mat-icon>
  </button>
  <button mat-icon-button (click)="onShow()">
    <mat-icon>task_alt</mat-icon>
  </button>

</mat-toolbar>

<div class="selector-state">
  <div class="label-small selection-path">{{selectionPath}}</div>
  <div class="label-small selection-path" *ngIf="sortPath">{{sortPath}}</div>
  <div class="label-small selection-path" *ngIf="groupPath">{{groupPath}}</div>
</div>

<div class="section-host">

  <ng-container *ngFor="let section of sections">

    <ui-section-header label="{{section.title}}" [divider]="true" [button]="false"></ui-section-header>

    <mat-chip-listbox [multiple]="true"
                      [selectable]="true"
                      [disabled]="section.selectors?.length < 2">

      <mat-chip-option *ngFor="let selector of section.selectors"
                       [selected]="selector.state"
                       [disabled]="selector.currentCount < 1"
                       (click)="toggleSelected(section, selector)">
        <span class="label-small">{{selector.title}} <span class="current-count" *ngIf="selector.currentCount > 0">{{selector.currentCount}}</span></span>
      </mat-chip-option>

    </mat-chip-listbox>

  </ng-container>

</div>

<mat-menu #sorts="matMenu">

  <ng-template matMenuContent let-sorts="sorts">

    <button mat-menu-item [disabled]="true">
      <span>{{text.sortTitle}}:</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item [disabled]="isSortedBy(null)" (click)="sortBy(null)">
      <mat-icon [style.visibility]="isSortedBy(null)?'visible':'hidden'">
        check
      </mat-icon>
      <span>{{text.sortNone}}</span>
    </button>

    <button mat-menu-item *ngFor="let sort of sorts" (click)="sortBy(sort)">
      <mat-icon [style.visibility]="isSortedBy(sort)?'visible':'hidden'">
        {{sort.state == 'desc' ? 'arrow_downward' : 'arrow_upward'}}
      </mat-icon>
      <span>{{sort.title}}</span>
    </button>

  </ng-template>

</mat-menu>

<mat-menu #groupings="matMenu">

  <ng-template matMenuContent let-groupings="groupings">

    <button mat-menu-item [disabled]="true">
      <span>{{text.groupTitle}}:</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item [disabled]="isGroupedBy(null)" (click)="groupBy(null)">
      <mat-icon [style.visibility]="isGroupedBy(null)?'visible':'hidden'">
        check
      </mat-icon>
      <span>{{text.groupNone}}</span>
    </button>

    <button mat-menu-item *ngFor="let grouping of groupings" [disabled]="isGroupedBy(grouping)" (click)="groupBy(grouping)">
      <mat-icon [style.visibility]="isGroupedBy(grouping)?'visible':'hidden'">
        check
      </mat-icon>
      <span>{{grouping.title}}</span>
    </button>

  </ng-template>

</mat-menu>
