import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MeService } from '../../services/me.service';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';
import { UiUserNotesComponent } from '../ui-user-notes/ui-user-notes.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';

@Component({
  selector: 'view-user-notes',
  templateUrl: './view-user-notes.component.html',
  styleUrls: ['./view-user-notes.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, UiUserNotesComponent, CdkScrollable]
})
export class ViewUserNotesComponent
  extends UiScrollableComponent
  implements OnInit {

  @ViewChild('content', { static: true, read: ElementRef<HTMLElement> })
  set content(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  constructor(public override me: MeService, 
    renderer: Renderer2,
    host: ElementRef,
    scrollDispatcher: ScrollDispatcher,
    zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone)

  }

  override ngOnInit(): void {

    // call base implementation
    super.ngOnInit();

  }
}
