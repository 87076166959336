import { Component } from '@angular/core';

@Component({
    selector: 'ui-dummy',
    templateUrl: './ui-dummy.component.html',
    styleUrls: ['./ui-dummy.component.css'],
    standalone: true
})
export class UiDummyComponent {

}
