import { Directive, HostListener } from '@angular/core'
import { NavService } from '../services/nav.service'

@Directive({
    selector: '[backButton]',
    standalone: true,
})
export class BackButtonDirective {
  constructor(private navigation: NavService) { }

  @HostListener('click')
  onClick(): void {
    this.navigation.back()
  }
}
