import { Component, Input } from '@angular/core';
import { MeService } from '../../services/me.service';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';
import { UiUserOrderComponent } from '../ui-user-order/ui-user-order.component';

@Component({
  selector: 'view-user-order',
  templateUrl: './view-user-order.component.html',
  styleUrls: ['./view-user-order.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, UiUserOrderComponent]
})
export class ViewUserOrderComponent {

  @Input('transactionId')
  public transactionId: string;

  constructor(public me: MeService) {

  }

}
