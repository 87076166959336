
<div (click)="close($event)">

  <div class="label-medium">
    <mat-icon class="icon-warn" color="warn">warning</mat-icon>
    <p><b>Číslo účtu:</b><br/>{{data.accountId}}</p>
    <p [innerHTML]="text.message"></p>
  </div>

  <div class="actions">

    <button mat-button (click)="deleteAccount()">
      <span class="label-medium">{{text.yes}}</span>
    </button>

    <button mat-raised-button color="primary" (click)="close()">
      <span class="label-medium">{{text.no}}</span>
    </button>

  </div>

</div>
