import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IEntityCollectionList } from '../../models/entity.interface';
import { UiSelectorComponent } from '../ui-selector/ui-selector.component';

@Component({
    selector: 'sheet-search',
    templateUrl: './sheet-search.component.html',
    styleUrls: ['./sheet-search.component.css'],
    standalone: true,
    imports: [UiSelectorComponent]
})
export class SheetSearchComponent {

  public get entities(): IEntityCollectionList { return this.data?.entities; }

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: { entities: IEntityCollectionList },
              private bottomSheetRef: MatBottomSheetRef<SheetSearchComponent>) {

  }

  close(event: MouseEvent = null): void {

    this.bottomSheetRef.dismiss();

    if (event)
      event.preventDefault();

  }

}
