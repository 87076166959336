
<div class="item" matRipple>

  <img class="image" [src]="imageSrc" loading="lazy" decoding="async" />

  <div class="item-data">

    <div class="row-space">

      <div class="label-small">
        <ui-state [state]="note.state"></ui-state>
        @if(manufacturerVisible) {
        <div>{{note.manufacturer}}</div>
        }
      </div>

      <span class="spacer"></span>

      <ui-note-rating class="dimmed" [note]="note.note"></ui-note-rating>

    </div>

    <div class="title label-large">{{note.name}}</div>
    <div class="subtitle label-small">{{note.subtitle}}</div>

    <div class="note label-small" [innerHtml]="note.note.note | lineBreaks"></div>

  </div>

</div>

<mat-divider></mat-divider>
