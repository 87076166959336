import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MeService } from '../../services/me.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'sheet-user-legal-age',
    templateUrl: './sheet-user-legal-age.component.html',
    styleUrls: ['./sheet-user-legal-age.component.css'],
    standalone: true,
    imports: [MatIcon, MatButton]
})
export class SheetUserLegalAgeComponent implements OnInit {

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.session.texts.components['sheet-user-legal-age']; }

  constructor(public me: MeService, private _bottomSheetRef: MatBottomSheetRef<SheetUserLegalAgeComponent>) { }

  ngOnInit() {

  }

  confirm() {

    this.me.setLegalAgeConsent();
    this.me.setNotifications();
    this._bottomSheetRef.dismiss();

  }

  reject() {

    this._bottomSheetRef.dismiss();
    this.me.navigate('ad4wine.com');

  }

  close(event: MouseEvent = null): void {

    this._bottomSheetRef.dismiss();

    if (event)
      event.preventDefault();

  }

}
