import { Component, Input, OnInit } from '@angular/core';
import { IOrderSummary, OrderSummary } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { UiOrderSummaryComponent } from '../ui-order-summary/ui-order-summary.component';

@Component({
    selector: 'ui-user-order',
    templateUrl: './ui-user-order.component.html',
    styleUrls: ['./ui-user-order.component.css'],
    standalone: true,
    imports: [UiOrderSummaryComponent]
})
export class UiUserOrderComponent implements OnInit {

  @Input('transactionId') public transactionId: string;

  public get text(): any { return this.me.texts.components['ui-user-orders']; }
  public get msg(): any { return this.me.texts.components['common']; }

  public summary: OrderSummary;

  constructor(private me: MeService) { }

  ngOnInit(): void {

    this.me.call<IOrderSummary>('browse.user.order.get', {

      transactionId: this.transactionId

    }).subscribe((orderSummary) => {

      this.summary = new OrderSummary(orderSummary);

    });

  }


}
