import { Component, Input } from '@angular/core';
import * as _ from "lodash";
import { IPropertyGroup } from '../../models/entity.interface';
import { UiPropertyValueComponent } from '../ui-property-value/ui-property-value.component';

@Component({
    selector: 'ui-property-list',
    templateUrl: './ui-property-list.component.html',
    styleUrls: ['./ui-property-list.component.css'],
    standalone: true,
    imports: [UiPropertyValueComponent]
})
export class UiPropertyListComponent {

  @Input('group') public group: IPropertyGroup;

  constructor() { }

  public get showPropertyName() {

    if (this.group.properties && this.group.properties.length === 1) {

      var value = this.group.properties[0].value;
      if (value && value.formatted) {

        if (_.isArray(value.formatted))
          return false;

        if (_.isObjectLike(value.formatted))
          return false;

      }
    }

    return true;
  }

}
