
<ng-container *ngIf="cartForm">

  <div class="toolbar">

    <button mat-flat-button (click)="previous()">
      <span class="toolbar-button-content">
        <mat-icon>chevron_left</mat-icon>
        {{text.delivery.caption}}
      </span>
    </button>

    <div class="spacer"></div>

    <button mat-flat-button (click)="next()">
      <span class="toolbar-button-content">
        {{text.summary.caption}}
        <mat-icon>chevron_right</mat-icon>
      </span>
    </button>

  </div>

  <div class="form">

    <ui-form #uiform [form]="cartForm.form" [data]="cartForm.data"></ui-form>

    <div class="cart-name-input">

      <mat-form-field>
        <input matInput placeholder="{{text.form.orderName}}" [type]="text" [(ngModel)]="cartForm.name">
        <mat-hint>{{text.form.orderNameHint}}</mat-hint>
      </mat-form-field>

    </div>

    <div class="label-large requried-hint">{{text.form.required}}</div>

  </div>

</ng-container>
