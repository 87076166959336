import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { NavService } from '../../services/nav.service';

const CART_FILLED =
`<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/>
</svg>`;

const CART_OUTLINED =
`<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
  <path d="M0 0h24v24H0V0z" fill="none"/>
  <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/>
</svg>`;

@Component({
    selector: 'ui-cart-btn',
    templateUrl: './ui-cart-btn.component.html',
    styleUrls: ['./ui-cart-btn.component.css'],
    standalone: true,
    imports: [MatIconButton, MatBadge, MatIcon]
})
export class UiCartBtnComponent {

  @Input('badgeHidden')
  badgeHidden: boolean = false;

  @Output('onCart') onCart = new EventEmitter<any>();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private me: MeService, private nav: NavService, public select: SelectService) {

    iconRegistry.addSvgIconLiteral('cart_filled', sanitizer.bypassSecurityTrustHtml(CART_FILLED));
    iconRegistry.addSvgIconLiteral('cart_outlined', sanitizer.bypassSecurityTrustHtml(CART_OUTLINED));

  }

  public get cartVisible(): boolean {
    return this.select.any;
  }

  private get numCartItems(): number {
    return this.select.totalCount;
  }

  public get cartBadgeHidden(): boolean {
    return this.badgeHidden || this.numCartItems == 0;
  }

  public get cartText(): any {
    return this.numCartItems;
  }

  public get cartIcon(): string {
    return this.numCartItems > 0 ? 'cart_filled' : 'cart_outlined';
  }

  openCart() {

    if (this.onCart.observed)
      this.onCart.emit();
    else
      this.nav.to('me/cart');

  }

}
