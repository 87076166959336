import { Component, EventEmitter, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MeService } from '../../services/me.service';

@Component({
    selector: 'ui-cart-sent',
    templateUrl: './ui-cart-sent.component.html',
    styleUrls: ['./ui-cart-sent.component.css'],
    standalone: true,
    imports: [MatButton, MatIcon]
})
export class UiCartSentComponent {

  @Output('onPrevious') onPrevious = new EventEmitter<any>();
  @Output('onNext') onNext = new EventEmitter<any>();

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['sheet-order-sent']; }

  constructor(private me: MeService) { }

  public next() {

    this.onNext.emit();

  }

}
