<mat-expansion-panel>

  <mat-expansion-panel-header>

    <mat-panel-title>{{objectTitle}}</mat-panel-title>

    @if (isDefined) {

    <mat-panel-description>{{hint}}</mat-panel-description>

    }
    @else {

    <mat-panel-description>Empty - click to create</mat-panel-description>

    }

  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>

    @if (isDefined) {

    <div>

      @if (objectSchema.sections?.length > 1) {

      <div>

        @for (section of objectSchema.sections; track $index; let i = $index) {

        <div class="mat-step">

          <div class="step-header" (click)="toggleSection(section)">

            <div class="step-icon" [ngClass]="sectionClass(section)">
              <div class="step-icon-content">{{i+1}}</div>
            </div>
            <div class="step-label">{{section.title}}</div>

          </div>

          @if (sectionExpanded(section)) {

          <div class="step-content">

            <ng-container *ngTemplateOutlet="formTemplate;context:{section:section}"></ng-container>

          </div>

          }

        </div>

        }

      </div>

      }
      @else if (objectSchema.sections?.length == 1) {

      <div class="formContent">

        <ng-container *ngTemplateOutlet="formTemplate;context:{section:objectSchema.sections[0]}"></ng-container>

      </div>

      }

    </div>

    }

    @if (!isDefined && !readonly) {

    <mat-action-row>

      <button mat-button color="primary" (click)="createNew()">Create</button>

    </mat-action-row>

    }

  </ng-template>

</mat-expansion-panel>

<ng-template #formTemplate let-section="section">

  @if (section.layout && !singleColumn) {

  <div class="formContent">

    @for (row of section.layout.rows; track $index) {

    <div [ngStyle]="row.style">

      @for (column of row.columns; track $index) {

      <form-field [formGroup]="objectGroup"
                  [formSchema]="objectSchema"
                  [attribute]="column.attribute"
                  [readonly]="readonly"
                  [culture]="culture"
                  [doc]="value"></form-field>

      }

    </div>

    }

  </div>

  }
  @else {

  <div class="formContent">

    @for (attribute of section.attributes; track $index) {

    <form-field [formGroup]="objectGroup"
                [formSchema]="objectSchema"
                [attribute]="attribute"
                [readonly]="readonly"
                [culture]="culture"
                [doc]="value"></form-field>

    }

  </div>

  }


</ng-template>
