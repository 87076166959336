import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'sheet-user-account-delete',
  templateUrl: './sheet-user-account-delete.component.html',
  styleUrls: ['./sheet-user-account-delete.component.css'],
  standalone: true,
  imports: [MatIcon, MatButton]
})
export class SheetUserAccountDeleteComponent {

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.session.texts.components['sheet-user-account-delete']; }

  constructor(public me: MeService, private nav: NavService,
    private _bottomSheetRef: MatBottomSheetRef<SheetUserAccountDeleteComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { accountId: string })
  {

  }


  deleteAccount() {

    this.me.call<void>('browse.user.account.delete', {

      id: this.data.accountId,
      delete: true,

    }).subscribe(() => {

      // delete deviceId and originalId
      this.me.resetDeviceId();
      this.me.resetDeviceIdOriginal();
      this.me.session.deviceId = null;

      // reload app for the new user
      this.me.closeAllDialogs();
      this.me.initSession(this.me.session.domainId)
        .then(() => this.nav.to('/'));

    }, () => {

      // open bottom sheet with error message
      this.me.openBottomSheet(this.text.msgErrorId, 'error', 5000);

    });

  }

  close(event: MouseEvent = null): void {

    this._bottomSheetRef.dismiss();

    if (event)
      event.preventDefault();

  }

}
