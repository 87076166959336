<mat-form-field>

  <mat-label [innerHtml]="fieldLabel"></mat-label>

  <input matInput [type]="textInputType" [formControl]="fieldCtrl" [required]="required ? 'required' : null" spellcheck="true" [lang]="currentLocale">

  @if (hint) {

  <mat-hint>{{hint}}</mat-hint>

  }

  @if (fieldCtrl?.invalid) {

  <mat-error>{{errMsg}}</mat-error>

  }

</mat-form-field>
