
<div>

  <div class="row">

    <mat-icon>help</mat-icon>

    <span [innerHTML]="text.message"></span>

  </div>

  <div class="message" [innerHTML]="text.question"></div>

  <div class="row actions">

    <button mat-raised-button (click)="reject()">{{msg.option.no}}</button>

    <button mat-raised-button (click)="confirm()">{{msg.option.yes}}</button>

  </div>

  <div class="note dimmed"  [innerHTML]="text.note"></div>

</div>
