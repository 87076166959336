import { Component } from '@angular/core';
import { UiCartComponent } from '../ui-cart/ui-cart.component';

@Component({
  selector: 'view-user-cart',
  templateUrl: './view-user-cart.component.html',
  styleUrls: ['./view-user-cart.component.css'],
  standalone: true,
  imports: [UiCartComponent]
})
export class ViewUserCartComponent {

  constructor() {

  }

}
