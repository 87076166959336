
<div class="toolbar">

  <button mat-flat-button (click)="next()">
    <span class="toolbar-button-content">
      {{text.done}}
      <mat-icon>done</mat-icon>
    </span>
  </button>

</div>

<div class="message">

  <span class="label-large" [innerHTML]="text.message"></span>

</div>
