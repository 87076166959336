
@if (!isEmpty) {

  <div class="toolbar">

    <div class="total label-large">

      <div class="total-text">{{text.items.total}}:</div>

      @if (cart.itemsPrice) {
      <ui-price [price]="cart.itemsPrice" [hint]="false"></ui-price>
      }

    </div>

    <div class="spacer"></div>

    <button mat-flat-button [disabled]="noItemsToOrder" (click)="onNext.emit()">
      <span class="toolbar-button-content label-large">
        {{text.buy.order}}
        <mat-icon>chevron_right</mat-icon>
      </span>
    </button>

  </div>


  <div class="items">

    <ui-cart-item *ngFor="let item of items" [cart]="cart" [item]="item"></ui-cart-item>

    <div class="price-description label-medium">{{text.items.priceDescription}}<br />{{priceDescription}}</div>

    @if (items?.length) {
    <button mat-button class="clearbtn" color="accent" (click)="onRemoveAll()">{{text.items.delete}}</button>
    }

  </div>

}
