import { Component } from '@angular/core';
import * as _ from "lodash";
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { SelectService } from '../../services/select.service';
import { UiCartDeliveryOptionListComponent } from '../ui-cart-deliveryoption-list/ui-cart-deliveryoption-list.component';
import { UiCartFormComponent } from '../ui-cart-form/ui-cart-form.component';
import { UiCartItemListComponent } from '../ui-cart-item-list/ui-cart-item-list.component';
import { UiCartSentComponent } from '../ui-cart-sent/ui-cart-sent.component';
import { UiCartSummaryComponent } from '../ui-cart-summary/ui-cart-summary.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';

@Component({
  selector: 'ui-cart',
  templateUrl: './ui-cart.component.html',
  styleUrls: ['./ui-cart.component.css'],
  standalone: true,
  imports: [UiToolbarTopComponent, UiCartItemListComponent, UiCartDeliveryOptionListComponent, UiCartFormComponent, UiCartSummaryComponent, UiCartSentComponent]
})
export class UiCartComponent {

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['view-shoppping-cart']; }
  public get title(): string {

    var title = this.msg.shopping.cart.title;

    var count = this.ssv.totalCount ?
      `${this.ssv.totalCount}` :
      _.toLower(this.msg.shopping.cart.empty);

    var step = '';
    switch (this.step) {
      case 'delivery':
        step = this.msg.shopping.cart.delivery;
        break;
      case 'form':
        step = this.msg.shopping.cart.form;
        break;
      case 'summary':
        step = this.msg.shopping.cart.summary;
        break;
      case 'sent':
        step = this.msg.shopping.cart.sent;
        break;
    }

    if (step)
      return `${step}`;
    else
      return `${title} (${count})`;
  }

  public step: string = 'items';

  constructor(public me: MeService, private ssv: SelectService, private nav: NavService) {

  }

  cartSent() {

    // clear selection
    this.ssv.removeAll();

    // move to final sent step
    this.step = 'sent';
  }

  ////////////////////////////////////////////
  // back
  public onBack() {
    this.nav.back();
  }

}
