import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MeService } from '../../services/me.service';

@Component({
  selector: 'sheet-application-install',
  templateUrl: './sheet-application-install.component.html',
  styleUrls: ['./sheet-application-install.component.css'],
  standalone: true,
  imports: [MatIcon, MatButton]
})
export class SheetApplicationInstallComponent implements OnInit {

  public get msg(): any { return this.me.msg.app.install; }
  public get text(): any { return this.me.texts.components['sheet-application-install']; }

  constructor(public me: MeService,
              private _bottomSheetRef: MatBottomSheetRef<SheetApplicationInstallComponent>) { }

  ngOnInit() {

  }

  close(event: MouseEvent = null): void {

    this._bottomSheetRef.dismiss();

    if (event)
      event.preventDefault();

  }







}
