import { Component, Input } from '@angular/core';
import { ProductPrice } from '../../models/cart.class';
import { IProduct } from '../../models/entity.interface';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiCartCountComponent } from '../ui-cart-count/ui-cart-count.component';
import { UiEntityImageComponent } from '../ui-entity-image/ui-entity-image.component';
import { UiPriceProductComponent } from '../ui-price-product/ui-price-product.component';
import { UiStateComponent } from '../ui-state/ui-state.component';


@Component({
    selector: 'ui-entity-tile',
    templateUrl: './ui-entity-tile.component.html',
    styleUrls: ['./ui-entity-tile.component.css'],
    standalone: true,
    imports: [
      UiEntityImageComponent,
      UiPriceProductComponent,
      UiStateComponent,
      UiCartCountComponent
    ],
})
export class UiEntityTileComponent {

  @Input('entity')
  public entity: IProduct = null;

  public get manufacturerVisible(): boolean {
    return this.me.session.domainId !== this.entity?.manufacturerId;
  }

  // pricing
  public get hasPricing(): boolean {
    if (this.entity?.pricing)
      return this.entity.pricing.length > 0;
    else
      return false;
  }
  public get price(): ProductPrice {
    if (this.hasPricing)
      return new ProductPrice(this.entity.pricing[this.entity.pricing.length - 1]);
    else
      return null;
  }

  public get viewCartCount(): boolean {
    return this.select.isSelected(this.entity.id);
  }

  public get viewCountOnly(): boolean {
    return this.me.windowSizeCompact &&
           this.select.isSelected(this.entity.id);
  }

  public get imageHeight(): number {
    return this.me.windowCompact ? 160 : 240;
  }

  public get imageSrc(): string {
    return this.entity.image?.imageSrc;
  }

  public get imageSrcSet(): string {
    return this.entity.image?.imageSrcSet;
  }


  constructor(public me: MeService, private select: SelectService) {

  }

  public viewState(entity: IProduct): boolean {

    return this.me.viewState(entity.state);

  }
}
