import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from "lodash";
import { IEntity, IEntityCollectionList, IEntityFilter, IEntityGroup, IEntityGrouping, IEntitySort, IHeaderInfo } from '../../models/entity.interface';
import { IImageRequest } from '../../models/media.interface';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { SheetSearchComponent } from '../sheet-search/sheet-search.component';
import { UiBtnMenuComponent } from '../ui-btn-menu/ui-btn-menu.component';
import { UiCartBtnComponent } from '../ui-cart-btn/ui-cart-btn.component';
import { IEntityListView } from '../ui-entity-list-view/ui-entity-list-view.component';
import { UiScrollableComponent } from '../ui-scrollable/ui-scrollable.component';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';
import { RelayService } from '../../services/relay.service';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';


const ESCAPE_KEYCODE = 27;

export interface INonEmptyGroup {
  group: IEntityGroup,
  entities: Array<IEntity>
}

@Directive({
  selector: '[listItem]',
  standalone: true,
})
export class UiEntityListItemDirective {
  constructor(public templateRef: TemplateRef<unknown>) { }
}

@Component({
    selector: 'ui-entity-list',
    templateUrl: './ui-entity-list.component.html',
    styleUrls: ['./ui-entity-list.component.css'],
    standalone: true,
    imports: [
      MatFormField,
      MatInput,
      FormsModule,
      MatIconButton,
      MatSuffix,
      MatIcon,
      UiSectionHeaderComponent,
      UiBtnMenuComponent,
      UiCartBtnComponent,
      NgTemplateOutlet,
      CdkScrollable,
      MatPaginator
    ],
})
export class UiEntityListComponent
  extends UiScrollableComponent
  implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // inputs

  @Input('collectionId')
  public set collectionId(collectionId: string) {
    this._collectionId = collectionId;
    if (this.isInitialized)
      this.reload();
  }
  public get collectionId(): string { return this._collectionId; }
  private _collectionId: string;

  @Input('queryId')
  public set queryId(queryId: string) {
    this._queryId = queryId;
    if (this.isInitialized)
      this.reload();
  }
  public get queryId(): string { return this._queryId; }
  private _queryId: string;

  @Input('imageRequest')
  public imageRequest: IImageRequest;

  @Input('twoPane')
  public twoPane: boolean = false;

  ////////////////////////////////////////////////
  // outputs

  @Output('onLoaded')
  onLoaded: EventEmitter<void> = new EventEmitter<void>();

  @Output('onDetailView')
  onDetailView: EventEmitter<IEntityListView> = new EventEmitter<IEntityListView>();


  ////////////////////////////////////////////////
  // children

  @ViewChild('searchInput', { static: false, read: ElementRef })
  searchInput: ElementRef;

  @ViewChild('list', { static: false, read: ElementRef<HTMLElement> })
  set list(element: ElementRef<HTMLElement>) {
    this.scrollElement = element;
  }

  @ViewChildren('sections')
  sections: QueryList<UiSectionHeaderComponent>;

  @ContentChild(UiEntityListItemDirective)
  content: UiEntityListItemDirective;

  @ViewChild('paginator', { static: false })
  paginator: MatPaginator;


  ////////////////////////////////////////////////
  // keyboard

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {

    // ignore when any dialog open
    if (this.me.topDialog || this.twoPane)
      return;

    if (event.keyCode === ESCAPE_KEYCODE) {

      if (this.bottomSheet._openedBottomSheetRef)
        return;

      if (this.search) {
        this.search = '';
        this.searchInput.nativeElement.blur();
      }

      return;
    }

    if (this.searchInput?.nativeElement)
      this.searchInput.nativeElement.focus();

  }


  ////////////////////////////////////////////////
  // properties

  public get count(): any {
    return this.entities && this.entities.list ? this.entities.list.length : 0;
  }

  public get filteredCount(): any {
    return this.filteredData.length;
  }

  public get isPaged(): boolean {
    return !this.isGrouped && this.filteredCount > 100;
  }

  public get pagedData(): Array<IEntity> {

    if (this.paginator) {

      // reset page, if filtered data cache is different than current
      const filteredData = this.filteredData;
      var changed = filteredData.length != this._filteredDatacache.length;
      if (!changed) {
        for (var i = 0; i < filteredData.length; i++)
          if (filteredData[i] !== this._filteredDatacache[i]) {
            changed = true;
            break;
          }
      }
      if (changed) {
        this.paginator.firstPage();
        this._filteredDatacache = filteredData;
      }

      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = startIndex + this.paginator.pageSize;
      return filteredData.slice(startIndex, endIndex);

    }
    else
      return [];
  }
  private _filteredDatacache: Array<IEntity> = [];

  // search
  public _search: string = null;

  private debounceFilter = _.debounce((search: string) => {
    this._search = search;
    this.dataSource.filter = this.me.normalizeText(search);
    this.updateSelection();
  }, 300);
  public get search(): string { return this._search; };
  public set search(search: string) {
    this.debounceFilter(search);
  };

  // entities
  public entities: IEntityCollectionList;
  public originalFilter: IEntityFilter;
  public isInitialized: boolean = false;

  // entity source
  public dataSource = new MatTableDataSource<IEntity>();

  // active
  public active: IEntity = null;

  // relay entity change subscription
  private onEntityChanged$: Subscription;

  constructor(public override me: MeService, public nav: NavService,
              private bottomSheet: MatBottomSheet, rel: RelayService,
              renderer: Renderer2, host: ElementRef, scrollDispatcher: ScrollDispatcher, zone: NgZone) {

    super(me, renderer, host, scrollDispatcher, zone);

    var meService = me;
    var _this = this;

    this.dataSource.filterPredicate = function (record, filter) {

      // split filter to words
      var filterTerms = _.words(filter);
      var entityTerms = _this.entities.filter.data[record.id].terms;

      if (entityTerms) {

        if (_.every(filterTerms, (filterTerm) => {
          return entityTerms.some((word) => word.startsWith(filterTerm));
        }))
          return true;

      }
      else {

        // put together
        var text = record.name;

        // search with diacritic off
        text = meService.normalizeText(text);

        // split to words
        var words = _.words(text);

        // any word starting with filter?
        if (words.some((word) => word.startsWith(filter)))
          return true;
      }

      // try id
      var id = _.lowerCase(meService.getPathLast(record.id));
      return id.indexOf(filter) >= 0;

    }

    // handle entity changed
    this.onEntityChanged$ = rel.onEntityChanged((payload) => {

      this.reload(payload.id);

    });

  }

  override ngOnInit() {

    // call base implementation
    this.scrollThreshold = 16;  // set scrolled threshold to 16px
    super.ngOnInit();

    this.isInitialized = true;

    // reload att
    this.reload();

  }

  ngOnDestroy(): void {

    this.onEntityChanged$.unsubscribe();

  }

  ////////////////////////////////////////////////
  // title
  public get listHeader(): IHeaderInfo {

    if (this.entities?.headers && this.entities.headers.length)
      return this.entities.headers[0];

    return null;
  }

  public get listTitle(): string {
    return this.listHeader?.title;
  }

  public get listSubtitle(): string {
    return this.entities.filter?.selector?.path ?? this.listHeader?.subtitle;
  }


  ////////////////////////////////////////////////
  // att

  public get singleEntity(): boolean {

    if (!this.entities?.list ||
         this.entities.list.length < 2)
      return true;
    else
      return false;
  }

  public get filteredData(): Array<IEntity> {

    var entities: Array<IEntity>;

    // selector
    if (this.entities?.filter?.selector)
      entities = _.filter(this.dataSource.filteredData, (entity) => {
        return this.entities.filter.data[entity.id].select[0] === true;
      });
    else
      entities = this.dataSource.filteredData;

    // sort
    if (this.isSorted)
      entities = this.sortedData(entities);

    return entities;
  }

  ////////////////////////////////////////////////
  // selection

  isSelected(entity: any) {
    return this.active == entity;
  }

  select(id: string) {

    id = _.trimStart(id, '/');

    var entity = _.find(this.entities.list, (e) => { return e.id == id });
    if (entity)
      this.active = entity;

  }

  updateSelection(id: string = null) {

    if (id)
      this.select(id);
    else
    if (this.dataSource.filteredData.length)
      this.active = this.dataSource.filteredData[0];
    else
      this.active = null;

  }


  ////////////////////////////////////////////////
  // load

  reload(id: string = null) {

    if (!this.collectionId)
      return;

    this.me.call<IEntityCollectionList>('browse.entity.collection.list', {

      collectionId: this.collectionId,
      queryId: this.queryId,
      imageRequest: this.imageRequest

    }).subscribe((entities) => {

      this.entities = entities;
      this.dataSource.data = entities.list;
      this.dataSource.filter = this.me.normalizeText(this._search);
      this.updateSelection(id);

      // update filter defaults
      if (_.isNumber(this.entities.filter?.sorter?.defaultSortId)) {

        this.entities.filter.sorter.selectedSort = this.entities.filter.sorter.sorts[this.entities.filter.sorter.defaultSortId];

        if (this.entities.filter?.sorter?.defaultSortOrder)
          this.entities.filter.sorter.selectedSort.state = this.entities.filter?.sorter?.defaultSortOrder;
      }

      if (_.isNumber(this.entities.filter?.grouper?.defaultGroupingId))
        this.entities.filter.grouper.selectedGrouping = this.entities.filter.grouper.groupings[this.entities.filter.grouper.defaultGroupingId];

      if (entities.filter)
        this.originalFilter = _.cloneDeep(entities.filter);
      else
        this.originalFilter = null;

      this.onLoaded.emit();
    });

  }


  ////////////////////////////////////////////////
  // selector
  public get selectorEnabled(): boolean {

    if (this.singleEntity)
      return false;

    return this.entities?.filter?.sorter ||
           this.entities?.filter?.grouper ||
           this.entities?.filter?.selector ? true : false;
  }

  public onSelector() {

    this.bottomSheet.open(SheetSearchComponent, {data: { entities: this.entities }});

  }


  ////////////////////////////////////////////////
  // search
  public get refreshVisible(): boolean {

    if (this.search)
      return true;

    if (this.entities?.filter?.selector?.path)
      return true;

    return false;
  }

  public get searchVisible(): boolean {
    return (this.search ? true : false) ||
           (document.activeElement === this.searchInput?.nativeElement);
  }

  public onSearch() {

    if (this.refreshVisible) {

      this.search = '';
      if (this.originalFilter)
        this.entities.filter = _.cloneDeep(this.originalFilter);

    }
    else {

      if (this.searchInput?.nativeElement)
        this.searchInput.nativeElement.focus();

    }

  }

  ////////////////////////////////////////////////
  // detail
  public getDetail(entity: IEntity): IEntityListView {

    var list : Array<IEntity> = [];
    if (this.isGrouped)
      _.forEach(this.grouping.groups, (group) => {
        var groupedData = this.groupedData(group);
        if (groupedData)
          list = list.concat(groupedData);
      });
    else
      list = this.filteredData;

    return {
      entity: entity,
      list: list
    };
  }


  ////////////////////////////////////////////////
  // sorting
  public get sorting(): IEntitySort {
    return this.entities.filter?.sorter?.selectedSort;
  }

  public get isSorted(): boolean {
    return this.sorting != null;
  }

  public sortedData(entities: Array<IEntity>): Array<IEntity> {

    if (!this.isSorted)
      return entities;

    var currentSortId = this.sorting.id;
    entities = _.sortBy(entities, [(entity) => {

      return this.entities.filter.data[entity.id].sort[currentSortId];

    }]);

    if (this.sorting.state == 'desc')
      _.reverse(entities);

    return entities;
  }

  ////////////////////////////////////////////////
  // grouping
  public get grouping(): IEntityGrouping {
    return this.entities.filter?.grouper?.selectedGrouping;
  }

  public get isGrouped(): boolean {
    return this.grouping != null;
  }

  public nonEmptyGroups(): Array<INonEmptyGroup> {
    const groups: Array<INonEmptyGroup> = new Array<INonEmptyGroup>;
    if (this.grouping) {
      for (var i = 0; i < this.grouping.groups.length; i++) {
        const entities = this.groupedData(this.grouping.groups[i]);
        if (entities)
          groups.push({
            group: this.grouping.groups[i],
            entities: entities
          });
      }
    }
    return groups;
  }

  public groupedData(group: IEntityGroup): Array<IEntity> {

    // group entities
    var entities = _.filter(this.filteredData, (entity) => {
      return this.entities.filter.data[entity.id].group[group.index] == group.id;
    });

    // sort
    if (this.isSorted)
      entities = this.sortedData(entities);

    return entities.length ? entities : null;

  }

  isExpanded(index: number) {
    return this.sections.get(index)?.expanded;
  }


  ////////////////////////////////////////////////
  // template context
  templateContext(entity: IEntity) {
    return { $implicit: entity };
  }

}
