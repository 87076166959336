import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { MeService } from '../../services/me.service';
import { ImmediateErrorStateMatcher } from '../form-field-text-edit/form-field-text-edit.component';


@Component({
    selector: 'form-field-price',
    templateUrl: './form-field-price.component.html',
    styleUrls: ['./form-field-price.component.css'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatSuffix, MatHint, MatError]
})
export class FormFieldPriceComponent implements OnInit {

  @Input('formGroup') public formGroup: FormGroup | FormArray;
  @Input('price') public price: any;
  @Input('standard') public standard: boolean = true;
  @Input('integer') public integer: boolean = false;
  @Input('required') public required: boolean = false;
  @Input('readonly') public readonly: boolean = false;
  @Input('label') public label: string = null;

  // common field control
  public fieldCtrl: FormControl;
  public matcher = new ImmediateErrorStateMatcher();

  public get currentValue() {

    if (this.standard)
      return this.price.standardPrice.type.includesVAT === false ?
             this.price.standardPrice.excVAT :
             this.price.standardPrice.incVAT;
    else
      return this.price.specialPrice.type.includesVAT === false ?
             this.price.specialPrice.excVAT :
             this.price.specialPrice.incVAT;

  }
  public set currentValue(value) {

    if (this.standard) {
      if (this.price.standardPrice.type.includesVAT === false) {
        this.price.standardPrice.excVAT = value;
        this.price.standardPrice.incVAT = this.incVAT(this.price.standardPrice, value);
      }
      else {
        this.price.standardPrice.incVAT = value;
        this.price.standardPrice.excVAT = this.excVAT(this.price.standardPrice, value);
      }
    }
    else
    if (this.price.specialPrice.type.includesVAT === false) {
        this.price.specialPrice.excVAT = value;
        this.price.specialPrice.incVAT = this.incVAT(this.price.specialPrice, value);
      }
      else {
        this.price.specialPrice.incVAT = value;
        this.price.specialPrice.excVAT = this.excVAT(this.price.specialPrice, value);
      }
  }

  public get computedValue() {

    if (this.standard)
      return this.price.standardPrice.type.includesVAT === false ?
             this.price.standardPrice.incVAT :
             this.price.standardPrice.excVAT;
    else
      return this.price.specialPrice.type.includesVAT === false ?
             this.price.specialPrice.incVAT :
             this.price.specialPrice.excVAT;

  }

  public get labelText(): string {

    if (this.label)
      return this.label;

    if (this.standard)
      return this.text.standard;
    else
      return this.text.action;
  }

  public get suffix(): string {

    if (this.standard)
      return this.price.standardPrice.type.includesVAT === false ?
        `${this.price.standardPrice.currency} ${this.text.excVAT}` :
             this.price.standardPrice.type.includesVAT === true ?
         `${this.price.standardPrice.currency} ${this.text.incVAT}` :
            this.price.standardPrice.currency;
    else
      return this.price.specialPrice.type.includesVAT === false ?
        `${this.price.standardPrice.currency} ${this.text.excVAT}` :
             this.price.specialPrice.type.includesVAT === true ?
        `${this.price.standardPrice.currency} ${this.text.incVAT}` :
           this.price.standardPrice.currency;
  }

  public get computedValueText(): string {

    if (!this.computedValue)
      return null;

    if (this.standard)
      return this.price.standardPrice.type.includesVAT === false ?
        `${this.computedValue} ${this.price.standardPrice.currency} ${this.text.incVAT}` :
        `${this.computedValue} ${this.price.standardPrice.currency} ${this.text.excVAT}`;
    else
      return this.price.specialPrice.type.includesVAT === false ?
        `${this.computedValue} ${this.price.standardPrice.currency} ${this.text.incVAT}` :
        `${this.computedValue} ${this.price.standardPrice.currency} ${this.text.excVAT}`;
  }

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.session.texts.components['ui-price']; }

  public get errMsg(): string {

    if (this.fieldCtrl.errors) {

      if (this.fieldCtrl.hasError('required'))
        return this.msg.formfield.err.required;
      else
      if (this.fieldCtrl.hasError('integer'))
        return this.msg.formfield.err.integer;
      else
      if (this.fieldCtrl.hasError('number'))
        return this.msg.formfield.err.number;

    }

    return null;
  }

  constructor(private me: MeService) {
  }

  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);
    if (this.integer)
      validators.push(this.intValidator());
    else
      validators.push(this.numberValidator());

    // create field control
    this.fieldCtrl = new FormControl(this.currentValue, {
      validators: validators,
      updateOn: 'change'
    });

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(500))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do not promote undefined
        if (_.isUndefined(value))
          return;

        // if null
        if (_.isNull(value)) {
          this.currentValue = null;
          return;
        }

        // if number
        if (_.isNumber(value)) {
          this.currentValue = value;
          return;
        }

        // if empty string
        if (value === '') {
          this.currentValue = null;
          return;
        }

        // replace , by . as decimal digit sepparator
        if (!_.isString(value))
          return;
        else
          value = _.replace(value,',','.')

        // try to convert
        var parsedValue: number;
        if (this.integer)
          parsedValue = Number.parseInt(value);
        else
          parsedValue = Number.parseFloat(value);

        // test parsed value
        if (_.isFinite(parsedValue))
          this.currentValue = parsedValue;
        else
          this.currentValue = null;

      });

    // register
    this.register();
  }

  ngOnChanges() {

    if (this.fieldCtrl)
      this.fieldCtrl.setValue(this.currentValue);

  }

  // VAT computation
  private incVAT(price: any, value: number) : number {

    if (!value)
      return value;

    if (!price.vat)
      return value;

    var incVat = (value + value * (0.01 * price.vat));

    return _.round(incVat, price.decimalPlaces);
  }

  private excVAT(price: any, value: number): number {

    if (!value)
      return value;

    if (!price.vat)
      return value;

    var excVat = (value - value * (price.vat / (100 + price.vat)));

    return _.round(excVat, price.decimalPlaces);
  }

  // validators

  // must be number
  private numberRegex: RegExp = /^[-+]?(?:\d*\.?\d+|\d+\.?\d*)(?:[eE][-+]?\d+)?$/;

  numberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      // empty string = null
      if (_.toString(control.value) == '')
        return null;

      // first check regex
      if (!this.numberRegex.test(control.value))
        return { 'number': { value: control.value } };

      // then try to convert to number
      var number = Number.parseFloat(control.value);
      if (_.isNaN(number))
        return { 'number': { value: control.value } };

      return null;

    };
  }

  // must be integer
  private intRegex: RegExp = /^[-+]?\d+$/;

  intValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      // empty string = null
      if (_.toString(control.value) == '')
        return null;

      // first check regex
      if (!this.intRegex.test(control.value))
        return { 'integer': { value: control.value } };

      // then try to convert to number
      var number = Number.parseInt(control.value);
      if (_.isNaN(number))
        return { 'integer': { value: control.value } };

      return null;

    };
  }

  // form registration
  private static ctrlId: number = 0;
  public register() {

    // add fieldCtrl to formGroup
    if (this.formGroup instanceof FormGroup)
      this.formGroup.registerControl(`price${FormFieldPriceComponent.ctrlId++}`, this.fieldCtrl);
    else
    if (this.formGroup instanceof FormArray)
        this.formGroup.push(this.fieldCtrl);

  }

}
