import { Component, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import * as _ from 'lodash';
import { BkgImageDirective } from '../../directives/bkgimage.directive';
import { IDocumentMedia, IMediaView } from '../../models/media.interface';
import { MeService } from '../../services/me.service';
import { DlgImageViewComponent } from '../dlg-image-view/dlg-image-view.component';

@Component({
    selector: 'ui-image-list',
    templateUrl: './ui-image-list.component.html',
    styleUrls: ['./ui-image-list.component.css'],
    standalone: true,
    imports: [BkgImageDirective, MatIconButton, MatIcon]
})
export class UiImageListComponent {

  @Input('media')
  public set media(media: IDocumentMedia) {
    this._media = media;
    this.imageIdx = 0;
  }
  public get media(): IDocumentMedia { return this._media; }
  private _media: IDocumentMedia = null;

  constructor(private me: MeService) { }

  ////////////////////////////////////////////
  // images

  public get images(): Array<IMediaView> { return this.media.images; }
  public get numImages(): any { return this.images ? this.images.length : 0; }
  public get imageNavigatorVisible(): boolean { return this.images && this.images.length > 1; }
  public imageIdx = 0;
  public get currentImage(): IMediaView { return this.numImages > 0 ? this.images[this.imageIdx] : null; }

  public get canImageNext() { return this.imageIdx < this.numImages - 1; }
  public get btnImageNext() { return this.canImageNext ? 'visible' : 'hidden'; }

  public onImageNext() {

    if (this.canImageNext)
      this.imageIdx += 1;

  }

  public get canImagePrev() { return this.imageIdx > 0; }
  public get btnImagePrev() { return this.canImagePrev ? 'visible' : 'hidden'; }

  public onImagePrev() {

    if (this.canImagePrev)
      this.imageIdx -= 1;

  }

  public onImage(index: number) {

    this.imageIdx = index;

  }

  public get imageHasLink(): boolean {

    if (!this.images || !this.images.length)
      return false;

    var media = this.images[this.imageIdx];
    if (media.link)
      return true;
    else
      return false;

  }

  public onImageClick() {

    if (!this.images || !this.images.length)
      return;

    // get current media
    var media = this.images[this.imageIdx];
    if (media.link) {

      // url to open
      var url: string = null;

      // switch link type
      switch (_.toLower(media.link.type)) {

        case 'url':
          url = media.link.data;
          break;

        case 'youtube':
          url = `https://www.youtube.com/embed/${media.link.data}?rel=0`;
          break;

        default:
          return;
      }

      if (url)
        window.open(url, 'assistant.link');
    }
    else {

      // toggle single / multiple
      // this._singleImage = !this._singleImage;
      this.me.openFullscreenDialog(DlgImageViewComponent, 'dlg-image', this.currentImage);

    }
  }

  public cancelClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}
