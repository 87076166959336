import { NgFor } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import * as _ from "lodash";
import { CartItem, CartItemList, ICartItemList } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiCartItemComponent } from '../ui-cart-item/ui-cart-item.component';
import { UiPriceComponent } from '../ui-price/ui-price.component';

@Component({
    selector: 'ui-cart-item-list',
    templateUrl: './ui-cart-item-list.component.html',
    styleUrls: ['./ui-cart-item-list.component.css'],
    standalone: true,
    imports: [UiPriceComponent, MatButton, MatIcon, NgFor, UiCartItemComponent]
})
export class UiCartItemListComponent implements OnInit {

  @Output('onNext') onNext = new EventEmitter<any>();

  public cart: CartItemList;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['view-shoppping-cart']; }

  public get items(): Array<CartItem> { return this.cart?.items; }
  public get isEmpty(): any { return !this.items || this.items.length == 0; }
  public get isSingle(): any { return this.items && this.items.length == 1; }
  public get isMultiple(): any { return this.items && this.items.length > 1; }
  public get itemsToOrder(): Array<any> {

    if (!this.items)
      return [];

    var itemsOnSale = _.filter(this.items, (item) => { return item.state ? item.state.state == 'sale' : true; });
    var itemsToOrder = _.filter(itemsOnSale, (item) => { return item.count > 0; });

    return _.map(itemsToOrder, (item) => {
      return {
        id: item.id,
        count: item.count
      }
    });

  }
  public get noItemsToOrder(): boolean { return this.itemsToOrder.length == 0; }
  public get priceDescription(): string {

    if (!this.cart.itemsPrice)
      return null;

    var vatText = null;
    if (this.cart.itemsPrice.type.includesVAT === true)
      vatText = this.text.items.incVAT;
    else
    if (this.cart.itemsPrice.type.includesVAT === false)
      vatText = this.text.items.excVAT;

    return `${this.cart.itemsPrice.type.label} ${vatText}`;

  }


  constructor(private me: MeService, private ssv: SelectService) { }

  ngOnInit() {

    this.me.call<ICartItemList>('browse.user.cart.itemlist.get', {

      selectedItems: this.ssv.selection.items

    }).subscribe((cart) => {

      this.cart = new CartItemList(cart);

    });

  }

  public onRemoveAll() {

    // remove all options
    this.items.length = 0;
    this.ssv.removeAll();

  }

}
