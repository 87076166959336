
<ui-entity-list #list
                [style.display]="detail ? 'none' : 'flex'"
                [collectionId]="hostId"
                [queryId]="queryId"
                [imageRequest]="imageRequest">

  <ng-template listItem let-entity="entity">

    <ui-entity-tile [entity]="entity"
                    matRipple [matRippleCentered]="true"
                    (click)="showDetail(entity)"></ui-entity-tile>

  </ng-template>

</ui-entity-list>

@if (detail) {

<ui-entity-list-view [data]="detail"
                     (back)="detail = null"></ui-entity-list-view>

}
@else if (list.selectorEnabled) {

    <ui-btn-fab icon="tune" (onClick)="list.onSelector()"
                [ngStyle]="{ 'bottom': list.isGrouped ? '16px' : '72px' }"></ui-btn-fab>

}
