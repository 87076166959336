
<mat-form-field>
  <mat-label>{{title}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="fieldCtrl">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  @if (fieldCtrl?.invalid) {

  <mat-error>{{errMsg}}</mat-error>

  }
</mat-form-field>
