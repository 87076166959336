import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { MeService } from "../services/me.service";


@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {

  constructor(private me: MeService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Promise<boolean> {

    // if we already have session
    if (this.me.session)
      return Promise.resolve(this.me.isAdmin);
    else
      return Promise.resolve(false);

    //// load session
    //// get domainName
    //const domainId = route.params["domainId"];

    //return this.me.initSession(domainId)
    //              .then(() => { return this.me.isAdmin; }, () => { return false; });

  }
}
