import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { MeService } from "../../services/me.service";


@Component({
    selector: 'ui-redirect',
    template: '',
    standalone: true
})
export class UiRedirectComponent implements OnInit {

  constructor(private auth: MsalService,
              private me: MeService) { }

  ngOnInit(): void {

    this.auth.getLogger().verbose('RedirectComponent activated');
    this.auth.handleRedirectObservable().subscribe(
      {
        next: (result: AuthenticationResult) => {

          // Perform actions related to user accounts here

          // if result is not null -> new login
          if (result) {

            window.console.log('REDIRECT FINISHED WITH LOGIN');

            // if init session running -> signal
            //if (this.me.initSessionRunning)
            //  // just signal
            //  this.me.redirectWithLogin = true;
            //else
            //if (this.me.session && this.me.isAnonymousVisitor) {

            //  // reload and re-init
            //  this.me.initSession(this.me.session.domainId)
            //         .then(() => this.me.reload());
            //}

          }

      },
      error: (error) => console.log(error)
      });

  }
}
