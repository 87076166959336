import { Component, Input } from '@angular/core';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { UiEntityDetailComponent } from '../ui-entity-detail/ui-entity-detail.component';

@Component({
    selector: 'view-entity-detail',
    templateUrl: './view-entity-detail.component.html',
    styleUrls: ['./view-entity-detail.component.css'],
    standalone: true,
    imports: [UiEntityDetailComponent]
})
export class ViewEntityDetailComponent {

  @Input('collectionId')
  public collectionId: string;

  @Input('entityId')
  public entityId: string;

  public get docId(): string {
    return `${this.me.session.domainId}/products/${this.collectionId}/${this.entityId}`;
  }

  constructor(private me: MeService, public nav: NavService) {

  }

}
