import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import * as _ from 'lodash';
import { CartDeliveryOption, CartDeliveryOptionList, ICartDeliveryOption, ICartDeliveryOptionList, ICartForm } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { SheetOrderErrorComponent } from '../sheet-order-error/sheet-order-error.component';
import { UiFormComponent } from '../ui-form/ui-form.component';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ui-cart-form',
    templateUrl: './ui-cart-form.component.html',
    styleUrls: ['./ui-cart-form.component.css'],
    standalone: true,
    imports: [NgIf, MatButton, MatIcon, UiFormComponent, MatFormField, MatInput, FormsModule, MatHint]
})
export class UiCartFormComponent implements OnInit {

  @Output('onPrevious') onPrevious = new EventEmitter<any>();
  @Output('onNext') onNext = new EventEmitter<any>();

  @ViewChild('uiform', { static: false }) uiForm: UiFormComponent;

  public cartForm: ICartForm;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['view-shoppping-cart']; }


  constructor(private me: MeService,
    private ssv: SelectService,
    private bottomSheet: MatBottomSheet) { }

  ngOnInit() {

    var localFormData = this.ssv.getFormData();

    this.me.call<ICartForm>('browse.user.cart.form.get', {

      selectedItems: this.ssv.selection.items,
      deliveryOptionId: this.ssv.selection.deliveryOption,
      formData: localFormData

    }).subscribe((cartForm) => {

      this.cartForm = cartForm;
      this.cartForm.name = this.ssv.getName();

    });

  }
  public previous() {

    // save form att
    this.ssv.setFormData(this.cartForm.data, this.cartForm.name);
    this.onPrevious.emit();

  }

  public next() {

    setTimeout(() => {

      // validate form
      this.uiForm.formGroup.markAllAsTouched();
      this.uiForm.formGroup.updateValueAndValidity();
      if (this.uiForm.formGroup.invalid) {

        // open bottom sheet with error message
        this.bottomSheet.open(SheetOrderErrorComponent);

      }
      else {

        // save form att
        this.ssv.setFormData(this.cartForm.data, this.cartForm.name);
        this.onNext.emit();

      }

    }, 50);

  }

}
