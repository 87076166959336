import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { IOrderSummary, OrderSummary } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiOrderSummaryComponent } from '../ui-order-summary/ui-order-summary.component';

@Component({
    selector: 'ui-cart-summary',
    templateUrl: './ui-cart-summary.component.html',
    styleUrls: ['./ui-cart-summary.component.css'],
    standalone: true,
    imports: [MatButton, MatIcon, UiOrderSummaryComponent]
})
export class UiCartSummaryComponent implements OnInit {

  @Output('onPrevious') onPrevious = new EventEmitter<any>();
  @Output('onNext') onNext = new EventEmitter<any>();

  public orderSummary: OrderSummary;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['view-shoppping-cart']; }

  constructor(private me: MeService, private ssv: SelectService) { }

  ngOnInit() {

    this.me.call<IOrderSummary>('browse.user.cart.summary.get', {

      selectedItems: this.ssv.selection.items,
      deliveryOptionId: this.ssv.selection.deliveryOption,
      customerInfo: this.ssv.getFormData(),
      name: this.ssv.getName(),

    }).subscribe((orderSummary) => {

      this.orderSummary = new OrderSummary(orderSummary);

    });

  }
  public previous() {

    this.onPrevious.emit();

  }

  public next() {

    this.me.call<IOrderSummary>('browse.user.order.submit', {

      selectedItems: this.ssv.selection.items,
      deliveryOptionId: this.ssv.selection.deliveryOption,
      customerInfo: this.ssv.getFormData(),
      name: this.ssv.getName(),

    }).subscribe(() => {

        // emit onNext
        this.onNext.emit();

    });

  }

}
