import * as _ from "lodash";
import { IProduct, IEntityStateInfo, IMediaView, IPrice, IPriceType, IProductPrice, IUserNote, IValidation, IPricingStrategy, IEntity } from "./entity.interface";

export interface ICartItem extends IProduct {
  "count": number,
  "price": IProductPrice,
}

export interface ICartResponse {
  "createdAt": string,
  "byUserId": string,
  "forUserId": string,
  "forUserRole": string,
}

export interface ICartItemList extends ICartResponse {
  "items": Array<ICartItem>,
  "itemsPrice": IPrice,
}

export class CartItemList {

  createdAt: string;
  byUserId: string;
  forUserId: string;
  forUserRole: string;
  items: Array<CartItem>;
  itemsPrice: Price;

  constructor(cart: ICartItemList) {

    this.createdAt = cart.createdAt;
    this.byUserId = cart.byUserId;
    this.forUserId = cart.forUserId;
    this.forUserRole = cart.forUserRole;

    this.items = cart.items.map(item => new CartItem(item));

    if (cart.itemsPrice)
      this.itemsPrice = new Price(cart.itemsPrice);

  }

  updateItemsPrice() {

    var incVAT: number = 0;
    var excVAT: number = 0;
    for (var i = 0; i < this.items.length; i++) {

      var price = this.items[i].price.actual;

      if (price.incVAT) incVAT += price.incVAT;
      if (price.excVAT) excVAT += price.excVAT;

    }

    this.itemsPrice.incVAT = incVAT;
    this.itemsPrice.excVAT = excVAT;

  }

}

export class CartItem implements ICartItem {

  id: string;
  name: string;
  description: string;
  subtitle: string;
  count: number;
  manufacturerId: string;
  manufacturer: string;
  supplierId: string;
  supplier: string;
  sku: string;
  state: IEntityStateInfo;
  pricing: Array<ProductPrice>;
  price: ProductPrice;
  lastModified: string;
  validation: IValidation;
  image: IMediaView;
  imgSrc: string;
  imgSrcSet: string;
  note: IUserNote;
  volume: string;
  terms: Array<string>;

  constructor(cartItem: ICartItem) {

    Object.assign(this, cartItem);
    if (cartItem.pricing)
      this.pricing = cartItem.pricing.map(productPrice => new ProductPrice(productPrice));
    if (cartItem.price)
      this.price = new ProductPrice(cartItem.price);

  }

  incrementCount() {

    this.count++;
    this.updatePrice();

  }

  decrementCount() {

    if (this.count > 1) {

      this.count--;
      this.updatePrice();

    }
  }

  private updatePrice() {

    var productPrice = this.pricing[0];
    if (productPrice) {

      this.price = _.cloneDeep(productPrice);

      if (this.price.standardPrice) {

        if (productPrice.standardPrice.incVAT)
          this.price.standardPrice.incVAT = productPrice.standardPrice.incVAT * this.count;

        if (productPrice.standardPrice.excVAT)
          this.price.standardPrice.excVAT = productPrice.standardPrice.excVAT * this.count;

      }

      if (this.price.specialPrice) {

        if (productPrice.specialPrice.incVAT)
          this.price.specialPrice.incVAT = productPrice.specialPrice.incVAT * this.count;

        if (productPrice.specialPrice.excVAT)
          this.price.specialPrice.excVAT = productPrice.specialPrice.excVAT * this.count;

      }

    }

  }


}

export class ProductPrice {

  // IProductPrice
  standardPrice: Price | null = null;
  specialPrice: Price | null = null;
  allocated: number | null = null;
  remaining: number | null = null;

  // computed
  get actual(): Price { return this.specialPrice ?? this.standardPrice; }

  constructor(productPrice: IProductPrice) {

    Object.assign(this, productPrice);

    if (productPrice.standardPrice)
      this.standardPrice = new Price(productPrice.standardPrice);

    if (productPrice.specialPrice)
      this.specialPrice = new Price(productPrice.specialPrice);

  }
}

export class Price implements IPrice {

  // IPrice
  excVAT: number | null;
  incVAT: number | null;
  priceListName: string;
  priceListType: string;
  type: IPriceType;
  currencyId: string;
  currency: string;
  vat: number | null;
  decimalPlaces: number;

  // computed
  get value(): number {
    return this.type.includesVAT === false ? this.excVAT : this.incVAT;
  }

  constructor(price: IPrice) {

    Object.assign(this, price);

  }

  valueText(locale: string, valueType: 'incVAT' | 'excVAT' = null): string {

    var value = this.value;

    switch (valueType) {

      case 'incVAT':
        value = this.incVAT;
        break;

      case 'excVAT':
        value = this.excVAT;
        break;

      default:
    }

    const fractionDigits = Number.isInteger(value) ? 0 : this.decimalPlaces;

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: this.currencyId,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    }).format(value);

  }

}

export interface ICartDeliveryOptionList extends ICartResponse {
  "options": Array<ICartDeliveryOption>,
  "messages": Array<string>,
  "priceType": IPriceType,
}

export interface ICartDeliveryOption extends IEntity {
  "validity": IValidityResult,
  "packagesCount": number,
  "price": IPrice,
}

export interface IValidityResult {
  "valid": boolean,
  "message": string,
}

export class CartDeliveryOption implements ICartDeliveryOption {

  id: string;
  name: string;
  description: string;
  packagesCount: number;
  price: Price;
  validity: IValidityResult;

  constructor(deliveryOption: ICartDeliveryOption) {

    Object.assign(this, deliveryOption);
    if (deliveryOption.price)
      this.price = new Price(deliveryOption.price);

  }

}

export class CartDeliveryOptionList {

  createdAt: string;
  byUserId: string;
  forUserId: string;
  forUserRole: string;
  options: Array<CartDeliveryOption>;
  messages: Array<string>;
  priceType: IPriceType;

  constructor(list: ICartDeliveryOptionList) {

    this.createdAt = list.createdAt;
    this.byUserId = list.byUserId;
    this.forUserId = list.forUserId;
    this.forUserRole = list.forUserRole;

    this.options = list.options.map(option => new CartDeliveryOption(option));
    this.messages = list.messages;
    this.priceType = list.priceType;

  }


}

export interface ICartForm {
  "name": string,
  "form": any,
  "data": any,
  "dataStored": boolean,
}

export interface IOrderSummary extends ICartResponse {
  "orderId": string,
  "name": string,
  "items": Array<ICartItem>,
  "itemsCount": number,
  "itemsPrice": IPrice,
  "deliveryOption": ICartDeliveryOption,
  "formInfo": any,
  "totalPrice": IPrice,
}

export class OrderSummary {

  createdAt: string;
  byUserId: string;
  forUserId: string;
  forUserRole: string;

  orderId: string;
  name: string;
  items: Array<CartItem>;
  itemsCount: number;
  itemsPrice: Price;
  deliveryOption: CartDeliveryOption;
  formInfo: any;
  totalPrice: Price;

  constructor(summary: IOrderSummary) {

    this.createdAt = summary.createdAt;
    this.byUserId = summary.byUserId;
    this.forUserId = summary.forUserId;
    this.forUserRole = summary.forUserRole;

    this.orderId = summary.orderId;
    this.name = summary.name;
    this.items = summary.items.map(item => new CartItem(item));
    this.itemsCount = summary.itemsCount;
    this.itemsPrice = new Price(summary.itemsPrice);
    this.deliveryOption = new CartDeliveryOption(summary.deliveryOption);
    this.formInfo = summary.formInfo;
    this.totalPrice = new Price(summary.totalPrice);

  }


}

export interface IOrderListItem {
  "transactionId": string,
  "name": string,
  "description": string
}
